import LoginApi from '@/api/LoginApi';
import SheriffApi from '../../api/SheriffApi';

const state = {
  shareType: null,
  shareData: null,
  sharerCustomerId: null,
  sharerMemberId: null,
  isLoggedInCustomer: false,
  sharePageId: null,
  isExpired: false,
};

const getters = {
  analyticsData: state => {
    return {
      sharerCustomerId: state.sharerCustomerId,
      sharerMemberId: state.sharerMemberId,
      sharePageId: state.sharePageId,
      sharedAsset: state.shareType,
      isLoggedInCustomer: state.isLoggedInCustomer,
    };
  },
};

const actions = {
  async createSharePage({ commit }, sharePage) {
    return await SheriffApi.createSharePage(sharePage);
  },
  // We want to send events from the sharer customerId perspective as well so we can complete the funnel
  async sendAnalytics({ commit, state }, eventName) {
    return await SheriffApi.sendAnalytics(state.sharePageId, eventName);
  },
  async sendAnalyticsLoggedIn({ commit, state }, eventName) {
    return await SheriffApi.sendAnalyticsLoggedIn(state.sharePageId, eventName);
  },
  async sendSharerAnalytics({ commit, state, dispatch }, eventName) {
    if (state.isLoggedInCustomer) {
      await dispatch('sendAnalyticsLoggedIn', eventName);
    } else {
      await dispatch('sendAnalytics', eventName);
    }
  },
  async init({ commit }, sharePageId) {
    commit('setSharePageId', sharePageId);
    try {
      const { shareContent, customerId, memberId } = await SheriffApi.getSharePage(sharePageId);
      const isLoggedInCustomer = await LoginApi.isLoggedIn();
      commit('setShareType', shareContent.shareType);
      commit('setShareData', shareContent.data);
      commit('setSharerCustomerId', customerId);
      commit('setSharerMemberId', memberId);
      commit('setIsLoggedInCustomer', isLoggedInCustomer);
    } catch (e) {
      if (e.response.status === 410) {
        commit('setIsExpired', true);
        return;
      }
      throw e;
    }
  },
};

// mutations
const mutations = {
  setIsExpired(state, isExpired) {
    state.isExpired = isExpired;
  },
  setShareType(state, shareType) {
    state.shareType = shareType;
  },
  setSharePageId(state, sharePageId) {
    state.sharePageId = sharePageId;
  },
  setShareData(state, shareData) {
    state.shareData = shareData;
  },
  setSharerCustomerId(state, customerId) {
    state.sharerCustomerId = customerId;
  },
  setSharerMemberId(state, memberId) {
    state.sharerMemberId = memberId;
  },
  setIsLoggedInCustomer(state, isLoggedInCustomer) {
    state.isLoggedInCustomer = isLoggedInCustomer;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
