import { render, staticRenderFns } from "./DemographicInfoInsight.vue?vue&type=template&id=63799ba5&scoped=true&"
import script from "./DemographicInfoInsight.vue?vue&type=script&lang=js&"
export * from "./DemographicInfoInsight.vue?vue&type=script&lang=js&"
import style0 from "./DemographicInfoInsight.vue?vue&type=style&index=0&id=63799ba5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63799ba5",
  null
  
)

export default component.exports