import Vue from 'vue';
import Router from 'vue-router';
import VueSocketio from 'vue-socket.io';
import _ from 'lodash';
import Segment from '@/Segment';
import { io } from 'socket.io-client';
import store from '@/store';
import featureFlag from '@/api/featureFlag';
import LoginApi from '@/api/LoginApi';
import auth0 from '@/utils/auth0';
import { PerformanceMarks, performanceService } from '@/utils/PerformanceService';
import InsightsViewer from '@/pages/responsive-pages/non-authenticated/InsightsViewer.vue';
import { noAuthRoutes } from './no-auth';
import { partialAuthRoutes } from './partial-auth';
import { authenticatedRoutes } from './authenticated';
import config from '../config';
import CustomersApi from '../api/CustomersApi';
import ResponsivePage from '../pages/responsive-pages/ResponsivePage.vue';
import UrlRedirect from '../pages/responsive-pages/UrlRedirect.vue';

const AuthenticateToken = () => import(/* webpackChunkName: "authenticated-base" */ '../pages/responsive-pages/authenticated/AuthenticateToken.vue');
const SingleFactorAuthenticateToken = () => import('../pages/responsive-pages/single-factor-authenticated/SingleFactorAuthenticateToken.vue');
const Playground = () => import('../pages/base-ui-playground/Playground.vue');
const MemberInviteExistingCustomer = () => import('../pages/responsive-pages/invite-authenticated/member-invite/pages/MemberInviteExistingCustomer');

const { isNavigationFailure, NavigationFailureType } = Router;
const REDIRECT_TO_BLACKLIST = ['/login', '/signup', '/signup/details'];

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (!isNavigationFailure(err, NavigationFailureType.duplicated) && !isNavigationFailure(err, NavigationFailureType.redirected)) {
      throw err;
    }
  });
};

const originalReplace = Router.prototype.replace;
Router.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => {
    if (!isNavigationFailure(err, NavigationFailureType.duplicated) && !isNavigationFailure(err, NavigationFailureType.redirected)) {
      throw err;
    }
  });
};
// Disable datadog from calling clearResourceTimings and clearing entries before we manually log them
performance.clearResourceTimings = () => {};
Vue.use(Router);

function initWebSockets() {
  if (!Vue.prototype.$socket) {
    Vue.use(new VueSocketio({
      debug: false,
      connection: io(config.get().elsaPublicUrl, {
        path: '/websockets',
        transports: ['websocket', 'polling'],
        reconnectionAttempts: 3,
        autoConnect: false,
      }),
      vuex: {
        store,
        mutationPrefix: 'socket_',
      },
    }));
  }
  if (!Vue.prototype.$socket.connected) {
    Vue.prototype.$socket.connect();
  }
}

async function redirectToLogin(to, next) {
  const redirectTo = _extractRedirectTo(to.path);
  const isPartiallyLoggedIn = await LoginApi.isPartialLoggedIn();
  if (isPartiallyLoggedIn) {
    const member = await CustomersApi.getPartiallyAuthenticatedMember();
    performanceService.markEnd(PerformanceMarks.AUTHENTICATE_TOKEN);
    if (member.hasOidc) {
      if (await _shouldShowGoogleDeadEndPage(member)) {
        const redirectPath = '/facebook-dead-end';
        Segment.trackUserGot('redirectingToFacebookDeadEnd', { redirectPath });
        const query = { ...to.query, redirectTo };
        next({ path: redirectPath, query });
      } else if (to.query.error_description === 'Access expired.') {
        const redirectPath = '/2fa-Link-Expired';
        Segment.trackUserGot('redirectingToResetPasswordLinkExpired', { redirectPath });
        next({ path: redirectPath });
      } else {
        Segment.trackUserGot('2faRedirectToAuth0Login');
        const redirectToForAuth0 = auth0.getEncodedRedirectTo(to);
        window.location.href = `/api/partial-auth/login-with-auth0?redirectTo=${redirectToForAuth0}`;
      }
      return;
    }
    Segment.trackUserGot('redirectToAdd2FaFromMain');
    window.location.href = '/api/partial-auth/login-with-auth0/new';
    return;
  }
  const redirectPath = '/login';
  const query = { redirectTo, ...to.query };
  performanceService.markEnd(PerformanceMarks.AUTHENTICATE_TOKEN);
  next({ path: redirectPath, query });
}

async function _shouldShowGoogleDeadEndPage(member) {
  const showFacebookDeadEndFeatureFlag = await featureFlag.getTreatmentPartialAuth('facebook-app-dead-end');
  return (showFacebookDeadEndFeatureFlag === 'on' && member.loginMethod
    && _.startsWith(member.loginMethod, 'google') && _isFacebookOrInstagramApp());
}

function _isFacebookOrInstagramApp() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  return (userAgent.indexOf('FBAN') > -1) || (userAgent.indexOf('FBAV') > -1) || (userAgent.indexOf('Instagram') > -1);
}

function _extractRedirectTo(path) {
  if (_.includes(REDIRECT_TO_BLACKLIST, path)) {
    return 'sr';
  }
  return _.trimStart(path, '/');
}

const vueRouter = new Router({
  mode: 'history',
  routes: [
    {
      path: '/internal/playground',
      name: 'Playground',
      component: Playground,
    },
    {
      path: '/internal/insights-viewer',
      name: 'InsightsViewer',
      component: InsightsViewer,
    },
    {
      path: '/',
      name: 'ResponsivePage',
      component: ResponsivePage,
      children: [
        ...noAuthRoutes,
        {
          path: '/',
          name: 'SingleFactorAuthenticateToken',
          component: SingleFactorAuthenticateToken,
          redirect: { name: 'StateRouter' },
          async beforeEnter(to, from, next) {
            if (await LoginApi.isPartialLoggedIn()) {
              next();
            } else {
              const query = { redirectTo: _extractRedirectTo(to.path), ...to.query };
              next({ path: 'login', query });
            }
          },
          children: partialAuthRoutes,
        },
        {
          path: '/url-redirect',
          name: 'AuthenticateTokenRedirect',
          component: UrlRedirect,
          async beforeEnter(to, from, next) {
            if (await LoginApi.isLoggedIn()) {
              next();
            } else {
              await redirectToLogin(to, next);
            }
          },
        },
        {
          path: '/invite-existing-customer',
          name: 'Member invite - existing customer',
          component: MemberInviteExistingCustomer,
          async beforeEnter(to, from, next) {
            if (await LoginApi.isLoggedIn()) {
              next();
            } else {
              await redirectToLogin(to, next);
            }
          },
        },
        {
          path: '/',
          name: 'AuthenticateToken',
          component: AuthenticateToken,
          redirect: { name: 'StateRouter' },
          async beforeEnter(to, from, next) {
            if (to.query.debug_performance) {
              sessionStorage.setItem('debug_performance', 'true');
            }
            performanceService.markStart(PerformanceMarks.AUTHENTICATE_TOKEN);
            if (await LoginApi.isLoggedIn()) {
              next();
              initWebSockets();
              performanceService.markEnd(PerformanceMarks.AUTHENTICATE_TOKEN);
            } else {
              await redirectToLogin(to, next);
            }
          },
          children: authenticatedRoutes,
        },
      ],
    },
  ],
});

// We want our "back" arrow in the app to go to previous place in app.
// However, if it's the first page, we don't want to go back to previous site or empty tab.
// Turns out that the router history does not expose the previous paths and if we want to check
// which page we came from we need to hook and record it ourselves.
// This puts the previous route name on the meta field of each route.
// The first page we came from will have a null value since the 'from.name' is empty.
vueRouter.beforeEach((to, from, next) => {
  _.extend(to.meta, { previousRoute: from });
  if (to.name !== from.name) {
    performanceService.clearMarks();
  }
  next();
});

vueRouter.canGoBack = route => {
  // The first page will have a 'null' value
  const isFirstNavigationPage = !route.meta?.previousRoute?.name;
  const canNavigateToPreviousComponent = !route.meta?.previousRoute?.meta?.preventRouterBack;
  return route.meta?.routerBack && !isFirstNavigationPage && canNavigateToPreviousComponent;
};

export default vueRouter;
