import HeverAmbassadorsCard from '@/pages/responsive-pages/authenticated/navigation-pages/ambassadors/HeverAmbassadorsCard';
import AmbassadorsCard from '@/pages/responsive-pages/authenticated/navigation-pages/ambassadors/AmbassadorsCard';
import YourReferralStatus from '@/pages/responsive-pages/authenticated/navigation-pages/ambassadors/YourReferralStatus';
import moment from 'moment';
import _ from 'lodash';
import PaymentDetailsCard from '@/pages/responsive-pages/authenticated/navigation-pages/ambassadors/PaymentDetailsCard';
import GetPaymentDetailsCard from '@/pages/responsive-pages/authenticated/navigation-pages/ambassadors/GetPaymentDetailsCard';
import CompetitionCard from '@/pages/responsive-pages/authenticated/navigation-pages/ambassadors/CompetitionCard';
import referralsApi from '../../api/referrals';

const state = {
  competitionDates: {
    start: moment('2024-02-18').startOf('day').toDate(),
    end: moment('2024-03-02').endOf('day').toDate(),
  },
  referralEvents: [],
  loadReferrals: false,
  shouldAskForPaymentDetails: false,
  paymentDetailsError: null,
  referrerInfo: {},
  cards: {
    hasPaymentDetails: {
      hasHever: [
        { component: HeverAmbassadorsCard },
        { component: AmbassadorsCard },
        { component: CompetitionCard },
        { component: YourReferralStatus },
        { component: PaymentDetailsCard },
      ],
      noHever: [
        { component: AmbassadorsCard },
        { component: CompetitionCard },
        { component: YourReferralStatus },
        { component: PaymentDetailsCard },
      ],
    },
    hasReferralsNoPayment: {
      hasHever: [
        {
          component: GetPaymentDetailsCard,
          props: { needsPayment: true },
        },
        { component: HeverAmbassadorsCard },
        { component: AmbassadorsCard },
        { component: CompetitionCard },
        { component: YourReferralStatus },
      ],
      noHever: [
        {
          component: GetPaymentDetailsCard,
          props: { needsPayment: true },
        },
        { component: AmbassadorsCard },
        { component: CompetitionCard },
        { component: YourReferralStatus },
      ],
    },
    noReferralsNoPayment: {
      hasHever: [
        { component: HeverAmbassadorsCard },
        { component: AmbassadorsCard },
        {
          component: GetPaymentDetailsCard,
          props: { needsPayment: true },
        },
        { component: CompetitionCard },
        { component: YourReferralStatus },
      ],
      noHever: [
        { component: AmbassadorsCard },
        {
          component: GetPaymentDetailsCard,
          props: { needsPayment: true },
        },
        { component: CompetitionCard },
        { component: YourReferralStatus },
      ],
    },
  },
};

const actions = {
  async init({ commit }) {
    commit('setLoadReferrals', true);
    try {
      const events = await referralsApi.getReferralEvents();
      const shouldAskForPaymentDetails = await referralsApi.shouldAskForPaymentDetails();
      const referrerInfo = await referralsApi.getReferrerInfo();
      commit('setReferralEvents', events);
      commit('setShouldAskForPaymentDetails', shouldAskForPaymentDetails);
      commit('setReferrerInfo', referrerInfo);
    } finally {
      commit('setLoadReferrals', false);
    }
  },
  async savePaymentDetails({ commit }, paymentDetails) {
    try {
      commit('setLoadReferrals', true);
      commit('setPaymentDetailsError', null);
      await referralsApi.updatePaymentDetails(paymentDetails);
    } catch (e) {
      commit('setPaymentDetailsError', e);
    } finally {
      commit('setLoadReferrals', false);
    }
  },
};

const mutations = {
  setReferralEvents(state, events) {
    state.referralEvents = events;
  },
  setLoadReferrals(state, loading) {
    state.loadReferrals = loading;
  },
  setShouldAskForPaymentDetails(state, shouldAskForPaymentDetails) {
    state.shouldAskForPaymentDetails = shouldAskForPaymentDetails;
  },
  setPaymentDetailsError(state, error) {
    state.paymentDetailsError = error;
  },
  setReferrerInfo(state, referrerInfo) {
    state.referrerInfo = referrerInfo;
  },
};

const getters = {
  activationEvents: state => _withDateRangeFilter(state.referralEvents.filter(event => event.type === 'activation')),
  firstPaymentEvents: state => _withDateRangeFilter(state.referralEvents.filter(event => event.type === 'firstPayment')),
  hasPaymentDetails: state => state.referrerInfo.paymentDetails,
  bankId: (state, getters) => getters.hasPaymentDetails && state.referrerInfo.paymentDetails.bankCode,
  bankAccountOwner: (state, getters) => getters.hasPaymentDetails && state.referrerInfo.paymentDetails.bankAccountOwner,
  bankAccountNumber: (state, getters) => getters.hasPaymentDetails && state.referrerInfo.paymentDetails.accountNumber,
  idNumber: (state, getters) => getters.hasPaymentDetails && state.referrerInfo.paymentDetails.idNumber,
  bankBranch: (state, getters) => getters.hasPaymentDetails && state.referrerInfo.paymentDetails.bankBranch,
  getCardsToDisplay: (state, getters) => hasHeverBenefit => _cardsToDisplay(state, getters.hasPaymentDetails, hasHeverBenefit),
  pastThreeMonthsRefferalsToPay: (state, getters) => _.map(_.range(0, 3), num => getters.monthAgoRefferalsToPay(num)),
  monthAgoRefferalsToPay: (state, getters) => numOfMonths => {
    const startDate = moment()
      .subtract(numOfMonths, 'month')
      .startOf('month');
    const endDate = moment()
      .subtract(numOfMonths, 'month')
      .endOf('month');
    return {
      name: moment()
        .subtract(numOfMonths, 'month')
        .locale('he')
        .format('MMMM YYYY'),
      activations: getters.activationEvents(startDate, endDate).length,
      firstPayments: getters.firstPaymentEvents(startDate, endDate).length,
    };
  },
  competitionDates: state => state.competitionDates,
  isDuringCompetition: state => new Date() >= state.competitionDates.start && new Date() <= state.competitionDates.end,
  showCompetitionCard: (state, getters) => {
    const competitionCardDuration = 5; // Leave the card up for 5 days after the competition ends
    const hideCardDate = moment(state.competitionDates.end).add(competitionCardDuration, 'days').toDate();
    return getters.isDuringCompetition || new Date() <= hideCardDate;
  },
  competitionEvents: (state, getters) => {
    return {
      name: 'להמליץ, להרוויח ולטוס 2',
      activations: getters.activationEvents(state.competitionDates.start, state.competitionDates.end).length,
      firstPayments: getters.firstPaymentEvents(state.competitionDates.start, state.competitionDates.end).length,
    };
  },
};

function _withDateRangeFilter(events) {
  return (startDate = undefined, endDate = undefined) => events.filter(event => {
    const eventDate = new Date(event.created);
    const afterStartDate = startDate ? eventDate >= startDate : true;
    const beforeEndDate = endDate ? eventDate <= endDate : true;
    return afterStartDate && beforeEndDate;
  });
}

function _cardsToDisplay(state, hasPaymentDetails, hasHeverBenefit) {
  let cards;
  if (hasPaymentDetails) {
    cards = state.cards.hasPaymentDetails;
  } else if (state.shouldAskForPaymentDetails) {
    cards = state.cards.hasReferralsNoPayment;
  } else {
    cards = state.cards.noReferralsNoPayment;
  }
  return hasHeverBenefit ? cards.hasHever : cards.noHever;
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
