import DDLogs from '@/DDLogs';
import { client } from '@/http';
import Segment from '../Segment';

export default {
  async getCustomerSessionData() {
    const response = await client.get('/api/restricted-customer/session-data');
    return response.data;
  },
  async getCustomerState() {
    const response = await client.get('/api/consolidated/customer-state');
    return response.data;
  },
  async getCustomerDetails() {
    const response = await client.get('/api/restricted-customer/');
    return response.data;
  },
  async updateCustomerIntake(intake) {
    const response = await client.put('/api/customers', intake);
    return response.data;
  },
  async getOnboradingStatus() {
    const response = await client.get('/api/customer/onboarding-status');
    return response.data;
  },
  async getMembers() {
    const response = await client.get('/api/restricted-customer/members');
    return response.data;
  },
  async getActiveMember() {
    const response = await client.get('/api/restricted-customer/members/active-member');
    return response.data;
  },
  async updateActiveMemberEmail(email) {
    const response = await client.put('/api/restricted-customer/members/active-member', { email });
    return response.data;
  },
  async getPartiallyAuthenticatedMember() {
    const response = await client.get('/api/partial-auth/members/active-member');
    return response.data;
  },
  async hasMultipleMembers() {
    const response = await client.get('/api/partial-auth/members/has-multiple-members');
    return response.data;
  },
  async assignAuth0Id() {
    await new Promise(resolve => setTimeout(resolve, 2000));
    const response = await client.get('/api/partial-auth/add-auth0-id');
    const { auth0Method, memberId } = response.data;
    Segment.trackUserGot('2faSavedSuccessfully', { auth0Method, memberId });
    DDLogs.log('2fa saved successfully');
    return auth0Method;
  },
  async complete2faLogin() {
    const response = await client.get('/api/partial-auth/complete-2fa-web-login');
    return response.data;
  },
  async customerTriggeredScrape() {
    const response = await client.post('/api/customer-triggered-scrape');
    return response.data;
  },
  async churn() {
    return client.delete('/api/churn');
  },
  async cancelSubscription() {
    return client.post('/api/cancel-subscription', {});
  },
  async churnThroughMemberInvite() {
    return client.delete('/api/churn/member-invite');
  },
  async resetForDormant(promoCode) {
    return client.post('/api/reset-dormant-customer', { promoCode });
  },
  async getProducts() {
    const response = await client.get('/api/partial-auth/products');
    return response.data;
  },
};
