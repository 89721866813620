<template>
<image-header-page :image-header-props="imageHeaderProps">
  <div class="content">
    <payment-form-section :title="title">
      <benefit-notification-box-v2 v-if="benefitId" flavor="signup-offering" />
      <slot name="title" />
    </payment-form-section>

    <payment-form-section title="על שם מי הכרטיס?" >
      <personal-info-form-v2
        :first-name-initial-value="personalInfo['payerFirstName'].value"
        :last-name-initial-value="personalInfo['payerLastName'].value"
        :phone-number-initial-value="personalInfo['payerPhone'].value"
        @input="onPersonalInfoFieldValueChange"
        @validationChange="onPersonalInfoValidationChange"
      />
    </payment-form-section>
    <payment-form-section title="פרטי הכרטיס" :has-bottom-padding="false">
      <existing-credit-card-details v-if="useExitingCard"
                                    @otherCardClick="onEnterNewCard"
                                    :card-mask="formattedCardNumberMask"/>
      <credit-card-details-form-v1 v-else
        :show-title="false"
        @init-failed="creditCardFormInitFailed"
      />
    </payment-form-section>

    <payment-form-section v-if="showPaymentInfoSection" title="פרטי התשלום" :bottom-separator="false" :has-bottom-padding="false">
      <payment-details
        :price="price"
        :strike-price="strikePrice"
        :payment-date="paymentDate"
        :discount="!!discountPercentageAmount"
        :discount-text="`הנחה של ${discountPercentageAmount}%`"
      />
      <div class="disclaimers" v-if="!!$slots.disclaimers">
        <slot name="disclaimers" />
      </div>
    </payment-form-section>

    <payment-form-section :bottom-separator="false">
      <div class="actions">
        <slot name="errors" />
        <riseup-button
          v-if="!tokenizationError"
          :title="purchaseButtonText"
          :loading="buttonLoading"
          :disabled="buttonLoading || !arePersonalInfoValid"
          @click="purchase"
        />
        <tokenize-error-v2 v-else />
        <riseup-button :title="cancelButtonText" :variant="cancelButtonVariant" @click="cancel" />
      </div>
    </payment-form-section>
  </div>
</image-header-page>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { BACKGROUND_POSITIONS, COLOR_VARIANT, COLOR_VARIANTS } from '@riseupil/base-ui/src/utils/layout-consts';
import { mapActions, mapGetters, mapState } from 'vuex';
import { CREDIT_CARD_FIELDS, PERSONAL_INFO_FIELDS } from '@/store/modules/payment';
import _ from 'lodash';
import ExistingCreditCardDetails
  from '@/pages/responsive-pages/authenticated/navigation-pages/payment/PaymentForm/ExistingCreditCardDetails.vue';
import paymentFormSection from './PaymentFormSection';
import PaymentHelper from '../../../payment/PaymentHelper';
import PersonalInfoFormV2 from './PersonalInfoFormV2';
import CreditCardDetailsFormV1 from './CreditCardDetailsFormV1';
import PaymentDetails from '../../../subscription/PaymentDetails';
import BenefitNotificationBoxV2 from '../../../subscription/V2/BenefitNotificationBox2.vue';
import TokenizeErrorV2 from '../../../subscription/V2/TokenizeErrorV2';

export default {
  name: 'PaymentFormPage',
  components: {
    ExistingCreditCardDetails,
    BenefitNotificationBoxV2,
    PersonalInfoFormV2,
    paymentFormSection,
    CreditCardDetailsFormV1,
    PaymentDetails,
    TokenizeErrorV2,
    ImageHeaderPage: BaseUI.Pages.ImageHeaderPage,
    RiseupButton: BaseUI.RiseupButton,
  },
  mixins: [PaymentHelper],
  props: {
    imageHeaderProps: {
      type: Object,
      default: () => {
        return {
          backgroundColor: COLOR_VARIANT.BLUE,
          // eslint-disable-next-line global-require
          imgSrc: require('@/assets/subscription/credit-card-header.svg'),
          imgHeight: 130,
          backgroundPosition: BACKGROUND_POSITIONS.BOTTOM_RIGHT,
          bottomSeparator: false,
        };
      },
      backgroundColor: {
        type: String,
        validator: value => COLOR_VARIANTS.includes(value),
      },
      imgSrc: {
        type: String,
      },
      imgHeight: {
        type: Number,
      },
      backgroundPosition: {
        type: String,
        validator: value => BACKGROUND_POSITIONS.includes(value),
      },
    },
    title: {
      type: String,
      required: true,
    },
    initialValues: {
      type: Object,
      validator: value => {
        const allFields = [
          ..._.values(PERSONAL_INFO_FIELDS),
          ..._.values(CREDIT_CARD_FIELDS),
        ];
        return Object
          .keys(value)
          .every(key => allFields.includes(key));
      },
    },
    initialToken: {
      type: String,
      required: false,
    },
    price: {
      type: Number,
      required: true,
    },
    strikePrice: {
      type: Number,
      required: false,
    },
    paymentDate: {
      type: String,
      required: true,
    },
    benefitId: {
      type: String,
      required: false,
    },
    discountPercentageAmount: {
      type: Number,
      required: false,
    },
    purchaseButtonText: {
      type: String,
      required: false,
      default: 'לתשלום מאובטח',
    },
    cancelButtonText: {
      type: String,
      required: false,
      default: 'ביטול',
    },
    purchaseLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    showPaymentInfoSection: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      cancelButtonVariant: BaseUI.RiseupButtonVariant.TERTIARY,
      useExitingCard: false,
    };
  },
  async created() {
    if (this.initialToken) {
      this.setPaymeToken(this.initialToken);
    }
    _.values(PERSONAL_INFO_FIELDS).forEach(field => {
      if (this.initialValues && this.initialValues[field]) {
        this.setPersonalInfoField({ field, value: this.initialValues[field], isValid: true });
      }
    });
    await this.fetchCreditCardMask();
    this.useExitingCard = !!this.cardNumberMask;
  },
  computed: {
    ...mapState('payment', ['personalInfo', 'creditCard', 'tokenizationError', 'paymeToken', 'loadingToken', 'creditCardIsValid', 'cardNumberMask']),
    ...mapGetters('payment', ['formattedCardNumberMask']),
    buttonLoading() {
      return this.loadingToken || this.purchaseLoading;
    },
    arePersonalInfoValid() {
      return _.values(this.personalInfo).every(({ isValid }) => isValid ?? false);
    },
  },
  methods: {
    ...mapActions('payment', ['setPersonalInfoField', 'getOrCreatePaymentToken', 'setPaymeToken', 'fetchCreditCardMask']),
    onEnterNewCard() {
      this.useExitingCard = false;
    },
    onPersonalInfoFieldValueChange({ field, value }) {
      this.setPersonalInfoField({
        ...this.personalInfo[field],
        field,
        value,
      });
    },
    onPersonalInfoValidationChange({ field, hasError }) {
      this.setPersonalInfoField({
        ...this.personalInfo[field],
        field,
        isValid: !hasError,
      });
    },
    async purchase() {
      if (this.useExitingCard) {
        this.$emit('tokenization-success', undefined);
        return;
      }
      if (await this.getOrCreatePaymentToken()) {
        this.$emit('tokenization-success', this.paymeToken);
      } else {
        this.$emit('tokenization-failure', null);
      }
    },
    cancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@riseupil/base-ui/src/scss/spacings';
@import '@riseupil/base-ui/src/scss/riseup-colors';

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .disclaimers {
    display: flex;
    flex-direction: column;

    ::v-deep .disclaimer {
      padding: $ri-spacing-sm 0;
      border-top: 1px solid $riseup_gray_0;
    }
  }

  .actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: $ri-spacing-s;
  }
}

</style>
