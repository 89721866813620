<template>
  <dynamic-deposit-step :authenticated="true" :step="this.$options.name">
    <template v-slot:content>

      <div class="dd-headline-label ri-bold-body margin-bottom-s">אישור וחתימה</div>

      <check-box :initially-checked="agreeToMeitavConditions"
                 :toggle="toggleAgreeToMeitavConditions">
        <template v-slot:title>
          <div class="checkbox-text">
מתחייב.ת מול ׳מיטב׳:
          </div>
        </template>
      </check-box>
      <div class="meitav-conditions">
        <div :class="{conditions: showFullConditions}">
          להודיע למיטב דש גמל ופנסיה בע"מ (להלן: "מיטב דש") מראש ובכתב על כל שינוי ו/או תיקון
          ו /או ביטול (להלן ביחד ולחוד: "שינוי") של ההרשאה לחיוב חשבון וכמו כן ידוע לי כי חלה עלי החובה לוודא כי הבקשה לביצוע שינוי בהרשאה לחיוב
          החשבון נקלטה במיטב דש, באמצעות עיון בטופס הבקשה החתום דיגיטלית על ידי, שישלח אליי על ידי מיטב דש לכתובת הדוא"ל שהוזנה על ידי. אני
          מצהיר כי במקרה שלא אודיע מראש ובכתב על שינוי<div v-if="!showFullConditions" style="display: inline">...</div>
        </div>
        <div v-if="!showFullConditions" class="read-more">
          <span class="link" v-on:click="readMore()">לקרוא את ההמשך</span>
        </div>
        <div v-else style="display: inline">
          <div style="display: inline">, לא יהיו לי כל טענות כלפי מיטב דש בגין גביית סכומים בחסר ו/או בעודף שתגרם כתוצאה מכך.</div>
          <div><span class="emphasise">1. ידוע לי כי:</span></div>
          <div>
            א. הרשאה זו ניתנת לביטול על ידי ו/או על ידי מיטב דש בהודעה בכתב
            לבנק שתכנס לתוקף יום עסקים אחד לאחר מתן ההודעה לבנק, וכן ניתנת לביטול עפ"י הוראת כל דין.
            ב. מיטב דש תהיה רשאית לבטל חיוב מסוים, ובלבד שהודעה על כך תימסר על ידי מיטב דש בכתב לבנק,
            לא יאוחר מ- 3 ימי עסקים לאחר מועד החיוב. ככל שהודעת הביטול ניתנה לאחר מועד החיוב, הזיכוי ייעשה בערך יום מתן הודעת הביטול.
            ג. מיטב דש תהיה רשאית לדרוש מהבנק, בהודעה בכתב, לבטל חיוב, אם החיוב אינו תואם את מועד פקיעת התוקף שנקבע בהרשאה,
            או את הסכומים שנקבעו בהרשאה, אם נקבעו.
            ד. הבנק אינו אחראי בכל הנוגע לעסקה שביני לבין מיטב דש.
            ה. הרשאה שלא יעשה בה שימוש במשך תקופה של 24 חודשים ממועד החיוב האחרון, בטלה.
            ו. כי השינוי המבוקש על ידי בהרשאה לחיוב חשבון הוא בכפוף למגבלות שסומנו על ידי בעת הקמת ההרשאה לחיוב חשבון (ככל שסומנו).
            ז. אם יישלחו על ידי חיובים שאינם עומדים בהגבלות שקבעתי, הם יוחזרו על ידי הבנק, על כל המשמעויות הכרוכות בכך.
            2. אני מסכים שבקשה זו לעדכון הרשאת החיוב תוגש לבנק על ידי מיטב דש.
          </div>
        </div>
      </div>

      <check-box :initially-checked="assignPowerOfAttorney"
                 :toggle="toggleAssignPowerOfAttorney">
        <template v-slot:title>
          <div class="checkbox-text">
            מאשר.ת לרייזאפ להעביר בשמי למיטב את הבקשה הזו לביצוע הפעולה -
            <span class="link" v-on:click="openAssignPowerOfAttorney()">מפורט בייפוי כח</span>
          </div>
        </template>
      </check-box>

      <div class="ri-large-headline margin-vertical-s">נשאר רק לחתום</div>
      <signature height="145px" @signed="setSignature"/>

    </template>
    <template v-slot:next-button>
      <dynamic-deposit-button title="אישור" :action="initiatePayment" :loading="loading" :disabled="!canSubmit"/>
    </template>
  </dynamic-deposit-step>
</template>

<script>

import Segment from '@/Segment';
import * as _ from 'lodash';
import BaseUI from '@riseupil/base-ui';
import { mapGetters } from 'vuex';
import * as SavingsApi from '@/api/SavingsApi';
import DynamicDepositStep from './DynamicDepositStep';
import DynamicDepositButton from './DynamicDepositButton';

export default {
  name: 'DDSignature',
  components: {
    Signature: BaseUI.Signature,
    DynamicDepositButton,
    DynamicDepositStep,
    CheckBox: BaseUI.CheckBox,
  },
  props: {
    nextStep: {
      type: Function,
      required: true,
    },
    showErrorPage: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      agreeToMeitavConditions: false,
      assignPowerOfAttorney: false,
      signature: undefined,
      loading: false,
      showFullConditions: false,
    };
  },
  created() {
    Segment.trackUserInteraction('DynamicDeposit_Signature_Entered');
  },
  computed: {
    ...mapGetters('dynamicSavings', ['depositSum', 'isOpportunisticDeposit', 'isMonthlyDeposit', 'isReduceMonthlyDeposit']),
    canSubmit() {
      return this.agreeToMeitavConditions && this.assignPowerOfAttorney && !_.isEmpty(this.signature);
    },
  },
  methods: {
    toggleAgreeToMeitavConditions() {
      Segment.trackUserInteraction('DynamicDeposit_Signature_AgreeToConditionsClicked', { newValue: !this.agreeToMeitavConditions });
      this.agreeToMeitavConditions = !this.agreeToMeitavConditions;
    },
    toggleAssignPowerOfAttorney() {
      Segment.trackUserInteraction('DynamicDeposit_Signature_AssignPowerOfAttorneyClicked', { newValue: !this.assignPowerOfAttorney });
      this.assignPowerOfAttorney = !this.assignPowerOfAttorney;
    },
    openAssignPowerOfAttorney() {
      Segment.trackUserInteraction('DynamicDeposit_Signature_ReadAssignPowerOfAttorney');
      window.open('/pdfs/riseup_power_of_attorney_June22.pdf', '_blank');
    },
    readMore() {
      Segment.trackUserInteraction('DynamicDeposit_Signature_ReadConditions');
      this.showFullConditions = true;
    },
    setSignature(signature) {
      this.signature = signature;
    },
    async initiatePayment() {
      Segment.trackUserInteraction('DynamicDeposit_Signature_NextClicked');
      this.loading = true;
      try {
        const response = await SavingsApi.initiatePayment(this.depositSum,
          this.isMonthlyDeposit || this.isReduceMonthlyDeposit, this.agreeToMeitavConditions, this.signature);
        if (response.status === SavingsApi.STATUS.SUCCESS) {
          this.nextStep();
        } else {
          this.showErrorPage();
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@riseupil/base-ui/src/scss/riseup-colors";

.checkbox-text {
  margin-right: 10px;
}

.signature-title {
  margin-top: 32px;
}

.meitav-conditions {
  font-size: 12px;
  line-height: 1.1;
  margin-bottom: 15px;

  .conditions {
    display: inline;
  }
}

.emphasise {
  font-weight: bold;
}

.link {
  color: $riseup_blue;
  text-decoration: underline;
  cursor: pointer;
}

.read-more {
  text-align: left;
  font-size: 12px;
  line-height: 22px;
}
</style>
