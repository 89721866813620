import { render, staticRenderFns } from "./PromoteAnnualInsight.vue?vue&type=template&id=4efe5d9a&scoped=true&"
import script from "./PromoteAnnualInsight.vue?vue&type=script&lang=js&"
export * from "./PromoteAnnualInsight.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4efe5d9a",
  null
  
)

export default component.exports