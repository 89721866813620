import _ from 'lodash';
import Vue from 'vue';
import moment from 'moment';
import reactiveCashflow from '@riseupil/reactive-cashflow';
import { PerformanceMarks, performanceService } from '@/utils/PerformanceService';
import { DateLocales } from '@riseupil/common-utils';
import * as trackingCategoriesApi from '@/api/TrackingCategoriesApi';
import router from '@/router';
import DDLogs from '@/DDLogs';
import cashflowHistory from '../utilities/cashflow-history';
import cashflowNormalizer from '../utilities/cashflow-normalizer';
import cashflowViewConsts from '../../constants/cashflow-view';
import CashflowViewApi from '../../api/CashflowViewApi';
import * as HamsterApi from '../../api/HamsterApi';
import LatestSeenTransactionDateApi from '../../api/LatestSeenTransactionCreationDateApi';

const state = {
  presentedBudgetDate: null,
  allCashflows: [],
  cashflowUITree: {},
  cashflow: {},
  finishLoadingAllBudgets: false,
  lastActivity: null,
  trackingCategoriesOrder: [],
  cashflowStartDay: cashflowViewConsts.DEFAULT_CASHFLOW_START_DAY,
};

function transactionsInCurrentCashflow(state) {
  const { envelopes, excluded, variableTransactions, variableIncomeTransactions } = state.cashflow;
  return _.chain(envelopes).flatMap(envelope => envelope.actuals).concat(excluded).concat(variableTransactions)
    .concat(variableIncomeTransactions)
    .value();
}

function normalizeTrackingCategories(trackingCategories) {
  return _.map(trackingCategories, category => {
    return {
      categoryId: category.categoryId,
      categoryName: cashflowViewConsts.CATEGORY_NAMES.TRACKING,
      expense: category.categoryName,
      cashflowCategory: cashflowViewConsts.CASHFLOW_CATEGORIES.TRACKING,
      icon: category.icon,
      label: category.categoryName,
      pluralLabel: category.categoryName,
      style: cashflowViewConsts.CATEGORY_STYLE[cashflowViewConsts.CASHFLOW_CATEGORIES.TRACKING],
    };
  });
}

const getters = {
  allCategories: (state, getters) => [...cashflowViewConsts.CATEGORIES, ...normalizeTrackingCategories(getters.trackingCategories)],
  currentBudgetDate: () => cashflowHistory.getCurrentBudgetDate(),
  presentedBudget: state => _.find(state.allCashflows, { budgetDate: state.presentedBudgetDate }),
  allBudgetDates: state => _getAllBudgetDates(state),
  lastBudgetDate: state => _.last(_getAllBudgetDates(state)),
  predictedBalanceAtEndOfMonth: state => (state.cashflow.predictedBalance === 0 ? 0 : -1 * state.cashflow.predictedBalance),
  riseupGoal: state => state.cashflow.balancedRiseupGoal,
  isGoalCustom: state => !_.isNil(state.cashflow.riseupGoalEnvelope.details.riseupGoalInputAmount),
  budgetUpdatedAt: state => state.cashflow.lastUpdatedAt,
  totalIncome: state => state.cashflow.variableIncomeSum + state.cashflow.incomeEnvelopesActualSum,
  totalExpense: state => state.cashflow.variableActualSum + state.cashflow.expenseEnvelopesActualSum,
  monthName: state => moment(state.presentedBudgetDate).locale('he').format('MMM'),
  monthFullName: state => moment(state.presentedBudgetDate).locale('he').format('MMMM'),
  balance: state => -1 * state.cashflow.balance,
  cashflowGraphHistory: (state, getters) => _.chain(state.allCashflows)
    .thru(cashflows => cashflowHistory.getCashflowsForPastMonthsGraph(cashflows, state.presentedBudgetDate, getters.currentBudgetDate))
    .map(budget => {
      const cashflow = reactiveCashflow.createCashflow(budget, DateLocales.EN_IL);
      return {
        cashflowDate: budget.budgetDate,
        total: -1 * cashflow.balance,
      };
    })
    .value(),
  pastCashflowStatus: (state, getters) => {
    const previousBudgetDate = cashflowHistory.getPrevBudgetDate(getters.currentBudgetDate);
    return _.chain(state.allCashflows)
      .thru(cashflows => cashflowHistory.getCashflowsForEnvelopeTrend(cashflows, previousBudgetDate, 6))
      .map(budget => {
        const cashflow = reactiveCashflow.createCashflow(budget, DateLocales.EN_IL);
        return {
          cashflowDate: budget.budgetDate,
          isPositive: cashflow.balance < 0,
        };
      })
      .value();
  },
  excluded: state => state.cashflow.excluded,
  hasExcluded: state => cashflowNormalizer.visibleExcluded(state.cashflow.excluded).length > 0,
  isPresentingCurrentMonth: (state, getters) => state.presentedBudgetDate === getters.currentBudgetDate,
  isPresentingLastMonth: (state, getters) => state.presentedBudgetDate === getters.lastBudgetDate,
  trackingCategories: state => state.cashflowUITree[cashflowViewConsts.cashflowTypes.trackingCategory],
  oneTimeSavingCategory: state => state.cashflowUITree[cashflowViewConsts.cashflowTypes.oneTimeSaving][0],
  weeks: state => state.cashflowUITree[cashflowViewConsts.cashflowTypes.variableExpense].weekly.realExpenses.categories,
  currentWeek: (state, getters) => _.find(getters.weeks, w => w.timeFrameDetails.isCurrentWeek),
  remainingWeeklyVariables: (state, getters) => getters.currentWeek.remaining,
  hasNextWeek: (state, getters) => getters.currentWeek.weekIndex < getters.weeks.length - 1,
  nextWeekRemainingVariables: (state, getters) => (getters.hasNextWeek ? getters.weeks[getters.currentWeek.weekIndex + 1].remaining : 0),
  fixedExpenseCategories: state => state.cashflow.fixedTree.fixedExpenseEnvelopes.map(e => e.details.expense),
  fixedIncomeCategories: state => state.cashflow.fixedTree.fixedIncomeEnvelopes.map(e => e.details.expense),
  transactionsInCurrentCashflow,
  allExcludedTransactions: state => _.chain(state.allCashflows)
    .flatMap(cashflow => cashflow.excluded)
    .keyBy('transactionId')
    .value(),
};

const actions = {
  async initBudgets({ commit, dispatch, state }, { budgetDate }) {
    performanceService.markStart(PerformanceMarks.INIT_BUDGETS);
    performanceService.markStart(PerformanceMarks.INIT_CURRENT_BUDGET);
    DDLogs.info('initBudgets', { budgetDate });
    const budgetToDisplayFirst = await cashflowHistory.getBudget(budgetDate);
    commit('setCashflowStartDay', budgetToDisplayFirst.cashflowStartDay);
    if (state.allCashflows.length === 0) {
      commit('setCashflows', [budgetToDisplayFirst]);
      await dispatch('updatePresentedBudgetDate', budgetToDisplayFirst.budgetDate);
    }
    dispatch('loadAllBudgets');
    performanceService.markEnd(PerformanceMarks.INIT_CURRENT_BUDGET);
  },
  async initCashflowStartDay({ commit }) {
    const cashflowStartDay = await CashflowViewApi.getCashflowStartDay();
    commit('setCashflowStartDay', cashflowStartDay);
  },
  async loadAllBudgets({ state, commit, dispatch, getters, rootGetters }) {
    DDLogs.info('loadAllBudgets', { presentedBudgetDate: state.presentedBudgetDate });
    if (!state.presentedBudgetDate) {
      return;
    }
    performanceService.markStart(PerformanceMarks.INIT_PREVIOUS_BUDGETS);

    const budgets = await CashflowViewApi.fetchPreviousBudgets({
      budgetDate: state.presentedBudgetDate,
      numMonthsBack: cashflowViewConsts.MONTHS_BACK_TO_LOAD,
    });
    // This loads also FUTURE budgets which we have no use for, so we throw them out
    const upToNowCashflows = _.reject(budgets, ({ budgetDate }) => (budgetDate > getters.currentBudgetDate));
    if (!_.isEmpty(upToNowCashflows)) {
      commit('setCashflowStartDay', _.last(upToNowCashflows).cashflowStartDay);
    }
    commit('setCashflows', upToNowCashflows);
    performanceService.markEnd(PerformanceMarks.INIT_PREVIOUS_BUDGETS);

    if (!getters.presentedBudget) {
      const latestBudgetDate = _.max(getters.allBudgetDates);
      await dispatch('updatePresentedBudgetDate', latestBudgetDate);
    }
    commit('setFinishLoadingAllBudgets');
    dispatch('lazyLoadCashflowEnrichment').then(() => dispatch('logLatestSeenTransactionCreationDate'));

    if (rootGetters['featureFlags/showCashflowSearch']) {
      dispatch('cashflowSearch/initCashflowSearch', { allCashflows: upToNowCashflows, plans: rootGetters['planAhead/plans'] }, { root: true });
    }
    performanceService.markEnd(PerformanceMarks.INIT_BUDGETS);
  },
  async updatePresentedBudgetDate({ commit, dispatch, getters }, budgetDate) {
    const parsedBudgetDate = budgetDate === 'current' ? cashflowHistory.getCurrentBudgetDate() : budgetDate;
    commit('setPresentedBudgetDate', parsedBudgetDate);
    await dispatch('resetBudgetTree');
  },
  async resetBudgetTree({ commit, dispatch, getters, state }) {
    const budget = getters.presentedBudget;
    if (budget) {
      const cashflow = reactiveCashflow.createCashflow({ ...budget, cashflowStartDay: state.cashflowStartDay }, DateLocales.EN_IL);
      const cashflowUITree = cashflowNormalizer.normalizeCashflow(cashflow);
      commit('setCashflow', cashflow);
      commit('setCashflowUITree', cashflowUITree);
      await dispatch('insights/reactiveInsights', null, { root: true });
    }
  },
  async moveToSpecificBudgetMonth({ dispatch, commit, state }, { budgetDate }) {
    await dispatch('backfillBudgetDates', { budgetDate, includeCurrent: true });
    await router.push({ params: { budgetDate }, query: router.currentRoute.query });
  },
  async moveToNextBudgetMonth({ dispatch }) {
    const nextBudgetDate = cashflowHistory.getNextBudgetDate(state.presentedBudgetDate);
    await dispatch('moveToSpecificBudgetMonth', { budgetDate: nextBudgetDate });
  },
  async moveToPrevBudgetMonth({ dispatch, state }) {
    const prevBudgetDate = cashflowHistory.getPrevBudgetDate(state.presentedBudgetDate);
    await dispatch('moveToSpecificBudgetMonth', { budgetDate: prevBudgetDate });
  },
  async loadBudgetDate({ dispatch, commit, getters, rootGetters }, budgetDate) {
    if (!getters.allBudgetDates.includes(budgetDate) && moment(budgetDate).isSameOrAfter(cashflowViewConsts.GOOD_CASHFLOWS_START)) {
      DDLogs.info('loadBudgetDate', { budgetDate });
      const budget = await CashflowViewApi.fetchBudget({ budgetDate });
      await commit('setCashflows', _.sortBy([...state.allCashflows, budget], 'budgetDate'));
      dispatch('cashflowSearch/addTransactionsForSearch', { newCashflows: [budget], plans: rootGetters['planAhead/plans'] }, { root: true });
    }
  },
  async backfillBudgetDates(
    { dispatch, commit, getters, rootGetters },
    { budgetDate, includeCurrent = false, monthsBack = cashflowViewConsts.MONTHS_BACK_TO_LOAD },
  ) {
    const necessaryBudgetDates = _.times(monthsBack, i => moment(budgetDate).subtract(i + 1, 'months').format('YYYY-MM'));
    if (includeCurrent) {
      necessaryBudgetDates.push(budgetDate);
    }
    // allBudgetDates has budget dates that are already in memory
    const budgetsToLoad = _.difference(necessaryBudgetDates, getters.allBudgetDates);
    if (_.isEmpty(budgetsToLoad)) {
      return;
    }
    const budgetDateToLoadFrom = _.max(budgetsToLoad);
    const numMonthsBack = necessaryBudgetDates.length - _.indexOf(necessaryBudgetDates, budgetDateToLoadFrom) - 1;
    DDLogs.info('backfillBudgetDates', { budgetDateToLoadFrom });
    const budgets = await CashflowViewApi.fetchPreviousBudgets({ budgetDate: budgetDateToLoadFrom, numMonthsBack });
    await commit('setCashflows', _.sortBy([...budgets, ...state.allCashflows], 'budgetDate'));
    dispatch('cashflowSearch/addTransactionsForSearch', { newCashflows: budgets, plans: rootGetters['planAhead/plans'] }, { root: true });
  },
  logLatestSeenTransactionCreationDate({ getters }) {
    if (router.currentRoute.query.incognito) {
      return;
    }
    const latestSeenTransactionCreationDate = _.maxBy(getters.transactionsInCurrentCashflow, t => t.createdAt)?.createdAt;
    const isPresentingCurrenCashflowMonth = getters.presentedBudget.budgetDate === getters.currentBudgetDate;
    if (latestSeenTransactionCreationDate && isPresentingCurrenCashflowMonth) {
      LatestSeenTransactionDateApi.setLatestSeenTransactionCreationDate(latestSeenTransactionCreationDate);
    }
  },
  async lazyLoadCashflowEnrichment({ dispatch, commit }) {
    const [savingTransactionIds, latestSeenTransactionCreationDate, { order }] = await Promise.all([
      HamsterApi.getCustomerSavingTransactions(),
      LatestSeenTransactionDateApi.getLatestSeenTransactionCreationDate(),
      trackingCategoriesApi.getTrackingCategoriesDisplaySettings(),
    ]);
    cashflowNormalizer.setSavingTransactions(savingTransactionIds.map(t => t.transactionId));
    cashflowNormalizer.setCategoriesOrder(order);
    if (latestSeenTransactionCreationDate) {
      cashflowNormalizer.setLatestSeenTransactionCreationDate(latestSeenTransactionCreationDate);
    }
    commit('setTrackingCategoriesOrder', order);
    dispatch('resetBudgetTree');
  },
  setTrackingCategoriesOrder({ commit }, trackingCategoriesOrder) {
    commit('setTrackingCategoriesOrder', trackingCategoriesOrder);
    cashflowNormalizer.setCategoriesOrder(trackingCategoriesOrder);
  },
};

function _getAllBudgetDates(state) {
  return state.allCashflows.map(({ budgetDate }) => budgetDate);
}

// mutations
const mutations = {
  setCashflowOptimistic(state, newCashflow) {
    const index = _.findIndex(state.allCashflows, { budgetDate: state.presentedBudgetDate });
    // use Vue.set to update arrays and induce reactivity
    Vue.set(state.allCashflows, index, newCashflow);
  },
  setCashflows(state, allCashflows) {
    state.allCashflows = allCashflows;
  },
  setCashflow(state, cashflow) {
    state.cashflow = cashflow; //eslint-disable-line
  },
  setCashflowUITree(state, cashflowUITree) {
    state.cashflowUITree = cashflowUITree;
  },
  setPresentedBudgetDate(state, budgetDate) {
    state.presentedBudgetDate = budgetDate;
  },
  setFinishLoadingAllBudgets(state) {
    state.finishLoadingAllBudgets = true;
  },
  setCashflowStartDay(state, cashflowStartDay) {
    state.cashflowStartDay = cashflowStartDay;
  },
  setTrackingCategoriesOrder(state, trackingCategoriesOrder) {
    state.trackingCategoriesOrder = trackingCategoriesOrder;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
