import { render, staticRenderFns } from "./DDCelebrateAsset.vue?vue&type=template&id=1f4dfc54&scoped=true&"
import script from "./DDCelebrateAsset.vue?vue&type=script&lang=js&"
export * from "./DDCelebrateAsset.vue?vue&type=script&lang=js&"
import style0 from "./DDCelebrateAsset.vue?vue&type=style&index=0&id=1f4dfc54&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f4dfc54",
  null
  
)

export default component.exports