import sumsumApi from '@/api/SumsumApi';
import _ from 'lodash';

const state = {
  story: {},
  viewerStory: {},
  singleShareFinishStory: {},
  storyName: null,
};

const getters = {
  name: state => state.story.name,
  pages: state => state.story.pages,
  shareStoryPages: state => _.filter(state.story.pages, { canShareInStory: true }),
  // Create the viewer story wrapper with the _viewer convention
  viewerStoryName: state => `${state.storyName}_viewer`,
  viewerSingleStoryFinishName: state => `${state.storyName}_singleShareFinish`,
  // Create the viewer story start pages with 'start' convention and the finish with 'finish' convention in the page names
  startViewerPages: state => _.filter(state.viewerStory.pages, p => _.includes(p.name, 'start')),
  finishViewerPages: state => _.filter(state.viewerStory.pages, p => _.includes(p.name, 'finish')),
  singleFinishViewerPages: state => _.filter(state.singleShareFinishStory.pages, p => _.includes(p.name, 'finish')),
  viewerPages: (state, getters) => [...getters.startViewerPages, ...getters.shareStoryPages, ...getters.finishViewerPages],
};

const actions = {
  async fetchStory({ commit, getters }, storyName) {
    commit('setStoryName', storyName);
    const story = await sumsumApi.getStory(storyName);
    const viewerStory = await sumsumApi.getStory(getters.viewerStoryName);
    const singleShareFinishStory = await sumsumApi.getStory(getters.viewerSingleStoryFinishName);
    commit('setStory', story || {});
    commit('setViewerStory', viewerStory || {});
    commit('setSingleShareFinishStory', singleShareFinishStory || {});
    return story;
  },
};

const mutations = {
  setStory(state, story) {
    state.story = story;
  },
  setViewerStory(state, viewerStory) {
    state.viewerStory = viewerStory;
  },
  setSingleShareFinishStory(state, singleShareFinishStory) {
    state.singleShareFinishStory = singleShareFinishStory;
  },
  setStoryName(state, storyName) {
    state.storyName = storyName;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
