import _ from 'lodash';
import DDLogs from '@/DDLogs';
import * as moment from 'moment';
import { getSourceConfigurationBySourceName } from '@/constants/sourcesConfiguration';
import { OBKConnectionErrors } from '@/pages/responsive-pages/non-authenticated/open-banking/connection-failures/consts';
import onboardingApi from '../../api/OnbordingApi';
import consts from '../../constants/onboarding-state';
import CredentialsApi from '../../api/CredentialsApi';
import MetricApi from '../../api/MetricsApi';
import AsyncFallbackApi from '../../api/AsyncFallbackApi';
import FinancialProfileScoreApi from '../../api/FinancialProfileScoreApi';

const state = {
  isLoading: false,
  pageName: null,
  activatedAt: null,
  error: null,
  pageParams: null,
  credentials: [],
  saveCredentialsError: false,
  nameAlreadyUsedError: false,
  loadingStartTime: null,
  asyncTimeoutReached: false,
  timeout: null,
  connectionSuccess: false,
};

const TIMEOUT_IN_SECS = 60;

const getters = {
  invalidCredentials: state => state.error === 'invalidCredentials',
  obkConnectionError: state => OBKConnectionErrors.includes(state.error),
  sourceName: state => (state.pageParams ? state.pageParams.sourceName : null),
  displayName: (state, getters) => getSourceConfigurationBySourceName(getters.sourceName).displayName,
  onboardingCompleted: state => state.pageName && state.pageName === consts.ONBOARDING_PAGE.CASHFLOW,
  // using anther getter instead of !onboardingCompleted,
  // because we migth have old customers without OB state, so onboaringCompleted will return false
  isOnboarding: state => state.pageName && state.pageName !== consts.ONBOARDING_PAGE.CASHFLOW,
};

const actions = {
  async handleOnboardingState({ commit, dispatch }) {
    if (state.isLoading) {
      dispatch('handleLoadingState');
    }
  },

  async getOnboardingState() {
    const { onboardingState } = await onboardingApi.fetchOnboardingState();
    return onboardingState;
  },

  async setOnboardingState({ commit, dispatch, state }, onboardingState) {
    commit('setPageName', onboardingState.onboardingPage);
    commit('setActivatedAt', onboardingState.activatedAt);
    commit('setPageParams', onboardingState.pageParams);
    if (onboardingState.pageParams) {
      commit('setLoadingState', !!onboardingState.pageParams.loading);
      commit('setError', onboardingState.pageParams.error);
      if (onboardingState.pageParams.loadingStartTime) {
        commit('setLoadingStartTime', new Date(onboardingState.pageParams.loadingStartTime));
      }
    } else {
      commit('setLoadingState', false);
      commit('setError', null);
      commit('setLoadingStartTime', null);
    }
    if (!state.isLoading) {
      commit('setAsyncTimeoutReached', false);
      await dispatch('clearTimeout');
    }
  },

  async fetchOnboardingState({ dispatch }) {
    const onboardingState = await dispatch('getOnboardingState');
    DDLogs.log('fetchOnboardingState', onboardingState);
    if (onboardingState) {
      await dispatch('setOnboardingState', onboardingState);
    }
  },

  async fetchOnboardingStateIfFinishedLoading({ commit, dispatch }) {
    const onboardingState = await dispatch('getOnboardingState');
    DDLogs.log('fetchOnboardingStateIfFinishedLoading', onboardingState);
    if (onboardingState && !onboardingState.isLoading) {
      dispatch('setOnboardingState', onboardingState);
    }
  },
  handleLoadingState({ commit, state, dispatch }) {
    const loadingTimeInSecs = moment().diff(moment(state.loadingStartTime), 'seconds');
    if (loadingTimeInSecs > TIMEOUT_IN_SECS) {
      commit('setAsyncTimeoutReached', true);
    } else {
      dispatch('createTimeout', loadingTimeInSecs);
    }
  },
  createTimeout({ commit, state, dispatch }, loadingTimeInSecs) {
    dispatch('clearTimeout');
    const timeout = setTimeout(async () => {
      await dispatch('fetchOnboardingStateIfFinishedLoading');
      if (state.isLoading) {
        commit('setAsyncTimeoutReached', true);
      }
    }, (TIMEOUT_IN_SECS - loadingTimeInSecs) * 1000);
    commit('setTimeout', timeout);
  },
  async onboardingPageSubmit({ dispatch }) {
    MetricApi.sendCounter('onboarding.next.clicked', { currentPage: state.pageName });
    const { onboardingState } = await onboardingApi.onboardingPageSubmit(state.pageName);
    dispatch('setOnboardingState', onboardingState);
  },
  async onboardingPageSubmitWithLoading({ commit, dispatch }) {
    commit('setLoadingState', true);
    dispatch('onboardingPageSubmit');
  },

  async bankDetailsAccountInvestigationSubmit({ commit, dispatch }, inputs) {
    const { onboardingState } = await onboardingApi.bankDetailsAccountInvestigationSubmit(inputs);
    dispatch('setOnboardingState', onboardingState);
    return onboardingState;
  },
  async openBankingSwitchToCredentials({ commit, dispatch }) {
    commit('setLoadingState', true);
    const { onboardingState } = await onboardingApi.openBankingSwitchToCredentials();
    dispatch('setOnboardingState', onboardingState);
    // todo yy - remove hard coded leumi
    commit('setPageParams', { sourceName: 'לאומי' });
  },
  async openBankingPartiallyAuthorisedContinue({ commit, dispatch }) {
    commit('setLoadingState', true);
    const { onboardingState } = await onboardingApi.openBankingContinue();
    dispatch('setOnboardingState', onboardingState);
  },
  async incomeInvestigationSubmit({ commit, dispatch }, inputs) {
    commit('setLoadingState', true);
    const financialProfileScore = await FinancialProfileScoreApi.getFinancialProfileScore();
    const { onboardingState } = await onboardingApi.incomeInvestigationSubmit(inputs, financialProfileScore || { connectedPercentage: 0 });
    dispatch('setOnboardingState', onboardingState);
  },
  async highIncomeExclusionSubmit({ commit, dispatch }, includedIncomes) {
    commit('setLoadingState', true);
    const { onboardingState } = await onboardingApi.highIncomeExclusionSubmit(includedIncomes);
    dispatch('setOnboardingState', onboardingState);
  },
  async expenseInvestigationSubmit({ commit, dispatch }, inputs) {
    commit('setLoadingState', true);
    const { onboardingState } = await onboardingApi.expenseInvestigationSubmit(inputs);
    dispatch('setOnboardingState', onboardingState);
  },
  async forwardLookingInformationSubmit({ commit, dispatch }) {
    commit('setLoadingState', true);
    const { onboardingState } = await onboardingApi.forwardLookingInformationSubmit();
    dispatch('setOnboardingState', onboardingState);
  },
  async planAheadSubmit({ commit, dispatch }) {
    commit('setLoadingState', true);
    const { onboardingState } = await onboardingApi.planAheadSubmit();
    dispatch('setOnboardingState', onboardingState);
  },
  async oshBalanceInformationSubmit({ commit, dispatch }) {
    commit('setLoadingState', true);
    const { onboardingState } = await onboardingApi.oshBalanceInformationSubmit();
    dispatch('setOnboardingState', onboardingState);
  },
  async securityInformationSubmit({ commit, dispatch }) {
    commit('setLoadingState', true);
    const { onboardingState } = await onboardingApi.securityInformationSubmit();
    dispatch('setOnboardingState', onboardingState);
  },
  clearTimeout({ state }) {
    if (state.timeout) {
      clearTimeout(state.timeout);
    }
  },
  async bankDetailsSubmit({ commit, dispatch, state }, { sourceName, credentials, name, sourceType, provider }) {
    commit('setSaveCredentialsError', false);
    if (!state.error) {
      await dispatch('validateName', { sourceName, name });
      if (state.nameAlreadyUsedError) {
        return;
      }
    }
    try {
      commit('setLoadingStartTime', moment().toDate());
      commit('setLoadingState', true);
      await onboardingApi.bankDetailsSubmitCredentials({ sourceName, credentials, name, sourceType, provider });
      dispatch('createTimeout', 0);
    } catch (e) {
      commit('setSaveCredentialsError', true);
      commit('setLoadingState', false);
    }
  },
  async bankDetailsSubmitDirect() {
    await onboardingApi.bankDetailsSubmitDirect();
  },
  async bankDetailsSubmitOpenBanking({ state }, { credentialsId, sourceName }) {
    await onboardingApi.bankDetailsSubmitOpenBankingApprovedConsent(sourceName, credentialsId);
  },
  async creditCardDetailsSubmitOpenBanking({ state }, { credentialsId, sourceName }) {
    await onboardingApi.creditCardDetailsSubmitOpenBankingApprovedConsent(sourceName, credentialsId);
  },
  async bankDetailsOpenBankingPartiallyAuthorized() {
    await onboardingApi.bankDetailsSubmitOpenBankingPartiallyAuthorised();
  },
  async creditCardDetailsSubmit({ commit, dispatch, state }, { sourceName, credentials, name, sourceType, provider }) {
    commit('setSaveCredentialsError', false);
    if (!state.error) {
      await dispatch('validateName', { sourceName, name });
      if (state.nameAlreadyUsedError) {
        return;
      }
    }
    try {
      commit('setLoadingStartTime', moment().toDate());
      commit('setLoadingState', true);
      await onboardingApi.creditCardDetailsSubmit({ sourceName, credentials, name, sourceType, provider });
      dispatch('createTimeout', 0);
    } catch (e) {
      commit('setSaveCredentialsError', true);
      commit('setLoadingState', false);
    }
  },

  async validateName({ commit, dispatch, state }, { sourceName, name }) {
    await dispatch('fetchCredentials');
    const existingCreds = _.find(state.credentials, { sourceName, name });
    if (existingCreds) {
      commit('setNameAlreadyInUseError', true);
    }
  },
  async fetchCredentials({ commit }) {
    const credentials = await CredentialsApi.fetchCredentials();
    commit('setCredentials', credentials);
  },
  async publishAsyncFallback() {
    await AsyncFallbackApi.publishAsyncFallback();
  },
};

const mutations = {
  setPageName(state, pageName) {
    state.pageName = pageName;
  },
  setActivatedAt(state, activatedAt) {
    state.activatedAt = activatedAt;
  },
  setPageParams(state, pageParams) {
    state.pageParams = pageParams;
  },
  setLoadingState(state, isLoading) {
    DDLogs.log(`setLoadingState to ${isLoading}`);
    state.isLoading = isLoading;
  },
  setLoadingStartTime(state, loadingStartTime) {
    state.loadingStartTime = loadingStartTime;
  },
  setError(state, error) {
    state.error = error;
  },
  setNameAlreadyInUseError(state, nameAlreadyUsedError) {
    state.nameAlreadyUsedError = nameAlreadyUsedError;
  },
  setSaveCredentialsError(state, saveCredentialsError) {
    state.saveCredentialsError = saveCredentialsError;
  },
  setCredentials(state, credentials) {
    state.credentials = credentials;
  },
  setAsyncTimeoutReached(state, asyncTimeoutReached) {
    DDLogs.log('setAsyncTimeoutReached', asyncTimeoutReached);
    state.asyncTimeoutReached = asyncTimeoutReached;
  },
  setTimeout(state, timeout) {
    state.timeout = timeout;
  },
  setConnectionSuccess(state, connectionSuccess) {
    state.connectionSuccess = connectionSuccess;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
