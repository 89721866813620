<template>
  <div>
    <div class="section">
      <div class="question">למה התחלנו עם מיטב?</div>
      <div>חיפשנו שותף טכנולוגי שאיתו אפשר ליצור חיסכון דינמי שמתאים את החיסכון לחיים שלך.</div>
    </div>
    <div class="section">
      <div class="question">מה התנאים של הקופה?</div>
      <div>ללקוחות רייזאפ יש הטבה בדמי הניהול: 0.75% מהצבירה.</div>
    </div>

    <div class="section">
      <span class="question">אם יש לך עוד שאלות, </span>
      ריכזנו כאן את
      <span class="link" v-on:click="openCommonQuestions()">כל מה שצריך לדעת</span>.
    </div>

    <riseup-button :action="goToOpenKgl" variant="primary" title="לפתיחת קופה ב-5 דק׳!" size="slim"/>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import DDLogs from '@/DDLogs';
import { mapActions } from 'vuex';

export default {
  name: 'AboutMeitav',
  components: {
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    insight: { type: Object, require: true },
  },
  created() {
    Segment.trackUserInteraction('StartSaveAboutMeitav_Loaded');
  },
  methods: {
    ...mapActions('insights', ['markAsRead']),
    ...mapActions('modalRootStore', ['closeModal', 'openModal']),

    openCommonQuestions() {
      Segment.trackUserInteraction('StartSaveAboutMeitav_More');
      window.open('/pdfs/riseup_Q&A.pdf', '_blank');
    },
    goToOpenKgl() {
      Segment.trackUserInteraction('StartSaveAboutMeitav_Confirmed');
      window.open('https://saving.riseup.co.il', '_blank');
      this.closeModal();
      this.markAsRead(this.insight).catch(error => {
        DDLogs.error('Failed to mark insight as read', { insight: this.insight, error });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './start-save.scss';
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.link {
  color: $riseup_blue;
  text-decoration: underline;
  cursor: pointer;
}

</style>
