import Vue from 'vue';
import _ from 'lodash';
import { SUBSCRIPTION_TYPES } from '@/constants/subscription-state';
import moment from 'moment';
import BillyApi from '../../api/BillyApi';
import MetricsApi from '../../api/MetricsApi';
import DDLogs from '../../DDLogs';
import Segment from '../../Segment';
import PaymeInterface from '../../Payme';
import SubscriptionStateApi from '../../api/SubscriptionStateApi';

const SUBSCRIPTION_STEPS = {
  subscriptionType: 'choose-subscription-type',
  creditCard: 'credit-card',
  summary: 'summary',
  done: 'done',
};

const CREDIT_CARD_FIELDS = {
  cardNumber: 'cardNumber',
  expiration: 'expiration',
  cvv: 'cvv',
  socialId: 'payerSocialId',
};

const PERSONAL_INFO_FIELDS = {
  firstName: 'payerFirstName',
  lastName: 'payerLastName',
  phoneNumber: 'payerPhone',
};

function _handlePaymentError(error, isUpdate) {
  const isKnownError = error && error.payload && error.payload.status_error_code;
  const errorDetails = isKnownError ? error.payload.status_error_details : 'unknown';
  const errorCode = isKnownError ? error.payload.status_error_code : undefined;
  const errorMessage = error && error.message ? error.message : undefined;

  if (isKnownError) {
    MetricsApi.sendCounter('payment.tokenize.error', { payme_status_error_code: errorCode });
  } else {
    MetricsApi.sendCounter('payment.tokenize.unknown-error');
  }

  if (isUpdate) {
    DDLogs.errorBusinessEvent('Update Subscription failed', { errorMessage, error });
    Segment.trackUserGot('UpdateCreditCardTokenizeError', {
      errorDetails,
      errorCode,
    });
  } else {
    DDLogs.errorBusinessEvent('Subscription failed', { errorMessage, error });
    Segment.trackUserGot('SubscriptionTokenizeError', {
      errorDetails,
      errorCode,
    });
  }
}

const state = {
  flowStep: null,
  fieldsInstance: null,
  fields: null,
  firstBillingDate: null,
  personalInfo: {
    [PERSONAL_INFO_FIELDS.firstName]: { value: null, isValid: false },
    [PERSONAL_INFO_FIELDS.lastName]: { value: null, isValid: false },
    [PERSONAL_INFO_FIELDS.phoneNumber]: { value: null, isValid: false },
  },
  creditCard: {
    [CREDIT_CARD_FIELDS.cardNumber]: { isEmpty: true, isValid: false, error: '' },
    [CREDIT_CARD_FIELDS.expiration]: { isEmpty: true, isValid: false, error: '' },
    [CREDIT_CARD_FIELDS.cvv]: { isEmpty: true, isValid: false, error: '' },
    [CREDIT_CARD_FIELDS.socialId]: { isEmpty: true, isValid: false, error: '' },
  },
  tokenizationError: null,
  creditCardIsValid: false,
  loadingToken: false,
  loadingBenefit: false,
  eligibilityError: null,
  paymeToken: null,
  didEnteredCcFields: false,
  type: null,
  pricing: {
    annual: null,
    monthly: null,
    advisory: null,
  },
  enableTerms: false,
  cardNumberMask: null,
  nextPaymentDate: null,
  price: null,
  remainingIterations: null,
  offeringData: null,
};

function trackValidationFailures(cardIsValid) {
  if (!cardIsValid) {
    _.chain(state.creditCard)
      .pickBy(value => !value.isValid)
      .keys()
      .forEach(field => Segment.trackUserGot('CreditCardValidationFailed', { field }))
      .value();
  }
}

const getters = {
  SUBSCRIPTION_FLOW_STEPS: () => SUBSCRIPTION_STEPS,
  personalInfoFields: () => PERSONAL_INFO_FIELDS,
  creditCardFields: () => CREDIT_CARD_FIELDS,
  cardLast4Digits: state => _.takeRight(state.paymeToken && state.paymeToken.card.cardMask, 4).join(''),
  validPersonalInfo: state => _.every(_.map(_.values(PERSONAL_INFO_FIELDS), field => state.personalInfo[field].isValid)),
  subscriptionPrice: state => (state.type === SUBSCRIPTION_TYPES.YEARLY ? state.pricing.annual : state.pricing.monthly),
  subscriptionTypSelectedText: state => (state.type === SUBSCRIPTION_TYPES.YEARLY ? 'שנתי' : 'חודשי'),
  isYearlySubscription: state => state.type === SUBSCRIPTION_TYPES.YEARLY,
  totalMonthlyPricingPerYear: state => state.pricing.monthly * 12,
  yearlyPricingPerMonth: state => (state.pricing.annual / 12).toFixed(2),
  yearlySavingAgainstMonthly: state => state.pricing.monthly * 12 - state.pricing.annual,
  yearlySavingAgainstMonthlyPercentage: state => ((state.pricing.monthly * 12 - state.pricing.annual) / (state.pricing.monthly * 12)) * 100,
  newFormattedCardNumberMask: state => {
    if (state.cardNumberMask) {
      const lastFourDigits = state.cardNumberMask.slice(-4);
      return `${lastFourDigits} ••••`;
    }
    return null;
  },
  formattedFirstBillingDate: state => moment(state.firstBillingDate).format('D.M.YYYY'),
  formattedNextPaymentDate: state => moment(state.nextPaymentDate).format('D.M.YYYY'),
  formattedFirstBillingDatePlusYear: state => moment(state.firstBillingDate).add(1, 'year').format('D.M.YYYY'),
  existingRemainingIterations: state => (state.remainingIterations && state.remainingIterations !== -1 ? state.remainingIterations : null),
  nextFormattedPaymentDate: (state, getters) => (getters.existingRemainingIterations
    ? moment(state.nextPaymentDate).add(state.remainingIterations, 'month').format('D.M.YYYY')
    : moment(state.nextPaymentDate).format('D.M.YYYY')),
  isMonthlySubscription: state => state.type === SUBSCRIPTION_TYPES.MONTHLY,
  subscriptionTypeText: (state, getters) => {
    if (state.offeringData && state.offeringData.bundleOffering) {
      return state.offeringData.bundleOffering.description;
    }
    return getters.isYearlySubscription ? 'שנתי' : 'חודשי';
  },
  subscriptionOfferingId: (state, getters) => (getters.isYearlySubscription ? 'cashflow-annual-subscription' : 'cashflow-monthly-subscription'),
};

const actions = {
  async fetchCreditCardMask({ commit }) {
    try {
      const creditCardDetails = await BillyApi.getCreditCardDetails();
      if (creditCardDetails) {
        commit('setCardNumberMask', creditCardDetails.cardNumberMask);
      }
    } catch (error) {
      commit('setCardNumberMask', undefined);
    }
  },
  setPersonalInfoField({ commit }, { field, value, isValid, errorMessage }) {
    commit('setPersonalInfoField', { field, value, isValid, errorMessage });
  },
  setCreditCardField({ commit }, { field, isEmpty, isValid }) {
    if (!state.didEnteredCcFields) {
      MetricsApi.sendCounter('subscription.payment-details.entered');
      commit('setDidEnteredCcFields', true);
    }
    commit('setCreditCardField', { field, isEmpty, isValid, error: '' });
  },
  setCreditCardValidations({ commit, state }) {
    if (!state.creditCard[CREDIT_CARD_FIELDS.cardNumber].isValid) {
      commit('setCreditCardValidationError', { field: CREDIT_CARD_FIELDS.cardNumber, error: 'אפשר לבדוק שוב את מספר הכרטיס?' });
    }
    if (!state.creditCard[CREDIT_CARD_FIELDS.expiration].isValid) {
      commit('setCreditCardValidationError', { field: CREDIT_CARD_FIELDS.expiration, error: 'התוקף לא נכון' });
    }
    if (!state.creditCard[CREDIT_CARD_FIELDS.cvv].isValid) {
      commit('setCreditCardValidationError', { field: CREDIT_CARD_FIELDS.cvv, error: '3 ספרות בגב הכרטיס' });
    }
    if (!state.creditCard[CREDIT_CARD_FIELDS.socialId].isValid) {
      commit('setCreditCardValidationError', { field: CREDIT_CARD_FIELDS.socialId, error: 'אפשר לבדוק שוב את מספר תעודת הזהות?' });
    }
    const cardIsValid = _.every(state.creditCard, field => field.isValid);
    commit('setCreditCardIsValid', cardIsValid);
    trackValidationFailures(cardIsValid);
    return cardIsValid;
  },
  async initPaymeInterface({ commit }) {
    const paymeInterface = await PaymeInterface.initOrGet();
    if (paymeInterface) {
      commit('setFieldsInstance', paymeInterface);
    }
  },
  async fetchFirstBillingDate({ commit }) {
    const { firstBillingDate } = await SubscriptionStateApi.fetchFirstBillingDate();
    commit('setFirstBillingDate', { firstBillingDate });
  },
  async createSubscriptionV2({ state, dispatch, commit, getters }, { percentageDiscount }) {
    await dispatch('setCreditCardValidations');
    if (state.creditCardIsValid) {
      Segment.trackUserGot('SubscriptionSubmitSucceededOnValidation', { subscriptionVersion: 'v2' });
      try {
        commit('setLoadingToken', true);
        const paymeToken = await dispatch('getOrCreatePaymentToken');
        commit('setPaymeToken', paymeToken);
        Segment.trackUserGot('SubscriptionSubscribe');
        await BillyApi.purchaseOffering({
          token: paymeToken.token,
          oneOffOfferings: [],
          subscriptionOfferings: [{ offeringId: getters.subscriptionOfferingId, numberOfInstallments: 1 }],
          bundleOfferings: [],
        });
        MetricsApi.sendCounter('subscription.success');
        Segment.trackUserGot('SubscriptionSubscribeSucceeded');
        commit('setStep', SUBSCRIPTION_STEPS.done);
        return true;
      } catch (error) {
        _handlePaymentError(error, false);
        commit('setTokenizationError', error);
        return false;
      } finally {
        commit('setLoadingToken', false);
      }
    } else {
      Segment.trackUserGot('SubscriptionSubmitFailedOnValidation', { validations: state.creditCard });
      return false;
    }
  },
  async toggleEnabledTerms({ state, dispatch, commit }) {
    await commit('setEnableTerms', !state.enableTerms);
  },

  async getOrCreatePaymentToken({ state, dispatch }) {
    return state.paymeToken ? state.paymeToken : await dispatch('createPaymentToken');
  },
  async purchaseGiftcard({ state, dispatch, commit }, { email }) {
    await dispatch('setCreditCardValidations');
    if (state.creditCardIsValid) {
      Segment.trackUserGot('Giftcard_SucceededOnValidation');
      try {
        commit('setLoadingToken', true);
        const paymeToken = await dispatch('getOrCreatePaymentToken');
        commit('setPaymeToken', paymeToken);
        await BillyApi.purchaseGiftcard(paymeToken.token, email);
        Segment.trackUserGot('Giftcard_Succeeded');
        return true;
      } catch (error) {
        DDLogs.errorBusinessEvent('Purchase giftcard failed', { error });
        commit('setTokenizationError', error);
        return false;
      } finally {
        commit('setLoadingToken', false);
      }
    } else {
      Segment.trackUserGot('Giftcard_FailedOnValidation', { validations: state.creditCard });
      return false;
    }
  },
  async updateCreditCard({ state, dispatch, commit }) {
    await dispatch('setCreditCardValidations');
    await dispatch('fetchSubscription');
    if (state.creditCardIsValid) {
      Segment.trackUserGot('UpdateCreditCardSucceededOnValidation');
      try {
        commit('setLoadingToken', true);
        const { token, card } = await dispatch('createPaymentToken');
        Segment.trackUserGot('UpdatingCreditCard');
        await BillyApi.updateSubscriptionV2({
          paymentToken: token, card, iterationType: state.type,
        });
        MetricsApi.sendCounter('subscription.success', { type: 'update' });
        Segment.trackUserGot('UpdatedCreditCardSucceeded');
      } catch (error) {
        _handlePaymentError(error, true);
        commit('setTokenizationError', error);
      } finally {
        commit('setLoadingToken', false);
      }
    } else {
      Segment.trackUserGot('UpdatedCreditCardFailedOnValidation', { validations: state.creditCard });
    }
  },
  async createPaymentToken({ state, rootState }) {
    const { customerId } = rootState.session;
    const sale = {
      payerFirstName: state.personalInfo[PERSONAL_INFO_FIELDS.firstName].value,
      payerLastName: state.personalInfo[PERSONAL_INFO_FIELDS.lastName].value,
      payerPhone: state.personalInfo[PERSONAL_INFO_FIELDS.phoneNumber].value.replace('-', ''),
      payerEmail: `${customerId}@riseup.co.il`,
      total: {
        label: 'תשלום לרייזאפ',
        amount: {
          currency: 'ILS',
          value: '45.00',
        },
      },
    };
    Segment.trackUserGot('SubscriptionTokenize');
    const tokenRequest = await state.fieldsInstance.tokenize(sale);
    Segment.trackUserGot('SubscriptionTokenizeSucceeded');
    const { token, card } = tokenRequest;
    return { token, card };
  },
  reset({ commit }) {
    commit('setTokenizationError', null);
  },
  async fetchPricing({ commit }) {
    const { pricing } = await BillyApi.fetchPricingOption();
    commit('setPricing', pricing);
  },
  async updateSubscriptionV2({ commit }) {
    try {
      await BillyApi.updateSubscriptionV2({ iterationType: state.type });
      commit('setStep', SUBSCRIPTION_STEPS.done);
      Segment.trackUserGot('updateSubscriptionSucceeded', { subscriptionVersion: 'v2' });
    } catch (error) {
      DDLogs.error('updateSubscriptionV2 error', { error });
    }
  },

  async fetchSubscription({ commit }) {
    try {
      const { subscription } = await BillyApi.getSubscriptionV2();
      commit('setNextPaymentDate', subscription.nextPaymentDate);
      commit('setType', subscription.iterationType);
      commit('setPrice', subscription.price);
      commit('setRemainingIterations', subscription.remainingIterations);
      commit('seOfferingData', subscription.offeringData);
    } catch (error) {
      commit('setNextPaymentDate', undefined);
    }
  },
};

const mutations = {
  setCardNumberMask(state, cardNumberMask) {
    state.cardNumberMask = cardNumberMask;
  },
  setStep(state, flowStep) {
    state.flowStep = flowStep;
  },
  setPaymeToken(state, paymeToken) {
    state.paymeToken = paymeToken;
  },
  setDidEnteredCcFields(state, didEnteredCcFields) {
    state.didEnteredCcFields = didEnteredCcFields;
  },
  setFieldsInstance(state, { fieldsInstance, fields }) {
    state.fieldsInstance = fieldsInstance;
    state.fields = fields;
  },
  setFirstBillingDate(state, { firstBillingDate }) {
    // eslint-disable-next-line no-param-reassign
    state.firstBillingDate = firstBillingDate;
  },
  setPersonalInfoField(state, { field, value, isValid }) {
    Vue.set(state.personalInfo, field, { value, isValid });
  },
  setCreditCardField(state, { field, isEmpty, isValid, error }) {
    Vue.set(state.creditCard, field, { isEmpty, isValid, error });
  },
  setTokenizationError(state, error) {
    state.tokenizationError = error;
  },
  setEligibilityError(state, error) {
    state.eligibilityError = error;
  },
  setPersonalInfoValidationError(state, { field, error }) {
    Vue.set(state.personalInfo, field, _.extend({}, { error }, state.personalInfo[field]));
  },
  setCreditCardValidationError(state, { field, error }) {
    Vue.set(state.creditCard, field, _.extend({}, state.creditCard[field], { error }));
  },
  setCreditCardIsValid(state, isValid) {
    state.creditCardIsValid = isValid;
  },
  setLoadingToken(state, loading) {
    state.loadingToken = loading;
  },
  setLoadingBenefit(state, loading) {
    state.loadingBenefit = loading;
  },
  setType(state, type) {
    state.type = type;
  },
  setPrice(state, price) {
    state.price = price;
  },
  setPricing(state, pricing) {
    state.pricing = pricing;
  },
  setEnableTerms(state, enableTerms) {
    state.enableTerms = enableTerms;
  },
  setRemainingIterations(state, remainingIterations) {
    state.remainingIterations = remainingIterations;
  },
  setNextPaymentDate(state, nextPaymentDate) {
    state.nextPaymentDate = nextPaymentDate;
  },
  seOfferingData(state, offeringData) {
    state.offeringData = offeringData;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
