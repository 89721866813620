import BenefitsApi from '@/api/BenefitsApi';
import PromotionsApi from '@/api/PromotionsApi';
import _ from 'lodash';
import Segment from '@/Segment';
import promotions from '@/constants/promotions';

const state = {
  benefitId: null,
  benefitType: null,
  benefitAmount: null,
  benefitPromotion: null,
  benefitPercentage: null,
  registeredUsingSpecialPromotion: false,
  redeemed: false,
};

const getters = {
  BENEFIT_TYPES: state => promotions.BENEFIT_TYPES,
  BENEFIT_CREATION_STATUS: state => promotions.BENEFIT_CREATION_STATUS,
  discountedPrice: state => (state.benefitPercentage
    ? promotions.FULL_MONTH_PRICE - (promotions.FULL_MONTH_PRICE * (state.benefitPercentage / 100))
    : promotions.FULL_MONTH_PRICE),
  isHundredDaysPromotion: state => promotions.PROMOTION_NAMES.HUNDRED_DAYS_PROMOTION.test(state.benefitPromotion),
  isPercentageDiscountBenefit: state => state.benefitType === promotions.BENEFIT_TYPES.PERCENTAGE_DISCOUNT,
  monthsText: state => {
    switch (state.benefitAmount) {
    case 1:
      return 'חודש';
    case 2:
      return 'חודשיים';
    default:
      return `${state.benefitAmount} חודשים`;
    }
  },
};

const actions = {
  async initBenefits({ commit }) {
    const [benefit, registeredUsingSpecialPromotion] = await Promise.all([BenefitsApi.getActiveBenefit(),
      PromotionsApi.isRegisteredUsingSource(PromotionsApi.SPECIAL_PROMOTION_1)]);
    if (!_.isEmpty(benefit)) {
      commit('setBenefitId', benefit._id);
      commit('setBenefitType', benefit.benefitType);
      commit('setBenefitAmount', benefit.benefitAmount);
      commit('setBenefitPercentage', benefit.benefitPercentage);
      commit('setBenefitPromotion', benefit.promotion);
      commit('setBenefitRedeemed', benefit.redeemed);
    }
    if (registeredUsingSpecialPromotion) {
      commit('setIsRegisteredUsingSpecialPromotion', registeredUsingSpecialPromotion);
    }
  },
  async redeemBenefit({ state }) {
    await BenefitsApi.redeemBenefit(state.benefitId);
    Segment.trackUserGot('BenefitRedeemed', { benefitType: state.benefitType, benefitAmount: state.benefitAmount });
  },
  async createBenefit({ dispatch }, promoCode) {
    await BenefitsApi.createBenefitByPromoCode(promoCode);
    await dispatch('initBenefits');
  },
  async createBenefitByPromoSource({ dispatch }, { promoSource, pending }) {
    const { result } = await BenefitsApi.createBenefitByPromoSource(promoSource, pending);
    await dispatch('initBenefits');
    return result;
  },
  async isBenefitInSource({ commit, state }, sourceName) {
    const { isBenefitInSource } = await BenefitsApi.isBenefitInSource(sourceName, state.benefitPromotion);
    return isBenefitInSource;
  },
};

const mutations = {
  setBenefitId(state, benefitId) {
    state.benefitId = benefitId;
  },
  setBenefitType(state, benefitType) {
    state.benefitType = benefitType;
  },
  setBenefitAmount(state, benefitAmount) {
    state.benefitAmount = benefitAmount;
  },
  setBenefitPromotion(state, benefitPromotion) {
    state.benefitPromotion = benefitPromotion;
  },
  setBenefitPercentage(state, benefitPercentage) {
    state.benefitPercentage = benefitPercentage;
  },
  setIsRegisteredUsingSpecialPromotion(state, registeredUsingSpecialPromotion) {
    state.registeredUsingSpecialPromotion = registeredUsingSpecialPromotion;
  },
  setBenefitRedeemed(state, redeemed) {
    state.redeemed = redeemed;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
