import Vue from 'vue';
import _ from 'lodash';
import moment from 'moment';
import reactiveCashflow from '@riseupil/reactive-cashflow';
import TrackingCategoriesApi from '@/api/TrackingCategoriesApi';
import BaseUI from '@riseupil/base-ui';
import { DateLocales } from '@riseupil/common-utils';
import CashflowUpdaterApi from '../../api/CashflowUpdaterApi';
import cashflowViewConsts from '../../constants/cashflow-view';

const DEFAULT_TRACKING_CATEGORIES = [
  {
    label: 'סופר',
    icon: 'shopping_cart',
    color: BaseUI.IconSquareColorVariants.BLUE,
    iconName: 'super.png',
    style: 'primary',
    value: 'כלכלה',
    frequency: 'weekly',
    id: 'v2-default-il-expense-category-2',
  },
  {
    label: 'אוכל בחוץ',
    icon: 'local_pizza',
    color: BaseUI.IconSquareColorVariants.BLUE,
    iconName: 'food.png',
    style: 'primary',
    value: 'אוכל בחוץ',
    frequency: 'monthly',
    id: 'v2-default-il-expense-category-9',
  },
  {
    label: 'תחבורה ציבורית',
    icon: 'directions_bus',
    color: BaseUI.IconSquareColorVariants.BLUE,
    iconName: 'public-transportation.png',
    style: 'primary',
    value: 'תחבורה ציבורית',
    frequency: 'monthly',
    id: 'v2-default-il-expense-category-14',
  },
  {
    label: 'פארמה',
    icon: 'medication',
    color: BaseUI.IconSquareColorVariants.BLUE,
    iconName: 'pharma.png',
    style: 'primary',
    value: 'פארמה',
    frequency: 'monthly',
    id: 'v2-default-il-expense-category-33',
  },
  {
    label: 'רכב',
    icon: 'directions_car',
    color: BaseUI.IconSquareColorVariants.BLUE,
    iconName: 'car.png',
    style: 'primary',
    value: 'רכב',
    frequency: 'monthly',
    id: 'v2-default-il-expense-category-13',
  },
  {
    label: 'תרומה',
    icon: 'volunteer_activism',
    color: BaseUI.IconSquareColorVariants.BLUE,
    iconName: 'donation.png',
    style: 'primary',
    value: 'תרומה',
    frequency: 'monthly',
    id: 'v2-default-il-expense-category-10',
  },
  {
    label: 'תיירות',
    icon: 'attractions',
    color: BaseUI.IconSquareColorVariants.BLUE,
    iconName: 'tourism.png',
    style: 'primary',
    value: 'תיירות',
    frequency: 'monthly',
    id: 'v2-default-il-expense-category-15',
  },
  {
    label: 'פנאי',
    icon: 'sports_esports',
    color: BaseUI.IconSquareColorVariants.BLUE,
    iconName: 'leisure.png',
    style: 'primary',
    value: 'פנאי',
    frequency: 'monthly',
    id: 'v2-default-il-expense-category-3',
  },
  {
    label: 'ביגוד והנעלה',
    icon: 'checkroom',
    color: BaseUI.IconSquareColorVariants.BLUE,
    iconName: 'clothing.png',
    style: 'primary',
    value: 'ביגוד והנעלה',
    frequency: 'monthly',
    id: 'v2-default-il-expense-category-28',
  },
  {
    label: 'חיות מחמד',
    icon: 'pets',
    color: BaseUI.IconSquareColorVariants.BLUE,
    iconName: 'pets.png',
    style: 'primary',
    value: 'חיות מחמד',
    frequency: 'monthly',
    id: 'v2-default-il-expense-category-5',
  },
];
const CUSTOM_CATEGORY_LABEL = 'אחר';
const OTHER_TRACKING_CATEGORY = {
  label: CUSTOM_CATEGORY_LABEL,
  icon: 'receipt_long',
  color: BaseUI.IconSquareColorVariants.BLUE,
  iconName: 'other.png',
  style: 'primary',
  value: CUSTOM_CATEGORY_LABEL,
  frequency: 'monthly',
};
const TRACKING_CATEGORY_ENVELOPE_TYPE = 'trackingCategory';

const state = {
  selectedTrackingCategory: null,
  amount: null,
  isCustomTrackingCategory: false,
  availableTransactions: [],
};

const getters = {
  displayedTrackingCategoryNames: (state, getters, rootState, rootGetters) => {
    const presentedBudget = rootGetters['cashflowView/presentedBudget'];
    if (!presentedBudget) {
      return [];
    }
    return _.uniq(_getTrackingCategoryNames(presentedBudget));
  },
  displayedTrackingCategoryIds: (state, getters, rootState, rootGetters) => {
    const presentedBudget = rootGetters['cashflowView/presentedBudget'];
    if (!presentedBudget) {
      return [];
    }
    return _.uniq(_getTrackingCategoryIds(presentedBudget));
  },
  currentDefaultTrackingCategories: (state, getters) => {
    const currentTrackingCategories = _.filter(getters.displayedTrackingCategoryNames, c => c !== 'כלכלה');
    return _.filter(
      DEFAULT_TRACKING_CATEGORIES,
      category => _.includes(currentTrackingCategories, category.value),
    );
  },
  defaultCategories: () => [...DEFAULT_TRACKING_CATEGORIES],
  enrichedDefaultCategoriesWithAvgInfo: (state, getters, rootState, rootGetters) => {
    const presentedBudget = rootGetters['cashflowView/presentedBudget'];
    const { trackingCategoryMetadata } = presentedBudget;
    return _.chain(getters.availableTrackingCategories)
      .map(c => {
        const { historyAverage, basedOnHistoryAverage } = _.find(trackingCategoryMetadata, t => t.name === c.value) || {};
        return {
          historyAverage,
          basedOnHistoryAverage,
          ...c,
        };
      })
      .orderBy(c => c.historyAverage, 'desc')
      .value();
  },
  availableTrackingCategories: (state, getters) => _.concat(_.reject(
    DEFAULT_TRACKING_CATEGORIES,
    defaultCategory => (
      getters.displayedTrackingCategoryNames.includes(defaultCategory.value)
      || getters.displayedTrackingCategoryIds.includes(defaultCategory.id)
    ),
  ), OTHER_TRACKING_CATEGORY),
  customCategoryLabel: () => CUSTOM_CATEGORY_LABEL,
  transactionsToTrack: state => _.filter(state.availableTransactions, t => t.isChecked),
  transactionsToTrackAmount: state => _.chain(state.availableTransactions).filter(t => t.isChecked).sumBy(t => t.amount).value(),
  avgCategoryActualsAmount: (state, getters, rootState) => {
    const selectedCategory = state.selectedTrackingCategory;
    const { allCashflows } = rootState.cashflowView;
    return _.chain(allCashflows)
      .filter(b => moment(b.budgetDate).isBetween(moment().subtract(7, 'month'), moment().subtract(1, 'month')))
      .flatMap(b => b.envelopes)
      .filter(c => c.type === cashflowViewConsts.cashflowTypes.trackingCategory && c.details.trackingCategory.name === selectedCategory.label)
      .meanBy(c => _.chain(c.actuals)
        .flatMap(t => t.billingAmount)
        .sum()
        .value())
      .value();
  },
  currentCategoryActuals: (state, getters, rootState, rootGetters) => {
    const presentedBudget = rootGetters['cashflowView/presentedBudget'];
    const selectedCategory = state.selectedTrackingCategory;
    return _.chain(presentedBudget.envelopes)
      .filter(c => c.type === cashflowViewConsts.cashflowTypes.trackingCategory && c.details.trackingCategory.name === selectedCategory.label)
      .flatMap(c => c.actuals)
      .flatMap(t => t.billingAmount)
      .sum()
      .value();
  },
};

const actions = {
  async selectTrackingCategory({ commit }, trackingCategory) {
    commit('selectTrackingCategory', trackingCategory);
  },
  async setAmount({ commit }, amount) {
    commit('setAmount', amount);
  },
  async reset({ commit }) {
    commit('setAmount', null);
    commit('selectTrackingCategory', null);
    commit('setCustomTrackingCategory', false);
    commit('setAvailableTransactions', []);
  },
  async showTrackingCategory({ dispatch, state }) {
    const trackingCategoryId = await CashflowUpdaterApi.showTrackingCategory({
      name: state.selectedTrackingCategory.value,
      icon: state.selectedTrackingCategory.icon,
      amount: state.amount,
      frequency: state.selectedTrackingCategory.frequency,
    });
    const newTrackingCategory = {
      amount: state.amount,
      icon: state.selectedTrackingCategory.icon,
      name: state.selectedTrackingCategory.value,
      frequency: state.selectedTrackingCategory.frequency,
      categoryId: trackingCategoryId,
      trackingCategoryId,
      cashflowCategory: cashflowViewConsts.CASHFLOW_CATEGORIES.TRACKING,
      isCustomPrediction: true,
    };
    dispatch('addTrackingCategoryOptimistic', { newTrackingCategory });
    return newTrackingCategory;
  },
  async setCategoryAsShown({ dispatch }, { newTrackingCategory }) {
    await TrackingCategoriesApi.setCategoryAsShown(newTrackingCategory.trackingCategoryId);
    await dispatch('addTrackingCategoryOptimistic', { newTrackingCategory });
  },
  async addTrackingCategoryOptimistic({ commit, dispatch, state, rootGetters }, { newTrackingCategory }) {
    const currentCashflow = rootGetters['cashflowView/presentedBudget'];
    const newCashflow = reactiveCashflow.optimus.showTrackingCategory(currentCashflow, newTrackingCategory, DateLocales.EN_IL);
    await commit('cashflowView/setCashflowOptimistic', newCashflow, { root: true });
    await dispatch('cashflowView/resetBudgetTree', {}, { root: true });
  },
  async trackingCategoriesSelectionDone() {
    await TrackingCategoriesApi.setTrackingCategoriesSelectionAsDone();
  },
  setAvailableTransactions({ commit }, budget) {
    const availableTransactions = _.chain(budget.envelopes)
      .filter(e => e.type === 'variable')
      .map(e => e.actuals)
      .flatten()
      .map(t => {
        const cashflowCategory = t.trackingCategory
          ? cashflowViewConsts.CASHFLOW_CATEGORIES.TRACKING : cashflowViewConsts.CASHFLOW_CATEGORIES.VARIABLE;
        return {
          businessName: t.businessName,
          amount: t.billingAmount,
          transactionId: t.transactionId,
          transactionDate: t.transactionDate,
          isChecked: false,
          trackingCategory: { ...t.trackingCategory, cashflowCategory },
        };
      })
      .orderBy(t => t.trackingCategory && t.trackingCategory.name)
      .value();
    commit('setAvailableTransactions', availableTransactions);
  },
  checkTransaction({ commit }, checkedTransaction) {
    commit('checkTransaction', checkedTransaction);
  },
};

// mutations
const mutations = {
  selectTrackingCategory(state, trackingCategory) {
    state.selectedTrackingCategory = trackingCategory; // eslint-disable-line
  },
  setAmount(state, amount) {
    state.amount = amount; // eslint-disable-line
  },
  setCustomTrackingCategory(state, isCustomTrackingCategory) {
    state.isCustomTrackingCategory = isCustomTrackingCategory;
  },
  setAvailableTransactions(state, availableTransactions) {
    state.availableTransactions = availableTransactions;
  },
  checkTransaction(state, checkedTransaction) {
    const transaction = _.find(state.availableTransactions, t => t.transactionId === checkedTransaction.transactionId);
    Vue.set(transaction, 'isChecked', !transaction.isChecked);
  },
};

function _getTrackingCategoryNames(budget) {
  return _getTrackingCategories(budget).map(e => e.details.trackingCategory.name);
}

function _getTrackingCategoryIds(budget) {
  return _getTrackingCategories(budget).map(e => e.details.trackingCategory._id);
}

function _getTrackingCategories(budget) {
  return budget.envelopes.filter(e => e.type === TRACKING_CATEGORY_ENVELOPE_TYPE);
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
