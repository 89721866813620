import { render, staticRenderFns } from "./SetCategoryChallenge.vue?vue&type=template&id=13783046&scoped=true&"
import script from "./SetCategoryChallenge.vue?vue&type=script&lang=js&"
export * from "./SetCategoryChallenge.vue?vue&type=script&lang=js&"
import style0 from "./SetCategoryChallenge.vue?vue&type=style&index=0&id=13783046&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13783046",
  null
  
)

export default component.exports