<template>
  <colored-popup :class="'light-blue'" :back-action="onBack" :dark-text="true">
    <template v-slot:top-content>
      <div class="centered">
        <img class="balance-money-logos" src="/images/saving-zone/balance-money/riseup_plus_paybox.svg"/>
        <div class="ri-medium-bold-headline margin-top-l">PayBox</div>
      </div>
    </template>

    <template v-slot:bottom-content>
      <div class="text flex-column margin-bottom-m ri-large-body">
        <div>
          כדי לפשט את התהליך יצרנו <span class="bold">שיתוף פעולה עם חברת PayBox</span>.
          שירות ה־Box מאפשר לשים כסף בצד בקלות למטרה ספציפית, להרוויח ריבית והכסף נזיל תמיד.
        </div>
        <div class="margin-top-m ri-bold-label headline">
          מה צריך לעשות?
        </div>
        <div class="margin-top-m">
          <bullet-block class="margin-bottom-m"
                        text=" לפתוח Box שמתאים לחיסכון שלך."
                        :bullet-text="1"/>
          <bullet-block class="margin-bottom-m"
                        text="להצטרף לכרטיס האשראי הדיגיטלי של פייבוקס (ללא דמי כרטיס לכל החיים) כדי להטעין כסף ל־Box."
                        :bullet-text="2"/>
          <bullet-block class="margin-bottom-m"
                        text="להנות מריבית שנתית של 3% (עד ₪20,000)."
                        :bullet-text="3"/>
          <bullet-block class="margin-bottom-m"
                        text="לצרף את כרטיס האשראי החדש לרייזאפ."
                        :bullet-text="4"/>
          <bullet-block class="margin-bottom-m"
                        text="כשצריך את הכסף, אפשר למשוך אותו חזרה לחשבון או לשלם ישירות מה־Box."
                        :bullet-text="5"/>
        </div>
        <div class="margin-top-m margin-bottom-m">
          רייזאפ לא מרוויחה מפתיחת החיסכון בפייבוקס. אנחנו שמחים ליצור עוד ועוד שיתופי פעולה שמשרתים את הצמיחה הכלכלית של הלקוחות שלנו.
        </div>

      </div>
      <riseup-button
        class="margin-bottom-medium"
        title="להתחיל לחסוך ב־PayBox"
        :action="openPaybox"
        size="slim"
        bold
      />
    </template>
  </colored-popup>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import BulletBlock from '../../cashflow-view/current-month/components/challenge-flow/BulletBlock';

export default {
  name: 'PakamStepInfo',
  components: {
    BulletBlock,
    ColoredPopup: BaseUI.ColoredPopup,
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    onBack: {
      type: Function,
      required: true,
    },
    onDismiss: {
      type: Function,
      required: true,
    },
    stepName: {
      type: String,
      required: true,
    },
  },
  created() {
    Segment.trackUserInteraction('BalanceMoneyFlow_StepEntered', { step: this.stepName });
  },
  methods: {
    openPaybox() {
      Segment.trackUserInteraction('BalanceMoneyFlow_ClickedOpenPayBox');
      window.open('https://app.payboxapp.com/Wsya/x9qbq5g5', '_blank');
      this.onDismiss();
    },
    onDismissClicked() {
      this.onDismiss(false);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/personalized-savings/balance-money/balance-money-styles";

.text {
  text-align: start;
}

</style>
