import { render, staticRenderFns } from "./ChallengeFlow.vue?vue&type=template&id=533a4be3&scoped=true&"
import script from "./ChallengeFlow.vue?vue&type=script&lang=js&"
export * from "./ChallengeFlow.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "533a4be3",
  null
  
)

export default component.exports