/* eslint-disable max-len */
import auth0 from '@/utils/auth0';
import DDLogs from '@/DDLogs';
import { PerformanceMarks, performanceService } from '@/utils/PerformanceService';
import featureFlag from '@/api/featureFlag';
import store from '@/store';
import isDormantWinback from '@/utils/dormant-winback';
import Segment from '@/Segment';
import { getEligibleForPlanAhead } from '@/api/PlanAheadApi';
import AssetsAndLiabilities from '@/pages/responsive-pages/authenticated/navigation-pages/advisory/AssetsAndLiabilities';
import PurchaseOfferingFlow from '@/pages/responsive-pages/authenticated/offerings/PurchaseOfferingFlow.vue';
import MislakaNewConsentFlow from '@/pages/responsive-pages/authenticated/navigation-pages/advisory/MislakaNewConsentFlow';
import AdvisoryPaymentAuthentication from '@/pages/responsive-pages/authenticated/navigation-pages/advisory/AdvisoryPaymentAuthentication';
import AdvisoryFlow from '@/pages/responsive-pages/authenticated/navigation-pages/advisory/AdvisoryFlow.vue';
import AdvisoryFlowExternal from '@/pages/responsive-pages/authenticated/navigation-pages/advisory/AdvisoryFlowExternal.vue';
import AdvisoryPackageFlow from '@/pages/responsive-pages/authenticated/navigation-pages/advisory/advisory-package/AdvisoryPackageFlow.vue';
import { addCashflowBudgetDateToParams } from '@/router/budget-date-param-helper';
import { getNextRouteBySubscriptionState } from '@/router/purchase';

const StateRouter = () => import(/* webpackChunkName: "authenticated-base" */ '../pages/responsive-pages/authenticated/routing/StateRouter');
const AppHeader = () => import(/* webpackChunkName: "authenticated-base" */ '../base-components/app-header/AppHeader');
const PopupsManager = () => import(/* webpackChunkName: "authenticated-base" */ '../base-components/app-header/PopupsManager');
const CashflowApp = () => import('../pages/responsive-pages/authenticated/navigation-pages/cashflow-app/CashflowApp');
const BalancePage = () => import('../pages/responsive-pages/authenticated/navigation-pages/balance/BalancePage');
const CredentialsSettings = () => import('../pages/responsive-pages/authenticated/navigation-pages/credentials-settings/CredentialsSettings');
const Settings = () => import('../pages/responsive-pages/authenticated/navigation-pages/settings/Settings');
const ExperimentalPages = () => import('../pages/responsive-pages/authenticated/navigation-pages/experimental-pages/ExperimentalPages');
const PersonalInformation = () => import('../pages/responsive-pages/authenticated/navigation-pages/settings/PersonalInformation');
const TransactionStories = () => import('../pages/responsive-pages/authenticated/navigation-pages/transaction-stories/TransactionStories');
const AccountSettings = () => import('../pages/responsive-pages/authenticated/navigation-pages/account-settings/AccountSettings');
const CashflowFlow = () => import('../pages/responsive-pages/authenticated/navigation-pages/experimental-pages/CashflowFlow');
const CashflowAssistant = () => import('../pages/responsive-pages/authenticated/navigation-pages/experimental-pages/CashflowAssistant');
const RiseupPool = () => import('../pages/responsive-pages/authenticated/navigation-pages/experimental-pages/RiseupPool/RiseupPool');
const TCSettings = () => import('../pages/responsive-pages/authenticated/navigation-pages/experimental-pages/TrackingCategoriesSettings/TCSettings');
const AnonymizeCashflow = () => import('../pages/responsive-pages/authenticated/navigation-pages/experimental-pages/AnonymizeCashflow/AnonymizeCashflow');
const EnableMobile = () => import('../pages/responsive-pages/authenticated/navigation-pages/experimental-pages/EnableMobile/EnableMobile');
const GetExcel = () => import('../pages/responsive-pages/authenticated/navigation-pages/experimental-pages/GetExcel');
const MoneyMoonJoinPage = () => import('../pages/responsive-pages/authenticated/navigation-pages/money-moon/MoneyMoonJoinPage');
const RiseupGiftcardFlow = () => import('../pages/responsive-pages/authenticated/navigation-pages/riseup-giftcard/RiseupGiftcardFlow');
const HeverBenefitPage = () => import('../pages/responsive-pages/authenticated/navigation-pages/hever/HeverBenefitPage');
const SuperDiscountPage = () => import('../pages/responsive-pages/authenticated/navigation-pages/super-discount/SuperDiscountPage');
const CashflowTourFlow = () => import('../pages/responsive-pages/authenticated/navigation-pages/cashflow-tour/CashflowTourFlow');
const MonthReviewFlowWrapper = () => import('../pages/responsive-pages/authenticated/navigation-pages/month-review/MonthReviewFlowWrapper');
const AmbassadorsRouter = () => import('../pages/responsive-pages/authenticated/navigation-pages/ambassadors/AmbassadorsRouter');
const AmbassadorsPage = () => import('../pages/responsive-pages/authenticated/navigation-pages/ambassadors/AmbassadorsPage');
const HeverBenefitRedeemed = () => import('../pages/responsive-pages/authenticated/navigation-pages/ambassadors/HeverBenefitRedeemed');
const GenerateBenefitFlow = () => import('../pages/responsive-pages/authenticated/GenerateBenefitFlow');
const StorySelector = () => import('../pages/responsive-pages/authenticated/navigation-pages/mid-month/StorySelector');
const TelecomStory = () => import('../pages/responsive-pages/authenticated/navigation-pages/mid-month/TelecomStory');
const SuspiciousGroupJoinInvestigator = () => import('../pages/responsive-pages/authenticated/suspicious-group-join/SuspiciousGroupJoinInvestigator');
const InviteMember = () => import('../pages/responsive-pages/authenticated/InviteMember');
const UnsupportedBanksMessagePage = () => import('../pages/responsive-pages/authenticated/unsupported-banks/UnsupportedBanksMessagePage');
const SubscriptionFlowV2 = () => import('../pages/responsive-pages/authenticated/subscription/V2/SubscriptionFlowV2.vue');
const Survey = () => import('../pages/responsive-pages/authenticated/survey/Survey');
const ActivationSurvey = () => import('../pages/responsive-pages/authenticated/survey/activation-survey/ActivationSurvey');
const CSATSurvey = () => import('../pages/responsive-pages/authenticated/survey/csat-survey/CSATSurvey');
const NPSSurveyFlow = () => import('../pages/responsive-pages/authenticated/survey/NPS/NPSSurveyFlow');
const OnboardFlow = () => import('../pages/responsive-pages/authenticated/onboard2/OnboardFlow.vue');
const OpenBankingLandingPage = () => import('../pages/responsive-pages/non-authenticated/open-banking/OpenBankingLandingPage');
const LoadingOBKCredentials = () => import('../pages/responsive-pages/non-authenticated/open-banking/LoadingOBKCredentials');
const BalanceMoneyPage = () => import('../pages/responsive-pages/authenticated/navigation-pages/balance-money/BalanceMoneyPage');
const ResetCashflow = () => import('../pages/responsive-pages/authenticated/ResetCashflow.vue');
const DormantMBG = () => import('../pages/responsive-pages/authenticated/DormantMBG.vue');
const PlanAheadPage = () => import('../pages/responsive-pages/authenticated/navigation-pages/plan-ahead/PlanAheadPage');
const SavingZone = () => import('../pages/responsive-pages/authenticated/navigation-pages/saving-zone/SavingZone');
const SupermarketStory = () => import('../pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/SupermarketStory');
const SupermarketStoryTeaser = () => import('../pages/responsive-pages/authenticated/navigation-pages/mid-month/supermarket-story/SupermarketStoryTeaser');
const CashflowStartDaySettings = () => import('../pages/responsive-pages/authenticated/navigation-pages/settings/cashflow-start-day-settings/CashflowStartDaySettings');
const SaveUTMParamsAndRedirect = () => import('../pages/responsive-pages/authenticated/SaveUTMParamsAndRedirect.vue');
const AltshulerIntroduction = () => import('../pages/responsive-pages/authenticated/navigation-pages/altshuler/AltshulerIntroduction.vue');
const AltshulerSignup = () => import('../pages/responsive-pages/authenticated/navigation-pages/altshuler/AltshulerSignupFlow.vue');
const XnesIntroduction = () => import('../pages/responsive-pages/authenticated/navigation-pages/xnes/lightup/XnesIntroduction.vue');
const XnesJoinFlow = () => import('../pages/responsive-pages/authenticated/navigation-pages/xnes/join/XnesJoinFlow.vue');
const SubscriptionCancelled = () => import('../pages/responsive-pages/authenticated/churn/CancelSubscriptionSummary.vue');
const WhatsappAlertsSettings = () => import('../pages/responsive-pages/authenticated/navigation-pages/account-settings/WhatsappAlertsSettings.vue');
const SignupOfferingsFlow = () => import('../pages/responsive-pages/authenticated/signup-offerings/SignupOfferings.vue');
const OneOffOfferingFlow = () => import('../pages/responsive-pages/authenticated/navigation-pages/offerings/one-off/OneOffOfferingFlow.vue');
const OneOffPaymentFlow = () => import('../pages/responsive-pages/authenticated/navigation-pages/offerings/one-off/OneOffPaymentFlow.vue');
const SignupOffering = () => import('../pages/responsive-pages/authenticated/signup-offerings/SignupOffering.vue');
const SignupOfferingPurchase = () => import('../pages/responsive-pages/authenticated/signup-offerings/SignupOfferingPurchase.vue');
const SignupOfferingPurchaseSuccess = () => import('../pages/responsive-pages/authenticated/signup-offerings/SignupOfferingPurchaseSuccess.vue');
const MyProgressBalanceSummary = () => import('../pages/responsive-pages/authenticated/navigation-pages/my-progress/balance/MyProgressBalanceSummary.vue');
const MyProgressSavingsSummary = () => import('../pages/responsive-pages/authenticated/navigation-pages/my-progress/savings/MyProgressSavingsSummary.vue');
const MyProgressSummary = () => import('../pages/responsive-pages/authenticated/navigation-pages/my-progress/cover/MyProgressSummary.vue');
const CategoryProgressPage = () => import('../pages/responsive-pages/authenticated/navigation-pages/my-progress/categories/category-progress-page/CategoryProgressPage.vue');
const MyProgressNextStepsPage = () => import('../pages/responsive-pages/authenticated/navigation-pages/my-progress/next-steps/NextStepsPage.vue');
const MyProgressIncomePage = () => import('../pages/responsive-pages/authenticated/navigation-pages/my-progress/categories/income/MyProgressIncomePage.vue');
const MyProgressFixedPage = () => import('../pages/responsive-pages/authenticated/navigation-pages/my-progress/categories/fixed/MyProgressFixedPage.vue');
const MyProgressVariablesPage = () => import('../pages/responsive-pages/authenticated/navigation-pages/my-progress/categories/variables/MyProgressVariablesPage.vue');
const SignupOfferingsV2 = () => import('../pages/responsive-pages/authenticated/signup-offerings/V2/SignupOfferingsV2.vue');
const JoinChallangePage = () => import('../pages/responsive-pages/authenticated/JoinChallengePage.vue');
const DataCenter = () => import('../pages/responsive-pages/authenticated/data-center/DataCenter.vue');
export const HOME_PATH = 'home';
export const CASHFLOW_APP_PATH = 'cashflow-app';
export const TOUR_PATH = 'tour';
export const PLAN_AHEAD_PATH = 'plan-ahead';

// eslint-disable-next-line import/prefer-default-export
export const authenticatedRoutes = [
  {
    path: 'auth-redirect',
    name: 'SaveUTMParamsAndRedirect',
    component: SaveUTMParamsAndRedirect,
  },
  {
    path: 'decode-and-redirect/:encodedUrl',
    name: 'DecodeAndRedirect',
    async beforeEnter(to, from, next) {
      const { path, params } = auth0.decodeUrl(to.params.encodedUrl);
      DDLogs.log('Redirecting path', { redirectedFrom: to.path });
      next({ path, query: params });
    },
  },
  {
    path: 'reset-cashflow',
    name: 'ResetCashflow',
    component: ResetCashflow,
  },
  {
    path: 'subscription-cancelled',
    name: 'SubscriptionCancelled',
    component: SubscriptionCancelled,
  },
  {
    path: 'dormant-money-back-guarantee',
    name: 'DormantMBG',
    component: DormantMBG,
  },
  {
    path: '/',
    name: 'AppHeader',
    component: AppHeader,
    beforeEnter(_, __, next) {
      performanceService.markStart(`${PerformanceMarks.COMPONENT}_AppHeader`);
      next();
    },
    children: [
      {
        path: '/',
        name: 'PopupsManager',
        component: PopupsManager,
        props: route => {
          return { hideCta: Boolean(route.query.hideCta) };
        },
        async beforeEnter(to, from, next) {
          if (await isDormantWinback()) {
            next({
              path: 'reset-cashflow',
              query: to.query,
            });
          } else {
            next();
          }
        },
        children: [
          {
            path: `${HOME_PATH}/:budgetDate?`,
            name: 'Home',
            component: CashflowApp,
            beforeEnter(to, _, next) {
              return next(addCashflowBudgetDateToParams(HOME_PATH, to));
            },
          }, {
            path: `${CASHFLOW_APP_PATH}/:budgetDate?`,
            name: 'CashflowApp',
            component: CashflowApp,
            beforeEnter(to, _, next) {
              return next(addCashflowBudgetDateToParams(CASHFLOW_APP_PATH, to));
            },
          }, {
            path: 'balance',
            name: 'BalancePage',
            component: BalancePage,
          }, {
            path: 'credentials',
            name: 'CredentialsSettings',
            component: CredentialsSettings,
            meta: {
              routerBack: true,
            },
          }, {
            path: 'experimental-pages',
            name: 'ExperimentalPages',
            component: ExperimentalPages,
          }, {
            path: 'personal-info',
            name: 'PersonalInformation',
            component: PersonalInformation,
            meta: {
              routerBack: true,
            },
          }, {
            path: 'stories',
            name: 'TransactionStories',
            component: TransactionStories,
          },
          {
            path: 'cashflow-flow',
            name: 'CashflowFlow',
            component: CashflowFlow,
            meta: {
              routerBack: true,
            },
          },
          {
            path: 'cashflow-assistant',
            name: 'CashflowAssistant',
            component: CashflowAssistant,
            meta: {
              routerBack: true,
            },
          },
          {
            path: '/purchase-offerings',
            name: 'PurchaseOffering',
            component: PurchaseOfferingFlow,
          },
          {
            path: 'riseup-pool',
            name: 'RiseupPool',
            component: RiseupPool,
            meta: {
              routerBack: true,
            },
          },
          {
            path: 'reorder-categories',
            name: 'TCSettings',
            component: TCSettings,
          }, {
            path: 'anonymize-cashflow',
            name: 'AnonymizeCashflow',
            component: AnonymizeCashflow,
            meta: {
              routerBack: true,
            },
          },
          {
            path: 'enable-mobile',
            name: 'EnableMobile',
            component: EnableMobile,
            meta: {
              routerBack: true,
            },
          },
          {
            path: 'get-excel',
            name: 'GetExcel',
            component: GetExcel,
            meta: {
              routerBack: true,
            },
          },
          {
            path: 'balance-money',
            name: 'BalanceMoneyPage',
            component: BalanceMoneyPage,
            meta: {
              routerBack: true,
            },
          },
          {
            path: 'data-center',
            name: 'DataCenter',
            component: DataCenter,
            meta: {
              routerBack: true,
            },
          },
          {
            path: 'my-progress',
            name: 'MyProgress',
            component: MyProgressSummary,
            meta: {
              routerBack: true,
            },
          },
          {
            path: 'my-progress/balance',
            name: 'MyBalanceProgress',
            component: MyProgressBalanceSummary,
            meta: {
              routerBack: true,
            },
          },
          {
            path: 'my-progress/savings',
            name: 'MySavingsProgress',
            component: MyProgressSavingsSummary,
            meta: {
              routerBack: true,
            },
          },
          {
            path: 'my-progress/next-steps',
            name: 'MyProgressNextSteps',
            component: MyProgressNextStepsPage,
            meta: {
              routerBack: true,
            },
            async beforeEnter(_, __, next) {
              if ((await featureFlag.getTreatment('my-progress-next-steps')) === 'on') {
                next();
              } else {
                next({ path: '/sr' });
              }
            },
          },
          {
            path: 'my-progress/income',
            name: 'MyProgressIncomePage',
            component: MyProgressIncomePage,
            meta: {
              routerBack: true,
            },
          },
          {
            path: 'my-progress/fixed',
            name: 'MyProgressFixedPage',
            component: MyProgressFixedPage,
            meta: {
              routerBack: true,
            },
          },
          {
            path: 'my-progress/variables',
            name: 'MyProgressVariablesPage',
            component: MyProgressVariablesPage,
            meta: {
              routerBack: true,
            },
          },
          {
            path: 'my-progress/category/:categoryIdHash',
            name: 'CategoryProgressPage',
            component: CategoryProgressPage,
            meta: {
              routerBack: true,
            },
            async beforeEnter(_, __, next) {
              if ((await featureFlag.getTreatment('my-progress-category-trends')) === 'on') {
                next();
              } else {
                next({ path: '/sr' });
              }
            },
          },
          {
            path: '/invite-member',
            name: 'InviteMember',
            component: InviteMember,
            meta: {
              routerBack: true,
            },
          },
          {
            path: 'moneymoon_join',
            name: 'MoneyMoonJoin',
            component: MoneyMoonJoinPage,
          }, {
            path: 'riseup-giftcard',
            name: 'RiseupGiftcard',
            component: RiseupGiftcardFlow,
          }, {
            path: 'hever_benefit',
            name: 'HeverBenefitPage',
            component: HeverBenefitPage,
          }, {
            path: 'super-discount',
            name: 'SuperDiscount',
            component: SuperDiscountPage,
          },
          {
            path: 'cashflow-start-day-settings',
            name: 'CashflowStartDaySettings',
            component: CashflowStartDaySettings,
          },
        ],
      },
      {
        path: 'offerings',
        name: 'signupOfferings',
        component: SignupOfferingsFlow,
        async beforeEnter(to, _, next) {
          const nextRoute = await getNextRouteBySubscriptionState(to);
          next(nextRoute);
        },
      },
      {
        path: 'offerings-v2',
        name: 'signupOfferingsV2',
        component: SignupOfferingsV2,
        async beforeEnter(to, _, next) {
          const nextRoute = await getNextRouteBySubscriptionState(to);
          next(nextRoute);
        },
      },
      {
        path: 'offerings/:offerId',
        name: 'signupOffering',
        component: SignupOffering,
        async beforeEnter(to, _, next) {
          const nextRoute = await getNextRouteBySubscriptionState(to);
          next(nextRoute);
        },
        props: route => {
          return {
            offerId: route.params.offerId,
          };
        },
        meta: {
          routerBack: true,
        },
      },
      {
        path: 'offerings/:offerId/purchase',
        name: 'signupOfferingPurchase',
        component: SignupOfferingPurchase,
        props: route => {
          return {
            offerId: route.params.offerId,
          };
        },
        meta: {
          routerBack: true,
        },
      },
      {
        path: 'offerings/:offerId/purchase-success',
        name: 'signupOfferingPurchaseSuccess',
        component: SignupOfferingPurchaseSuccess,
        props: route => {
          return {
            offerId: route.params.offerId,
          };
        },
      },
      {
        path: TOUR_PATH,
        name: 'Tour',
        component: CashflowTourFlow,
        async beforeEnter(to, from, next) {
          if (await isDormantWinback()) {
            next({
              path: 'reset-cashflow',
              query: to.query,
            });
          } else {
            next();
          }
        },
      },
      {
        path: 'mr',
        name: 'MonthReview',
        component: MonthReviewFlowWrapper,
      },
      {
        path: '/generate-benefit',
        name: 'GenerateBenefitFlow',
        component: GenerateBenefitFlow,
        props: route => {
          const props = {};
          if (route.query.promoSource) {
            props.promoSource = route.query.promoSource;
          }
          return props;
        },
      },
      {
        path: 'settings',
        name: 'Settings',
        component: Settings,
      },
      {
        path: 'account-settings',
        name: 'AccountSettings',
        component: AccountSettings,
        meta: {
          routerBack: true,
        },
      },
      {
        path: 'whatsapp-alerts',
        name: 'WhatsappAlerts',
        component: WhatsappAlertsSettings,
        meta: {
          routerBack: true,
        },
        async beforeEnter(_, __, next) {
          if ((await featureFlag.getTreatment('whatsapp-alerts-settings')) === 'on') {
            next();
          } else {
            next({ path: '/sr' });
          }
        },
      },
      {
        path: PLAN_AHEAD_PATH,
        name: 'PlanAheadPage',
        component: PlanAheadPage,
        meta: {
          routerBack: true,
        },
        async beforeEnter(_, __, next) {
          if (await getEligibleForPlanAhead()) {
            next();
          } else {
            next({ path: '/sr' });
          }
        },
      },
      {
        path: 'saving-zone',
        name: 'SavingZone',
        component: SavingZone,
        meta: {
          routerBack: true,
        },
      },
      {
        path: '/advisory-flow-external',
        name: 'AdvisoryFlowExternal',
        component: AdvisoryFlowExternal,
      },
    ],
  },
  {
    path: 'join-challenge/:challengeId',
    name: 'JoinChallenge',
    component: JoinChallangePage,
    props: route => {
      return {
        challengeId: route.params.challengeId,
      };
    },
  },
  {
    path: 'ambassadors',
    name: 'Ambassadors',
    component: AmbassadorsRouter,
    async beforeEnter(to, from, next) {
      await store.dispatch('ambassadors/getLink');
      next();
    },
    children: [
      {
        path: '/',
        name: 'AmbassadorsPage',
        component: AmbassadorsPage,
        props: route => {
          return {
            showIdPopup: Boolean(route.query.shouldEnterId),
            showPaymentPopup: Boolean(route.query.showPaymentForm),
          };
        },
      }, {
        path: 'partners',
        name: 'HeverBenefitRedeemed',
        component: HeverBenefitRedeemed,
      },
    ],
  },
  {
    path: 'altshuler',
    name: 'AltshulerIntroduction',
    component: AltshulerIntroduction,
    async beforeEnter(_, __, next) {
      if ((await featureFlag.getTreatment('altshuler-account')) === 'on') {
        next();
      } else {
        next({ path: '/sr' });
      }
    },
  },
  {
    path: 'xnes',
    name: 'XnesIntroduction',
    component: XnesIntroduction,
    // async beforeEnter(_, __, next) {
    //   if ((await featureFlag.getTreatment('altshuler-account')) === 'on') {
    //     next();
    //   } else {
    //     next({ path: '/sr' });
    //   }
    // },
  },
  {
    path: '/st/:storyName',
    name: 'StorySelector',
    component: StorySelector,
    props: route => {
      return {
        storyName: route.params.storyName,
      };
    },
    meta: {
      routerBack: true,
    },
  }, {
    path: 'telecom-story',
    name: 'TelecomStory',
    component: TelecomStory,
    async beforeEnter(to, from, next) {
      Segment.trackUserInteraction('TelecomStoryExpiredEntered');
      next({ path: '/sr' });
    },
  }, {
    path: 'supermarket-story',
    name: 'SupermarketStory',
    component: SupermarketStory,
    async beforeEnter(to, from, next) {
      if (await featureFlag.getTreatment('elsa-supermarket-story') === 'on') {
        next();
      } else {
        next({ path: '/sr' });
      }
    },
  },
  {
    path: 'supermarket-story-teaser',
    name: 'SupermarketStoryTeaser',
    component: SupermarketStoryTeaser,
  },
  {
    path: 'ybitan',
    beforeEnter() {
      window.location.href = 'https://intercom.riseup.co.il/he/articles/6527821-לקנות-ב-יינות-ביתן-בהנחה-דרך-רייזאפ';
    },
  },
  {
    path: 'sr',
    name: 'StateRouter',
    component: StateRouter,
  }, {
    path: 'group-join', // in app
    name: 'SuspiciousGroupJoinInvestigator',
    component: SuspiciousGroupJoinInvestigator,
  }, {
    path: 'unsupported-banks', // in app
    name: 'UnsupportedBanks',
    component: UnsupportedBanksMessagePage,
  }, {
    path: 'subscription', // out of app
    name: 'subscription',
    component: SubscriptionFlowV2,
    meta: {
      preventRouterBack: true,
    },
  },
  {
    path: '/subscription/update', // out of app
    name: 'subscriptionV2',
    component: SubscriptionFlowV2,
    props: () => {
      return { mode: 'update' };
    },
    meta: {
      preventRouterBack: true,
    },
  },
  {
    path: 'survey',
    name: 'Survey',
    component: Survey,
    children: [
      {
        path: 'first-cashflow', // in app
        name: 'First Cashflow Survey',
        component: ActivationSurvey,
      },
      {
        path: 'csat', // in app
        name: 'CSAT Survey',
        component: CSATSurvey,
      },
      {
        path: 'nps',
        name: 'NPS survey',
        component: NPSSurveyFlow,
      },
    ],
  },
  {
    path: '/ob',
    name: 'Onboard',
    component: OnboardFlow,
  },
  {
    path: '/advisory-onboarding',
    name: 'AdvisoryOnboarding',
    component: AdvisoryFlow,
    beforeEnter(to, from, next) {
      next({ path: 'advisory-plan', query: { ...to.query } });
    },
    meta: {
      routerBack: true,
    },
  },
  {
    path: '/advisory-package',
    name: 'advisory-package',
    component: AdvisoryPackageFlow,
  },
  {
    path: '/one-off/:offeringId',
    name: 'one-off',
    props: route => {
      return {
        offeringId: route.params.offeringId,
      };
    },
    async beforeEnter(to, _, next) {
      const nextRoute = await getNextRouteBySubscriptionState(to);
      next(nextRoute);
    },
    component: OneOffOfferingFlow,
  },
  {
    path: '/one-off/payment/:offeringId',
    name: 'one-off-payment',
    props: route => {
      return {
        offeringId: route.params.offeringId,
      };
    },
    async beforeEnter(to, __, next) {
      await store.dispatch('subscriptionState/fetchSubscriptionState');
      if (!store.getters['subscriptionState/subscribed'] && !store.getters['subscriptionState/isFree']) {
        next({
          path: '/offerings',
          query: { offerId: to.params.offeringId },
        });
      } else {
        next();
      }
    },
    component: OneOffPaymentFlow,
  },
  {
    path: '/altshuler-signup',
    name: 'AltshulerSignup',
    component: AltshulerSignup,
  },
  {
    path: '/xnes-join',
    name: 'XnesJoinFlow',
    component: XnesJoinFlow,
  },
  {
    path: '/:source/welcome-back',
    name: 'OpenBankingLandingPage',
    component: OpenBankingLandingPage,
    meta: {
      preventRouterBack: true,
    },
  },
  {
    path: 'loading-credentials',
    name: 'LoadingOBKCredentials',
    component: LoadingOBKCredentials,
    meta: {
      preventRouterBack: true,
    },
  },
  // This path is because auth0 returns only one query param so we send the state
  // as part of the url
  {
    path: '/:source/welcome-back/:consentId/:code',
    async beforeEnter(to, from, next) {
      const { consentId, source, code } = to.params;
      next({ path: `/${source}/welcome-back`, query: { ...to.query, state: consentId, code } });
    },
  },
  {
    path: 'anl',
    name: 'AssetsAndLiabilities',
    component: AssetsAndLiabilities,
  },
  {
    path: 'clearing-house',
    name: 'ClearingHouse',
    component: MislakaNewConsentFlow,
  },
  {
    path: 'mislaka-consent',
    name: 'MislakaConsent',
    component: MislakaNewConsentFlow,
  },
  {
    path: 'mislaka-auth',
    name: 'MislakaAuthentication',
    component: AdvisoryPaymentAuthentication,
  },
  {
    path: 'advisory-plan',
    name: 'AdvisoryPlanRouting',
    component: AdvisoryFlowExternal,
  },
  {
    path: '*',
    redirect: { name: 'AuthenticateToken' },
  },
];
