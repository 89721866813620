<template>
  <colored-popup :class="'light-blue'" :back-action="onBack" :dark-text="true">
    <template v-slot:top-content>
      <div class="centered">
        <img class="balance-money-logos" src="/images/saving-zone/balance-money/bank.svg"/>
        <div class="title margin-top-large">פקדון קצר מועד</div>
      </div>
    </template>

    <template v-slot:bottom-content>
      <div class="body-text margin-bottom-x-large">
        <div class="bold accent-text margin-bottom-large">אפשר לרכוש פק״מ דרך כל בנק, כזה שיש לך חשבון בו, או כזה שלא.</div>
        <div class="bold margin-bottom-small">מה כדאי לבדוק?</div>
        <bullet-block class="margin-bottom-medium" text-class-names="font-16" :title="'נזילות:'"
                      :text="'ככל שהפקדון סגור ליותר זמן, הריבית תהיה גבוהה יותר. כדאי להחליט לפי הצורך שלך בזמינות הכסף.'"
                      :bullet-text="1"/>
        <bullet-block class="margin-bottom-medium" :title="'סוג הריבית:'"
                      :text="'קבועה או משתנה? האם הריבית תלויה במדדים אחרים בשוק או שהיא קבועה לאורך כל תקופת הפיקדון.'"
                      :bullet-text="2"/>
        <bullet-block class="margin-bottom-medium" :title="'השוואת מחירים:'"
                      :text="'אפשר להתמקח על גובה הריבית, לקבל הצעות מכמה גורמים ולשפר את ההצעה!'"
                      :bullet-text="3"/>
      </div>
      <riseup-button
        class="margin-bottom-medium"
        title="הבנתי, תודה"
        :action="onDismissClicked"
        size="slim"
        :bold="true"
      />
    </template>
  </colored-popup>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import BulletBlock from '../../cashflow-view/current-month/components/challenge-flow/BulletBlock';

export default {
  name: 'PakamStepInfo',
  components: {
    BulletBlock,
    ColoredPopup: BaseUI.ColoredPopup,
    RiseupButton: BaseUI.RiseupButton,
  },
  props: {
    onBack: {
      type: Function,
      required: true,
    },
    onDismiss: {
      type: Function,
      required: true,
    },
    stepName: {
      type: String,
      required: true,
    },
  },
  created() {
    Segment.trackUserInteraction('BalanceMoneyFlow_StepEntered', { step: this.stepName });
  },
  methods: {
    onDismissClicked() {
      this.onDismiss(false);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/personalized-savings/balance-money/balance-money-styles";

</style>
