import * as _ from 'lodash';
import * as planAheadAPI from '@/api/PlanAheadApi';
import moment from 'moment';
import cashflowNormalizer from '@/store/utilities/cashflow-normalizer';

const state = {
  initializedPlans: false,
  eligibleForPlanAhead: null,
  plans: [],
  calculatedMonthlyAmount: null,
  currentPlan: {},
  showPlansInSection: false,
  suggestions: [],
};

const getters = {
  plans: state => state.plans,
  ongoingPlans: state => state.plans.filter(plan => plan.state === 'ongoing'),
  hasPlans: state => state.plans.length > 0,
  hasActivePlans: (state, getters) => getters.ongoingPlans.length > 0,
  totalPlansMonthlyAmount: (__, getters) => _.sumBy(getters.ongoingPlans, plan => plan.monthlyTarget) || 0,
  monthlyPlans: (state, getters, rootState) => state.plans.filter(plan => {
    const { presentedBudgetDate } = rootState.cashflowView;
    const createdBeforeCurrentMonth = moment(plan.created).isSameOrBefore(presentedBudgetDate, 'month');
    const deadlineAfterCurrentMonth = moment(plan.deadline).isAfter(presentedBudgetDate, 'month');
    return createdBeforeCurrentMonth && deadlineAfterCurrentMonth;
  }),
  plansForTransactionPlacement: state => {
    // return only plans that are active, or that their deadline is no later than 3 months ago
    const threeMonthsAgo = moment().subtract(3, 'months');
    return state.plans.filter(plan => {
      const deadlineAfterThreeMonthsAgo = moment(plan.deadline).isSameOrAfter(threeMonthsAgo, 'month');
      return plan.state === 'ongoing' || (plan.state === 'done' && deadlineAfterThreeMonthsAgo);
    });
  },
};

const actions = {
  async initEligibleForPlanAhead({ commit, state }, force = false) {
    if (state.eligibleForPlanAhead === null || force) {
      const eligibleForPlanAhead = await planAheadAPI.getEligibleForPlanAhead();
      commit('setEligibleForPlanAhead', eligibleForPlanAhead);
    }
  },
  async initPlans({ commit, state, dispatch }, force = false) {
    if (state.initializedPlans && !force) {
      return;
    }
    await dispatch('initEligibleForPlanAhead');
    if (state.eligibleForPlanAhead) {
      const plans = await planAheadAPI.getPlans();
      commit('setPlans', plans);
      dispatch('initRelatedTransactions');
    }
    commit('setInitialized', true);
  },
  async initPlansSuggestions({ commit }) {
    const suggestions = await planAheadAPI.getSuggestions();
    await commit('setSuggestions', suggestions);
  },
  async initRelatedTransactions({ dispatch, state }) {
    const relatedTransactions = state.plans.reduce((allTransactions, plan) => {
      plan.relatedTransactionIds.forEach(transaction => {
        allTransactions[transaction] = plan._id;
      });
      return allTransactions;
    }, {});

    cashflowNormalizer.setRelatedTransactions(relatedTransactions);
    dispatch('cashflowView/resetBudgetTree', null, { root: true });
  },
  async addPlan({ commit, state, dispatch }) {
    const plan = await planAheadAPI.createPlan(_.omit(state.currentPlan, ['currentAmount']));
    await commit('addPlan', plan);
  },
  async addPlanTransaction({ commit, state }, planTransaction) {
    await planAheadAPI.addPlanTransaction(planTransaction);
  },
  async deletePlan({ commit, state, dispatch }, plan) {
    await planAheadAPI.deletePlan(plan._id);
    await commit('deletePlan', plan._id);
  },
  async updatePlan({ commit, state, dispatch }) {
    const savedAmountTransaction = state.currentPlan.planTransactions?.find(transaction => transaction.creator === 'customer');
    if (state.currentPlan.savedAmountObj && !!savedAmountTransaction) {
      savedAmountTransaction.amount = state.currentPlan.savedAmountObj.amount;
      savedAmountTransaction.comment = state.currentPlan.savedAmountObj.comment;
      state.currentPlan.currentAmount = _.sumBy(state.currentPlan.planTransactions, 'amount');
      // Need to be undefined as otherwise the backend will create another customer transaction
      state.currentPlan.savedAmountObj = undefined;
      const saveAmountTransactionMetadata = _.pick(savedAmountTransaction, ['amount', 'comment']);
      await planAheadAPI.updatePlanTransaction(state.currentPlan._id, savedAmountTransaction._id, saveAmountTransactionMetadata);
    }
    const planMetadata = _.pick(state.currentPlan, ['name', 'type', 'targetAmount', 'deadline', 'savedAmountObj']);
    await planAheadAPI.updatePlan(state.currentPlan._id, planMetadata);
    await dispatch('initPlans', true);
  },
  async savePlansPrioritiesDraft({ state, commit }, plansPrioritiesDraft) {
    const { prioritiezdPlans } = await planAheadAPI.changePrioritiesForPlans(plansPrioritiesDraft.map(plan => plan._id));
    await commit('setPlans', prioritiezdPlans);
  },
  async pausePlan({ commit, state, dispatch }, plan) {
    const updatedPlan = await planAheadAPI.pausePlan(plan._id);
    await commit('updatePlan', updatedPlan);
  },
  async resumePlan({ commit, state, dispatch }, plan) {
    const updatedPlan = await planAheadAPI.resumePlan(plan._id);
    await commit('updatePlan', updatedPlan);
  },
  async completePlan({ commit, state, dispatch }, plan) {
    const updatedPlan = await planAheadAPI.completePlan(plan._id);
    await commit('updatePlan', updatedPlan);
  },
  async getCalculatedMonthlyAmount({ commit }, { deadline, targetAmount }) {
    const { calculatedMonthlyAmount } = await planAheadAPI.calculatedMonthlyAmount(deadline, targetAmount);
    await commit('setCalculatedMonthlyAmount', calculatedMonthlyAmount);
  },
  async updateCurrentPlan({ commit }, prop) {
    await commit('updateCurrentPlan', prop);
  },
  resetCurrentPlan({ commit }) {
    commit('resetCurrentPlan');
  },
  addTransactionToPlan({ commit }, { planId, transactionId }) {
    commit('addTransactionToPlan', { planId, transactionId });
  },
  removeTransactionFromPlan({ commit }, { planId, transactionId }) {
    commit('removeTransactionFromPlan', { planId, transactionId });
  },
};

const mutations = {
  setEligibleForPlanAhead(state, eligibleForPlanAhead) {
    state.eligibleForPlanAhead = eligibleForPlanAhead;
  },
  setPlans(state, plans) {
    state.plans = plans;
  },
  setInitialized(state, value) {
    state.initializedPlans = value;
  },
  setSuggestions(state, suggestions) {
    state.suggestions = suggestions;
  },
  setShowPlansInSection(state, value) {
    state.showPlansInSection = value;
  },
  addPlan(state, plan) {
    state.plans.push(plan);
    state.showPlansInSection = true;
  },
  deletePlan(state, planId) {
    const index = _.findIndex(state.plans, plan => plan._id === planId);
    state.plans.splice(index, 1);
  },
  updatePlan(state, updatedPlan) {
    const index = _.findIndex(state.plans, plan => plan._id === updatedPlan._id);
    state.plans.splice(index, 1, updatedPlan);
    state.showPlansInSection = true;
  },
  setCalculatedMonthlyAmount(state, calculatedMonthlyAmount) {
    state.calculatedMonthlyAmount = calculatedMonthlyAmount;
  },
  updateCurrentPlan(state, prop) {
    state.currentPlan = { ...state.currentPlan, ...prop };
  },
  resetCurrentPlan(state) {
    state.currentPlan = {};
    state.calculatedMonthlyAmount = null;
  },
  addTransactionToPlan(state, { planId, transactionId }) {
    const plan = state.plans?.find(plan => plan._id === planId);
    if (!plan) {
      return;
    }
    plan.relatedTransactionIds.push(transactionId);
  },
  removeTransactionFromPlan(state, { planId, transactionId }) {
    const plan = state.plans?.find(plan => plan._id === planId);
    if (!plan) {
      return;
    }
    _.remove(plan.relatedTransactionIds, transactionId);
  },
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
