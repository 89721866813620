import { render, staticRenderFns } from "./PapasMergeQuestionsActionSelection.vue?vue&type=template&id=68684659&scoped=true&"
import script from "./PapasMergeQuestionsActionSelection.vue?vue&type=script&lang=js&"
export * from "./PapasMergeQuestionsActionSelection.vue?vue&type=script&lang=js&"
import style0 from "./PapasMergeQuestionsActionSelection.vue?vue&type=style&index=0&id=68684659&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68684659",
  null
  
)

export default component.exports