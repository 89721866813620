import { client, clientWithoutErrorHandling } from '../http';

export const OFFERING_TYPES = {
  ONE_OFF: 'oneOff',
  SUBSCRIPTION: 'subscription',
  BUNDLE: 'bundle',
};
export default {

  async updateSubscriptionV2({ paymentToken = null, card = null, iterationType }) {
    const response = await clientWithoutErrorHandling.put('/api/v2/subscription', { paymentToken, card, iterationType });
    return response.data;
  },

  async purchaseGiftcard(token, email) {
    const response = await clientWithoutErrorHandling.post('/api/sale', { token, email, productType: 'GIFTCARD' });
    return response.data;
  },
  async purchaseOffering({ token, oneOffOfferings, subscriptionOfferings, bundleOfferings, email }) {
    const response = await client.post('/api/v2/offerings/purchase-new',
      { token, oneOffOfferings, subscriptionOfferings, bundleOfferings, email });
    return response.data;
  },

  async purchaseOfferings({ token, subscriptionOfferings, bundleOfferings, oneOffOfferings }) {
    const response = await client.post('/api/v2/offerings/purchase-new',
      { token, oneOffOfferings, subscriptionOfferings, bundleOfferings });
    return response.data;
  },

  async getCreditCardDetails() {
    const response = await clientWithoutErrorHandling.get('/api/credit-card-details');
    return response.data;
  },

  async getSubscriptionV2() {
    const response = await clientWithoutErrorHandling.get('/api/v2/subscription');
    return response.data;
  },

  async fetchPricingOption() {
    const response = await client.get('/api/pricing-options');
    return response.data;
  },

  async getOfferings() {
    const response = await client.get('/api/v2/offerings');
    return response.data;
  },
};
