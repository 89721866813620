import ChallengesApi from '@/api/ChallengesApi';
import moment from 'moment';
import { OFFERING_TYPES } from '@/api/BillyApi';
import DDLogs from '@/DDLogs';
import { SUBSCRIPTION_STATES } from '@/constants/credentials';
import * as _ from 'lodash';
import { SIGNUP_OFFERING_IDS } from './oneOff-Offerings';

export default class ThirtyDays {
  id = SIGNUP_OFFERING_IDS.THIRTY_DAYS;

  total = 0;

  subscriptionStartDate = moment();

  offeringCard = {
    buttonCtaIcon: 'groups_2',
    btnIconSize: 24,
    buttonCtaText: 'להתחיל עם קבוצה',
    moreInfoLink: 'https://www.events.riseup.co.il/he/together',
    offeringBullets: [
      'תוכנית אימון למשך 30 יום',
      'ליווי צמוד בקבוצת ווטסאפ ייעודית',
      'טיפים לשינוי הרגלים ותכנים בלעדיים',
    ],
    isOneOffPayment: true,
    price: 0,
  };

  purchaseInfo() {
    const isPretrial = SUBSCRIPTION_STATES.PRE_TRIAL === this.subscriptionState;
    const buttonText = isPretrial ? 'הרשמה והתחלת תקופת נסיון' : 'המשך לתשלום';
    return {
      buttonText,
      details: {
        offerId: '30-days',
        title: 'תוכנית ליווי ל-30 יום',
        subTitle: 'בתשלום חד פעמי',
        amount: 0, // will be filled later from backend
        type: OFFERING_TYPES.ONE_OFF,
      },
    };
  }

  isDormant() {
    return _.includes([SUBSCRIPTION_STATES.DORMANT_AFTER_TRIAL, SUBSCRIPTION_STATES.DORMANT_AFTER_CANCELED], this.subscriptionState);
  }

  insertPaymentDetailsPage = {
    title: 'רכישת תוכנית ומינוי',
    buttonCtaText: 'לאישור התשלום',
  };

  successPurchasePageInfo = {
    checkedItems: [{
      text: 'הרשמה למינוי',
      checked: true,
    }, {
      text: 'הצטרפות לתוכנית',
      checked: true,
    }, {
      text: 'הצטרפות לקבוצת על-זה ביחד בוואטסאפ',
      checked: false,
    }, {
      text: 'התחלת התוכנית',
      checked: false,
    }],
  };

  constructor(subscriptionState) {
    this.subscriptionState = subscriptionState;
    this.challengePromise = ChallengesApi
      .getUpcomingChallengeOfType(this.id)
      .then(challenge => { this.challenge = challenge; })
      .catch(error => { DDLogs.error(`Failed to get ${this.id} challenge`, { error }); });
  }

  async isAvailable({ offerIds, featureFlagAvailableOfferings }) {
    if (!offerIds.includes(this.id) && !featureFlagAvailableOfferings.includes(this.id)) {
      return false;
    }

    await this.challengePromise;
    return !!this.challenge;
  }

  async startDate() {
    await this.challengePromise;
    return this.challenge?.startDate;
  }
}
