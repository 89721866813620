<template>
  <div class="balance-insight-popup">
    <insight-details-popup :cashflow-category="cashflowCategory"
                           :insight="insight"
                           :buttons="buttons"
                           :backAction="backAction"
                           :showFeedbackFooter="showFeedbackFooter"
                           :close-action="close">
      <template v-slot:top-content>
        <div class="insight-prefix">
          <span class="insight-prefix-prefix">עד עכשיו יצא על </span>
          <span class="insight-expense">{{insight.expense}}</span>
        </div>
        <balance-change-details :insight="insight"/>
      </template>
      <template v-slot:bottom-content>
        <div class="insight-popup-title">המערכת עדכנה את הצפי בהתאם לסכום שיצא עד עכשיו</div>
        <edit-amount-input v-if="editAmountMode" :placeholder="balancedAmountWithFraction" :updated-amount="updatedAmount" :onInput="onInput"/>
      </template>
    </insight-details-popup>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';
import cashflowViewConsts from '@/constants/cashflow-view';
import moneyUtils from '@/utils/money';
import EventBus from '@/event-bus/event-bus';
import EditCategoryAmountToaster
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/toasters/EditCategoryAmountToaster';
import InsightDetailsPopup from './InsightDetailsPopup';
import BalanceChangeDetails from './BalanceChangeDetails';

export default {
  name: 'BalanceInsightPopup',
  components: {
    InsightDetailsPopup,
    BalanceChangeDetails,
    EditAmountInput: BaseUI.EditAmountInput,
  },
  props: {
    insight: { type: Object, required: true },
    showFeedbackFooter: { type: Boolean, default: false },
  },
  created() {
    this.sendSegmentEvent('InsightPopupShown');
  },
  data() {
    return {
      updatedAmount: null,
      editAmountMode: false,
    };
  },
  computed: {
    ...mapGetters('editTransaction', ['categoryToVariant']),
    ...mapGetters('cashflowView', ['trackingCategories', 'monthName']),
    headerVariant() {
      return this.categoryToVariant[this.cashflowCategory];
    },
    cashflowCategory() {
      return cashflowViewConsts.CASHFLOW_CATEGORIES.TRACKING;
    },
    buttons() {
      return this.editAmountMode ? this.editAmountButtons : this.actionButtons;
    },
    balancedAmountWithFraction() {
      return moneyUtils.formatAmountWithFraction(this.insight.balancedAmount);
    },
    editAmountButtons() {
      return [{
        text: 'עדכון צפי',
        variant: 'primary',
        action: async () => {
          await this.markAsRead(this.insight);
          await this.updateEnvelopeAmountApprove();
          this.openToaster();
          this.closePopup();
        },
        disabled: !this.updatedAmount || this.updatedAmount <= this.insight.balancedAmount,
      }];
    },
    actionButtons() {
      return [{
        text: 'הבנתי, תודה',
        variant: 'primary',
        action: async () => {
          this.sendSegmentEvent('RecurringBalanceInsightPopupReadButtonClicked');
          await this.markAsRead(this.insight);
          this.close();
        },
      }, {
        text: 'עדכון צפי',
        variant: 'secondary',
        action: async () => {
          this.sendSegmentEvent('RecurringBalanceInsightPopupUpdateAmountClicked');
          this.editAmountMode = true;
        },
      }];
    },
    trackingCategory() {
      return this.trackingCategories.find(tc => tc.categoryName === this.insight.expense);
    },
    categoryName() {
      return this.trackingCategory.categoryName;
    },
    envelopeId() {
      return this.trackingCategory.envelopeId;
    },
    backAction() {
      if (this.editAmountMode) {
        return () => {
          this.sendSegmentEvent('RecurringBalanceInsightBackButtonClicked');
          this.editAmountMode = false;
        };
      }

      return null;
    },
  },
  methods: {
    ...mapActions('editCashflow', ['updateEnvelopeAmount']),
    ...mapActions('insights', ['markAsRead']),
    ...mapActions('modalRootStore', ['closeModal']),
    sendSegmentEvent(eventName, extraProps = {}) {
      Segment.trackUserInteraction(eventName, { ...this.insight, ...extraProps });
    },
    close() {
      this.sendSegmentEvent('InsightPopupCloseClicked');
      this.$emit('close');
    },
    onInput(value) {
      this.updatedAmount = value;
    },
    async updateEnvelopeAmountApprove() {
      this.sendSegmentEvent('RecurringBalanceInsightPopupEnvelopeAmountApproveAmountClicked',
        {
          envelopeId: this.envelopeId,
          categoryName: this.categoryName,
          originalAmount: this.insight.originalAmount,
          updatedAmount: this.updatedAmount,
        });
      await this.updateEnvelopeAmount({
        envelopeId: this.envelopeId,
        amount: this.updatedAmount,
      });
    },
    closePopup() {
      this.closeModal();
    },
    openToaster() {
      EventBus.$emit('open-toaster', {
        component: EditCategoryAmountToaster,
        props: {
          timeFrameTitle: this.monthName,
          categoryName: this.categoryName,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.balance-insight-popup {
  .insight-prefix {
    margin-bottom: 16px;
    line-height: 11px;
    font-size: 16px;

    .insight-prefix-prefix {
      font-weight: normal;
    }

    .insight-expense {
      font-weight: bold;
    }
  }

  .insight-popup-title {
    margin-bottom: 16px;
    font-size: 22px;
    line-height: 28px;
    font-weight: bold;
  }
}
</style>
