import { render, staticRenderFns } from "./MarkPapaAsLoan.vue?vue&type=template&id=fbea9346&scoped=true&"
import script from "./MarkPapaAsLoan.vue?vue&type=script&lang=js&"
export * from "./MarkPapaAsLoan.vue?vue&type=script&lang=js&"
import style0 from "./MarkPapaAsLoan.vue?vue&type=style&index=0&id=fbea9346&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbea9346",
  null
  
)

export default component.exports