<template>
  <colored-popup    class="primary">
    <template v-slot:top-content>
      <img class="header-background margin-bottom-l" :src="require('./bullet-circle.svg')">
      <div class="riseup-white ri-large-headline">
        להסתכל קדימה זה לצמוח!
      </div>
    </template>
    <template v-slot:bottom-content>
      <div class="ri-large-body text-align-right margin-bottom-l">
        הוספת את התוכנית לרשימת התוכניות העתידיות שלך. בתזרים החיובי הבא היתרה החיובית תצבר לעבר היעד.
      </div>
      <callout class="margin-bottom-xxl text-align-right"
               iconPath="icons/heart-icon.svg" title="מה אפשר לעשות כרגע?">
        <template v-slot:text class="ri-body-l">
          כדי להגיע לתזרים חיובי, כדאי לבדוק אם יש אפשרות לצמצם בקטגוריות ולהציב יעד חודשי חדש.
          <span class="link" @click="openHowToLink"> למידע נוסף</span>
        </template>
      </callout>
      <riseup-button    class="margin-bottom-medium"
                        title="סגור"
                        :action="close"
                        size="slim"
                        :bold="true"/>
    </template>
  </colored-popup>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseUi from '@riseupil/base-ui';
import Segment from '@/Segment';

export default {
  name: 'PlanCreatedNegativeCashflowExperiencePopup',
  components: {
    ColoredPopup: BaseUi.ColoredPopup,
    Callout: BaseUi.Callout,
    RiseupButton: BaseUi.RiseupButton,
  },
  data() {
    return {
      iconColor: BaseUi.Colors.riseupWhite,
    };
  },
  created() {
    Segment.trackUserGot('PlanAhead_Flow_CreatedPopup',
      { isAble: this.isCurrentPlanOnTrack, cashflowIsPositive: this.cashflowIsPositive });
  },
  computed: {
    ...mapGetters('cashflowView', ['predictedBalanceAtEndOfMonth']),
    ...mapGetters('planAhead', ['totalPlansMonthlyAmount']),
    cashflowIsPositive() {
      return this.predictedBalanceAtEndOfMonth >= 0;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['closeModal']),
    async close() {
      this.closeModal();
    },
    isCurrentPlanOnTrack() {
      return this.totalPlansMonthlyAmount <= this.predictedBalanceAtEndOfMonth;
    },
    openHowToLink() {
      window.open(`https://intercom.riseup.co.il/he/articles/6228720-%D7%A7%D7%99%D7%91%D7%9C%D7%AA%D7%99-%D7%AA%D7%96%D7%A8%D7%99%D7%9D
-%D7%A9%D7%9C%D7%99%D7%9C%D7%99-%D7%9E%D7%94-%D7%A2%D7%9B%D7%A9%D7%99%D7%95`, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  .icon {
    position: absolute;
    width: 57.33px;
    height: 57.33px;
    left: 153.33px;
    top: 58.33px;
  }
  .riseup-white {
    color: $riseup_white;
  }

  .text-align-right {
    text-align: right;
  }
  .link {
    color: $riseup_blue;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 700;
  }
</style>
