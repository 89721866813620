import TimeLimitedOfferApi from '@/api/TimeLimitedOfferApi';
import _ from 'lodash';
import moment from 'moment';

const state = {
  timeLimitedOffers: [],
};

const getters = {
  noTrialDiscount: state => _.find(state.timeLimitedOffers, { offerId: 'no-trial-discount' }),
  noTrialDiscountEndDate: (state, getters) => getters.noTrialDiscount && moment(getters.noTrialDiscount.endDate),
};

const actions = {
  async initTimeLimitedOffers({ dispatch, commit }) {
    await dispatch('fetchTimeLimitedOffers');
  },
  async deleteTimeLimitedOffer({ dispatch, commit }, offerId) {
    await TimeLimitedOfferApi.deleteTimeLimitedOffer(offerId);
    await dispatch('fetchTimeLimitedOffers');
  },
  async createTimeLimitedOffer({ dispatch, commit }, { offerId, offerValidForHours }) {
    const response = await TimeLimitedOfferApi.createTimeLimitedOffer(offerId, offerValidForHours);
    return response.data;
  },
  async fetchTimeLimitedOffers({ commit }) {
    const timeLimitedOffers = await TimeLimitedOfferApi.getTimeLimitedOffers();
    commit('setTimeLimitedOffers', timeLimitedOffers);
  },
};

const mutations = {
  setTimeLimitedOffers(state, timeLimitedOffers) {
    state.timeLimitedOffers = timeLimitedOffers;
  },
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
