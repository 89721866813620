import _ from 'lodash';
import moment from 'moment';

import Segment from '@/Segment';
import cashflowViewConsts from '../../constants/cashflow-view';

const HIDDEN_EXCLUDED = {
  LOGIC: 'logic',
};

const SHORT_LABEL = {
  'תחבורה ציבורית': 'תחב"צ',
};

const TC_DISPLAY_NAME = {
  כלכלה: 'סופר',
};

function normalizeCashflow(cashflow) {
  initCashflowIndicatorsData(cashflow);
  return {
    [cashflowViewConsts.cashflowTypes.income]: _getIncomeSubtree(cashflow),
    [cashflowViewConsts.cashflowTypes.variableExpense]: _getVariableExpenseSubtree(cashflow),
    [cashflowViewConsts.cashflowTypes.fixedExpense]: _getFixedExpenseSubtree(cashflow),
    [cashflowViewConsts.cashflowTypes.fixedSaving]: _getFixedSavingSubtree(cashflow),
    [cashflowViewConsts.cashflowTypes.trackingCategory]: _getTrackingCategoriesSubtree(cashflow),
    [cashflowViewConsts.cashflowTypes.oneTimeSaving]: _getOneTimeSavingCategoriesSubtree(cashflow),
    [cashflowViewConsts.cashflowTypes.excluded]: _getExcludedTransactions(cashflow),
  };
}

const SavingTransactionsData = {
  savingTransactionsIds: [],
};

function setSavingTransactions(savingTransactionsIdsFromHamster) {
  SavingTransactionsData.savingTransactionsIds = savingTransactionsIdsFromHamster;
}

const CategoriesOrderData = {
  trackingCategoriesOrder: null,
};

function setCategoriesOrder(order) {
  CategoriesOrderData.trackingCategoriesOrder = order;
}

const CashflowIndicatorsData = {
  latestSeenTransactionCreationDate: null,
  twoDaysSinceActivation: false,
  editedTransactions: new Set(),
  transactionsSinceLastActivity: [],
};

const PlanAheadData = {
  relatedTransactions: {}, // { transactionId: planId }
};

function setRelatedTransactions(relatedTransactions) {
  PlanAheadData.relatedTransactions = relatedTransactions;
}

function addRelatedTransaction(planId, transactionId) {
  PlanAheadData.relatedTransactions[transactionId] = planId;
}

function removeRelatedTransaction(planId, transactionId) {
  if (PlanAheadData.relatedTransactions[transactionId] === planId) {
    PlanAheadData.relatedTransactions[transactionId] = null;
  }
}

function setActivationDate(activationDate) {
  CashflowIndicatorsData.twoDaysSinceActivation = activationDate && moment().diff(activationDate, 'days') >= 2;
}

function setLatestSeenTransactionCreationDate(latestSeenTransactionCreationDate) {
  CashflowIndicatorsData.latestSeenTransactionCreationDate = latestSeenTransactionCreationDate;
}

function addEditedTransaction(transactionId) {
  CashflowIndicatorsData.editedTransactions.add(transactionId);
}

function _transactionsInCurrentCashflow({ envelopes, excluded, variableTransactions, variableIncomeTransactions }) {
  return _.chain(envelopes)
    .flatMap(envelope => envelope.actuals)
    .concat(excluded)
    .concat(variableTransactions)
    .concat(variableIncomeTransactions)
    .value();
}

function initCashflowIndicatorsData(cashflow) {
  if (!CashflowIndicatorsData.twoDaysSinceActivation) {
    CashflowIndicatorsData.transactionsSinceLastActivity = [];
    return;
  }
  if (!CashflowIndicatorsData.latestSeenTransactionCreationDate) {
    Segment.trackUserGot('NoLatestSeenTransactionCreationDate');
    CashflowIndicatorsData.latestSeenTransactionCreationDate = moment().toISOString();
  }
  const indicatorMaxAge = moment().subtract(5, 'days');
  const transactionsDiffStartDate = indicatorMaxAge.isAfter(CashflowIndicatorsData.latestSeenTransactionCreationDate)
    ? indicatorMaxAge
    : moment(CashflowIndicatorsData.latestSeenTransactionCreationDate);
  CashflowIndicatorsData.transactionsSinceLastActivity = _transactionsInCurrentCashflow(cashflow)
    .filter(t => moment(t.createdAt).isAfter(transactionsDiffStartDate))
    .filter(t => !CashflowIndicatorsData.editedTransactions.has(t.transactionId))
    .map(t => t.transactionId);
}

function _transactionHasUpdate(transactionId) {
  return CashflowIndicatorsData.transactionsSinceLastActivity.includes(transactionId);
}

function _getIncomeSubtree(cashflow) {
  const { fixedTree } = cashflow;
  const fixedIncomeCategories = _.map(fixedTree.incomeCategories, _getFixCategory);
  const variableTransactionsWithHasUpdate = cashflow.variableIncomeTransactions.map(t => {
    return { ...t, hasUpdate: _transactionHasUpdate(t.transactionId) };
  });
  const variableIncomeCategories = [
    _getVariableDetails(cashflow.variableIncomeSum, cashflow.variableIncomeBalancedAmount, variableTransactionsWithHasUpdate),
  ];
  return {
    upToNowAmount: cashflow.incomeEnvelopesActualSum + cashflow.variableIncomeSum,
    recommendedOrExpectedAmount: cashflow.incomeEnvelopesBalanceSum + cashflow.variableIncomeBalancedAmount,
    fixedIncome: {
      categories: fixedIncomeCategories,
      hasUpdate: _.some(fixedIncomeCategories, c => c.hasUpdate),
    },
    variableIncome: {
      categories: variableIncomeCategories,
      hasUpdate: _.some(variableIncomeCategories, c => c.hasUpdate),
    },
  };
}

function _isExcludedSaving(transaction) {
  return SavingTransactionsData.savingTransactionsIds
    .find(savingTransactionsId => savingTransactionsId === transaction.transactionId);
}

function _getExcludedTransactions(cashflow) {
  const allExcluded = visibleExcluded(cashflow.excluded);
  const [income, expense] = _.chain(allExcluded)
    .sortBy(t => t.transactionDate)
    .map(t => {
      return {
        ...t,
        isExcludedSaving: _isExcludedSaving(t),
        hasUpdate: _transactionHasUpdate(t.transactionId),
        plan: PlanAheadData.relatedTransactions[t.transactionId] ?? undefined,
      };
    })
    .partition(t => t.isIncome)
    .value();
  return {
    excludedIncome: {
      categories: [{
        label: 'הכנסות',
        actualsSum: _.sumBy(income, t => t.incomeAmount),
        transactions: income,
        hasUpdate: _.some(income, t => t.hasUpdate),
      }],
    },
    excludedExpense: {
      categories: [{
        label: 'הוצאות',
        actualsSum: _.sumBy(expense, t => t.billingAmount),
        transactions: expense,
        hasUpdate: _.some(income, t => t.hasUpdate),
      }],
    },
  };
}

function visibleExcluded(transactions) {
  return _.reject(transactions, t => t.excludeReason === HIDDEN_EXCLUDED.LOGIC);
}

function _getTrackingCategoriesSubtree(cashflow) {
  const [weeklyTracking, monthlyTracking] = _.chain(cashflow.getEnvelopesByType(cashflowViewConsts.cashflowTypes.trackingCategory))
    .reject(_isOneTimeSavingEnvelope)
    .partition(e => e.details.trackingCategory.frequency === 'weekly')
    .value();
  const categories = [..._parseWeeklyTrackingCategory(weeklyTracking), ..._parseMonthlyTrackingCategory(monthlyTracking)];
  return CategoriesOrderData.trackingCategoriesOrder
    ? _.sortBy(categories, category => CategoriesOrderData.trackingCategoriesOrder.indexOf(category.categoryId) ?? 1000)
    : categories;
}

function _getOneTimeSavingCategoriesSubtree(cashflow) {
  const [weeklyTracking, monthlyTracking] = _.chain(cashflow.getEnvelopesByType(cashflowViewConsts.cashflowTypes.trackingCategory))
    .filter(_isOneTimeSavingEnvelope)
    .partition(e => e.details.trackingCategory.frequency === 'weekly')
    .value();
  const transactionProps = { isSaving: true };
  return [
    ..._parseWeeklyTrackingCategory(weeklyTracking, transactionProps),
    ..._parseMonthlyTrackingCategory(monthlyTracking, transactionProps),
  ];
}

function _isOneTimeSavingEnvelope(e) {
  return e.details.trackingCategory.trackingCategoryType === 'saving';
}

function _parseMonthlyTrackingCategory(monthlyTracking, transactionProps) {
  return _.map(monthlyTracking, envelope => {
    const categoryType = envelope.details.trackingCategory.defaultAmount && envelope.details.trackingCategory.defaultAmount.type;
    const categories = [_trackingCategoryEnvelopeToCategory(envelope, transactionProps)];
    return {
      envelopeId: envelope.id,
      categoryName: TC_DISPLAY_NAME[envelope.details.trackingCategory.name] || envelope.details.trackingCategory.name,
      categoryId: envelope.details.trackingCategory._id,
      upToNowAmount: envelope.actualsAmount,
      recommendedOrExpectedAmount: envelope.predictedAmount,
      categories,
      icon: envelope.details.trackingCategory.icon,
      frequency: 'monthly',
      basedOnHistoryAverage: envelope.details.trackingCategory.basedOnHistoryAverage,
      categoryType: categoryType || undefined,
      isCustomPrediction: envelope.isCustomPrediction,
      hasUpdate: _.some(categories, c => c.hasUpdate),
    };
  });
}

function _parseWeeklyTrackingCategory(weeklyTracking, transactionProps) {
  const weeklyByType = _.groupBy(weeklyTracking, e => e.details.trackingCategory.name);
  return _.map(weeklyByType, envelopes => {
    const categoryName = TC_DISPLAY_NAME[envelopes[0].details.trackingCategory.name] || envelopes[0].details.trackingCategory.name;
    const categoryType = envelopes[0].details.trackingCategory.defaultAmount && envelopes[0].details.trackingCategory.defaultAmount.type;
    const categories = _.chain(envelopes)
      .sortBy(envelope => envelope.details.weeklyIndex)
      .map(e => _weeklyEnvelopeToCategory(e, categoryName, transactionProps))
      .value();
    return {
      categoryName,
      categoryId: envelopes[0].details.trackingCategory._id,
      upToNowAmount: _.sumBy(envelopes, envelope => envelope.actualsAmount),
      recommendedOrExpectedAmount: _.sumBy(envelopes, envelope => envelope.balancedAmount),
      icon: envelopes[0].details.trackingCategory.icon,
      frequency: 'weekly',
      categories,
      basedOnHistoryAverage: envelopes[0].details.trackingCategory.basedOnHistoryAverage,
      categoryType: categoryType || undefined,
      isCustomPrediction: _.some(envelopes, envelope => envelope.isCustomPrediction),
      hasUpdate: _.some(categories, c => c.hasUpdate),
    };
  });
}

function _getFixedSavingSubtree(cashflow) {
  const { fixedTree } = cashflow;
  const categories = _getOrderedFixedCategories(fixedTree.savingCategories);
  return {
    upToNowAmount: fixedTree.fixedSavingEnvelopesActualSum,
    recommendedOrExpectedAmount: fixedTree.fixedSavingEnvelopesBalanceSum,
    categories,
    hasUpdate: _.some(categories, c => c.hasUpdate),
  };
}

function _getFixedExpenseSubtree(cashflow) {
  const { fixedTree } = cashflow;
  const categories = _getOrderedFixedCategories(fixedTree.expenseCategories);
  return {
    upToNowAmount: fixedTree.fixedExpenseEnvelopesActualSum,
    recommendedOrExpectedAmount: fixedTree.fixedExpenseEnvelopesBalanceSum,
    categories,
    hasUpdate: _.some(categories, c => c.hasUpdate),
  };
}

function _getOrderedFixedCategories(expenseCategories) {
  const orderedExpenses = _getOrderedExpenseNames(_.keys(expenseCategories));
  return _.chain(expenseCategories)
    .map(_getFixCategory)
    .sortBy(_.partialRight(_getFixedOrderIndex, orderedExpenses))
    .value();
}

function _getOrderedExpenseNames(expenseNames) {
  const knownPositionExpenses = _.intersection([
    cashflowViewConsts.OTHER_CATEGORY,
    ..._.sortBy(cashflowViewConsts.PAYMENT_METHODS_FIXED_EXPENSE_CATEGORIES),
  ], expenseNames);
  const regularExpenses = _.chain(expenseNames)
    .difference(knownPositionExpenses)
    .sortBy()
    .value();
  return [...regularExpenses, ...knownPositionExpenses];
}

function _getVariableExpenseSubtree(cashflow) {
  const currentMonthVariableExpenses = cashflow.weeklyVariables.toJson();
  const monthlyCategories = [_getVariableDetails(
    cashflow.variableActualSum,
    cashflow.variableBalancedAmount,
    cashflow.variableTransactions,
  )];
  const weeklyCategories = _.map(currentMonthVariableExpenses.weeks, week => {
    const label = `שבוע ${week.weekIndex + 1}`;
    const { sum: actualsSum, transactions, remaining: recommendedAmount } = week;
    const weekTimeFrame = _getEnvelopeWeekTimeFrame(week, moment());
    const disabled = _isFutureWeek(weekTimeFrame) && week.transactions.length === 0;
    const transactionsWithHasUpdate = transactions.map(t => {
      return { ...t, hasUpdate: _transactionHasUpdate(t.transactionId) };
    });
    const sortedTransactions = _.sortBy(transactionsWithHasUpdate, t => t.transactionDate);
    return {
      ...week,
      label,
      actualsSum,
      transactions: sortedTransactions,
      recommendedAmount,
      disabled,
      initiallyExpanded: false,
      timeFrameDetails: {
        timeFrame: cashflowViewConsts.envelopeTimeFrames.weekly,
        isCurrentWeek: weekTimeFrame === cashflowViewConsts.weekTimeFrames.current,
        weeklyIndex: week.weekIndex,
      },
      hasUpdate: _.some(sortedTransactions, t => t.hasUpdate),
    };
  });
  return {
    upToNowAmount: cashflow.variableActualSum,
    recommendedOrExpectedAmount: _.max([cashflow.variableBalancedAmount, 0]),
    monthly: {
      realExpenses: {
        categories: monthlyCategories,
        hasUpdate: _.some(monthlyCategories, c => c.hasUpdate),
      },
    },
    weekly: {
      realExpenses: {
        categories: weeklyCategories,
        hasUpdate: _.some(weeklyCategories, c => c.hasUpdate),
      },
    },
    hasUpdate: _.some(weeklyCategories, c => c.hasUpdate),
  };
}

function _isFutureWeek(weekTimeFrame) {
  return weekTimeFrame === cashflowViewConsts.weekTimeFrames.future;
}

function _getFixCategory(fixCategory) {
  const transactions = toPredictedTransactions(fixCategory.envelopes);
  return {
    label: fixCategory.expense,
    actualsSum: fixCategory.actualsAmount,
    recommendedAmount: fixCategory.balancedAmount,
    transactions,
    initiallyExpanded: false,
    hasUpdate: _.some(transactions, t => t.hasUpdate),
  };
}

function _getFixedOrderIndex(fixCategory, allExpenseCategoriesOrdered) {
  return _.findIndex(allExpenseCategoriesOrdered, c => c === fixCategory.label);
}

function _weeklyEnvelopeToCategory(envelope, categoryName, transactionProps) {
  const { weeklyIndex } = envelope.details;
  const label = `שבוע ${weeklyIndex + 1}`;
  const weekTimeFrame = _getEnvelopeWeekTimeFrame(envelope.details, moment());
  const transactions = _.chain(envelope.actuals)
    .sortBy(t => t.transactionDate)
    .map(t => {
      return {
        ...t,
        ...transactionProps,
        hasUpdate: _transactionHasUpdate(t.transactionId),
      };
    })
    .value();
  return {
    label,
    parentCategory: categoryName,
    actualsSum: envelope.actualsAmount,
    recommendedAmount: envelope.balancedAmount,
    transactions,
    initiallyExpanded: false,
    disabled: weekTimeFrame === cashflowViewConsts.weekTimeFrames.future && _.isEmpty(envelope.actuals),
    isEditable: !envelope.balanceDatePassed,
    categoryId: envelope.details.trackingCategory._id,
    envelopeId: envelope.id,
    timeFrameDetails: {
      timeFrame: cashflowViewConsts.envelopeTimeFrames.weekly,
      weeklyIndex,
      startDate: envelope.details.startDate,
      endDate: moment(envelope.details.endDate)
        .subtract(1, 'days')
        .format('YYYY-MM-DD'),
      isCurrentWeek: weekTimeFrame === cashflowViewConsts.weekTimeFrames.current,
    },
    hasUpdate: _.some(transactions, t => t.hasUpdate),
  };
}

function _trackingCategoryEnvelopeToCategory(envelope, transactionProps) {
  const { trackingCategory } = envelope.details;
  const transactions = _.chain(envelope.actuals)
    .sortBy(t => t.transactionDate)
    .map(t => {
      return {
        ...t,
        ...transactionProps,
        hasUpdate: _transactionHasUpdate(t.transactionId),
      };
    })
    .value();
  return {
    label: trackingCategory.name,
    shortLabel: SHORT_LABEL[trackingCategory.name],
    parentCategory: trackingCategory.name,
    actualsSum: envelope.actualsAmount,
    recommendedAmount: envelope.balancedAmount,
    transactions,
    initiallyExpanded: true,
    disabled: false,
    isEditable: !envelope.balanceDatePassed,
    categoryId: envelope.details.trackingCategory._id,
    envelopeId: envelope.id,
    timeFrameDetails: {
      timeFrame: cashflowViewConsts.envelopeTimeFrames.monthly,
    },
    hasUpdate: _.some(transactions, t => t.hasUpdate),
  };
}

function _getVariableDetails(actualsSum, recommendedAmount, variableTransactions) {
  const transactions = _.sortBy(variableTransactions, t => t.transactionDate);
  return {
    label: '',
    actualsSum,
    recommendedAmount,
    transactions,
    initiallyExpanded: true,
    disabled: false,
    hasUpdate: _.some(transactions, t => t.hasUpdate),
  };
}

function toPredictedTransactions(envelopes) {
  return _.map(envelopes, envelope => {
    return {
      name: envelope.details.businessName,
      expense: envelope.details.expense,
      predictedAmount: envelope.balancedAmount,
      actual: envelope.actuals[0],
      previousTransactionId: envelope.details.previousTransactionId,
      predictedTransactionDate: envelope.details.transactionDate,
      balanceDatePassed: envelope.balanceDatePassed,
      envelopeId: envelope.id,
      sequenceId: envelope.details.sequenceId,
      sequenceIdSource: envelope.details.sequenceIdSource,
      isIncome: _isIncome(envelope.originalAmount),
      isSaving: _isFixedSaving(envelope),
      sequenceCustomerComment: envelope.sequenceCustomerComment,
      hasUpdate: envelope.actuals[0]?.transactionId ? _transactionHasUpdate(envelope.actuals[0].transactionId) : false,
    };
  });
}

function _isFixedSaving(envelope) {
  return envelope.type === cashflowViewConsts.CASHFLOW_CATEGORIES.FIXED
    && cashflowViewConsts.FIXED_SAVING_CATEGORY_NAMES.includes(envelope.details?.expense);
}

function _isIncome(amount) {
  return amount < 0;
}

function _getEnvelopeWeekTimeFrame(weekDetails, now) {
  const {
    endDate,
    startDate,
  } = weekDetails;
  const nowUTC = now.utc();
  const startDateUTC = moment(startDate).utc();
  const endDateUTC = moment(endDate).utc();
  if (moment(endDateUTC).isBefore(nowUTC)) {
    return cashflowViewConsts.weekTimeFrames.past;
  }
  if (moment(startDateUTC).isSameOrBefore(nowUTC) && nowUTC.isBefore(endDateUTC)) {
    return cashflowViewConsts.weekTimeFrames.current;
  }
  return cashflowViewConsts.weekTimeFrames.future;
}

export default {
  setLatestSeenTransactionCreationDate,
  setActivationDate,
  setRelatedTransactions,
  addRelatedTransaction,
  removeRelatedTransaction,
  addEditedTransaction,
  normalizeCashflow,
  visibleExcluded,
  TC_DISPLAY_NAME,
  setSavingTransactions,
  setCategoriesOrder,
};
