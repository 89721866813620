import _ from 'lodash';
import Vue from 'vue';

import { getSourceConfigurationBySourceName } from '@/constants/sourcesConfiguration';

import {
  fetchClassifiedMergeInvestigations,
  fetchPapasQuestions,
  fetchSonsQuestions,
  answerSonQuestion,
  answerPapaQuestion,
  answerMultipleQuestions,
} from '../../api/MergeInvestigatorApi';

const state = {
  initialized: false,
  papasMergeQuestions: [],
  sonsMergeQuestions: [],
  classifiedMergeInvestigations: null,
  loading: false,
  papaInputTypes: {
    approved: 'approved',
    loan: 'loan',
    clearing: 'clearing',
    addCreds: 'addCreds',
    bug: 'bug',
  },
  cardInputTypes: {
    addCreds: 'addCreds',
    excluded: 'excluded',
    approvedAccount: 'approvedAccount',
    bug: 'bug',
  },
  sourceNameToAdd: undefined,
  onCredentialsAddSuccess: undefined,
};

const getters = {
  allPapasAnswered: state => _.every(state.papasMergeQuestions, q => q.input && (!isAddCredsInput(q) || hasSource(q))),
  allSonsAnswered: state => _.every(state.sonsMergeQuestions, q => q.input && (!isAddCredsInput(q) || q.bankSource)),
  allQuestionsAnswered: (state, getters) => {
    // we need this line otherwise vuex doesn't watch the second getter because the first one is always false
    const sonsAnswered = getters.allSonsAnswered;
    return getters.allPapasAnswered && sonsAnswered;
  },
  answeredPapas: state => _.filter(state.papasMergeQuestions, q => q.input),
  classifiedMergeInvestigation: state => transactionId => _.find(state.classifiedMergeInvestigations,
    question => question.transactionId === transactionId),
};

const actions = {
  async fetchMergeQuestions({ commit, state }) {
    if (state.initialized) {
      return;
    }
    commit('setLoading', { loading: true });
    const papasMergeQuestions = getLatestQuestionPerBusinessName(await fetchPapasQuestions());
    const sonsMergeQuestions = getLatestQuestionPerAccount(await fetchSonsQuestions());
    const classifiedMergeInvestigations = await fetchClassifiedMergeInvestigations();
    commit('setPapasMergeQuestions', { papasMergeQuestions });
    commit('setSonsMergeQuestions', { sonsMergeQuestions });
    commit('setClassifiedMergeInvestigations', { classifiedMergeInvestigations });
    commit('setLoading', { loading: false });
    commit('setInitialized', true);
  },
  async setClassifiedPapaQuestionInput({ dispatch, commit, state }, { question, input }) {
    await dispatch('setPapaQuestionInput', { question, input });
    commit('removeClassifiedMergeInvestigation', { transactionId: question.transactionId });
  },
  async setPapaQuestionInput({ commit, state }, { question, input }) {
    if (input !== state.papaInputTypes.addCreds) {
      await answerPapaQuestion({
        businessName: question.businessName, accountNumberPiiId: question.accountNumberPiiId, transactionId: question.transactionId, input,
      });
    }
    commit('setPapaQuestionInput', { question, input });
  },
  async setPapaSource({ commit }, { transactionId, cardSource }) {
    commit('setPapaSource', { transactionId, cardSource });
  },
  async setSonQuestionInput({ commit, state }, { chunkId, input }) {
    const question = _.find(state.sonsMergeQuestions, q => q.chunkId === chunkId);
    if (input !== state.cardInputTypes.addCreds) {
      await answerSonQuestion({
        chunkId,
        input,
        accountNumberPiiId: question.accountNumberPiiId,
      });
    }
    commit('setSonsQuestionInput', { question, input });
  },
  async addSonSource({ commit }, { chunkId, source: { sourceName } }) {
    commit('addSonSource', { chunkId, sourceName });
  },
  async finishMergeInvestigation({ state, commit }) {
    commit('setLoading', { loading: true });
    const papaInputs = _.chain(state.papasMergeQuestions).reject(isAddCredsInput).map(extractPapasInput).value();
    const sonInputs = _.chain(state.sonsMergeQuestions).reject(isAddCredsInput).map(extractSonsInput).value();
    await answerMultipleQuestions(papaInputs, sonInputs);
    const papaSourcesToAdd = _.chain(state.papasMergeQuestions).filter(isAddCredsInput).map(q => q.cardSource)
      .value();
    const sonSourcesToAdd = _.chain(state.sonsMergeQuestions).filter(isAddCredsInput).map(q => q.bankSource)
      .value();
    commit('setLoading', { loading: false });
    return [...papaSourcesToAdd, ...sonSourcesToAdd];
  },
};

// mutations
const mutations = {
  setPapasMergeQuestions(state, { papasMergeQuestions }) {
    state.papasMergeQuestions = papasMergeQuestions;
  },
  setSonsMergeQuestions(state, { sonsMergeQuestions }) {
    state.sonsMergeQuestions = sonsMergeQuestions;
  },
  setClassifiedMergeInvestigations(state, { classifiedMergeInvestigations }) {
    state.classifiedMergeInvestigations = classifiedMergeInvestigations;
  },
  addSonSource(state, { chunkId, sourceName }) {
    const question = _.find(state.sonsMergeQuestions, q => q.chunkId === chunkId);
    Vue.set(question, 'bankSource', sourceName);
  },
  setPapaQuestionInput(state, { input, question }) {
    const papaMergeQuestion = _.find(state.papasMergeQuestions, q => q.transactionId === question.transactionId);
    Vue.set(papaMergeQuestion, 'input', input);
  },
  setPapaSource(state, { cardSource, transactionId }) {
    const question = _.find(state.papasMergeQuestions, q => q.transactionId === transactionId);
    Vue.set(question, 'cardSource', cardSource);
  },
  async setSonsQuestionInput(state, { input, question }) {
    Vue.set(question, 'input', input);
  },
  removeClassifiedMergeInvestigation(state, { transactionId }) {
    state.classifiedMergeInvestigations = _.omitBy(state.classifiedMergeInvestigations, { transactionId });
  },
  setLoading(state, { loading }) {
    state.loading = loading;
  },
  setSourceNameToAdd(state, sourceNameToAdd) {
    state.sourceNameToAdd = sourceNameToAdd;
  },
  setOnCredentialsAddSuccess(state, onCredentialsAddSuccess) {
    state.onCredentialsAddSuccess = onCredentialsAddSuccess;
  },
  setInitialized(state, initialized) {
    state.initialized = initialized;
  },
};

function isAddCredsInput(question) {
  return question.input === state.papaInputTypes.addCreds;
}

function extractPapasInput(papasQuestion) {
  return { transactionId: papasQuestion.transactionId, input: papasQuestion.input };
}

function extractSonsInput(sonsQuestion) {
  return {
    chunkId: sonsQuestion.chunkId,
    input: sonsQuestion.input,
    account: sonsQuestion.accountNumberPiiValue,
    accountNumberPiiId: sonsQuestion.accountNumberPiiId,
  };
}

function hasSource(question) {
  return !!(question.cardSource && getSourceConfigurationBySourceName(question.cardSource));
}

export function getLatestQuestionPerBusinessName(questions) {
  return _.chain(questions)
    .groupBy('businessName')
    .mapValues(papaQuestions => _.maxBy(papaQuestions, question => question.billingDate))
    .values()
    .flatten()
    .value();
}

export function getLatestQuestionPerAccount(questions) {
  return _.chain(questions)
    .groupBy('accountNumberPiiId')
    .mapValues(sonsQuestions => _.maxBy(sonsQuestions, question => question.billingDate))
    .values()
    .flatten()
    .value();
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
