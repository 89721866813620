<template>
  <div class="approve-consent-override-popup">
    <popup :close-action="closeModal" class="popup">
      <template v-slot:title>
        <img :src="require('@/assets/red-eight-pointed-alert-flower.svg')" class="alert-icon-in-popup"/>
        <div class="popup-title">{{ title }}</div>
      </template>
      <template v-slot:content>
        <div>
          <div class="description">
            <span class="bold">רצית לחבר {{ accountsOrCards }} נוספים להסכמה או למחוק ממנה {{ accountsOrCards }}?</span>
            <span>במעבר לאתר של {{sourceName}} ההרשאה הקיימת תימחק.</span>
          </div>
          <riseup-button :title="`להמשיך לאתר ${sourceName}`"
                         variant="secondary"
                         @click="openRedirectToOpenBankingPopup" />
        </div>
      </template>
    </popup>
  </div>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import RedirectToOpenBankingPopup from '@/base-components/onboarding/openBanking/RedirectToOpenBankingPopup';
import { mapActions } from 'vuex';
import Segment from '../../../Segment';

export default {
  name: 'ApproveConsentOverridePopup',
  props: {
    idNumber: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    source: {
      type: Object,
      required: true,
    },
    existingValidConsent: {
      type: Object,
      required: true,
    },
  },
  async created() {
    Segment.trackUserGot('ApproveConsentOverridePopup', { idNumber: this.idNumber, source: this.source.name });
  },
  components: {
    Popup: BaseUI.Popup,
    RiseupButton: BaseUI.V2Components.RiseupButton,
  },
  computed: {
    title() {
      return `חשוב לשים לב, החשבונות ב${this.sourceName} כבר מחוברים בחשבון`;
    },
    sourceName() {
      return this.source.displayName;
    },
    sourceTypeText() {
      return this.source.type === 'bank' ? 'הבנק יבקש' : 'חברת האשראי תבקש';
    },
    accountsOrCards() {
      return this.source.type === 'bank' ? 'חשבונות' : 'כרטיסים';
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    closeModal() {
      Segment.trackUserInteraction('ApproveConsentOverridePopup_CloseClicked', { idNumber: this.idNumber, source: this.source.sourceName });
      this.$emit('close');
    },
    openRedirectToOpenBankingPopup() {
      this.closeModal();
      this.openModal({
        component: RedirectToOpenBankingPopup,
        props: {
          idNumber: this.idNumber,
          name: this.name,
          source: this.source,
          credentialsToUpdateMetaData: this.existingValidConsent,
        },
      });
    },
    redirectToCashflow() {
      this.closeModal();
      this.$router.push({ path: '/sr' });
    },
  },
};
</script>

<style lang="scss" scoped>

@import '~@riseupil/base-ui/src/scss/riseup-colors';

  .approve-consent-override-popup {
    text-align: center;
    .popup{
      padding-bottom: 0;
    }
    .popup-title {
      text-align: right;
      font-family: SimplerPro;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 27px;
    }

    .description {
      text-align: right;
      font-family: SimplerPro;
      margin-bottom: 27px;
      font-size: 16px;
      line-height: 22px;

      .bold {
        font-weight: bold;
      }
    }
  }

</style>
