<template>
  <sticky-cta-page :cta-props="{buttonProps: { title: paymentButtonTitle || 'לשלם ולצאת לדרך!'}}" @click="next">
  <div class="one-off-landing-page">
    <page-header v-bind="pageHeaderProps" />
    <dashboard-page :image-header-props="imageHeaderProps">
      <template v-slot:header>
        <div class="header margin-bottom-l">
          <div class="ri-small-display">
            <div v-for="sentence in header" :key="sentence">{{ sentence }}</div>
          </div>
          <div v-if="subHeader" class="ri-large-bold-body margin-top-s" :class="textVariant">{{ subHeader }}</div>
        </div>
      </template>
      <template v-slot:dashboard>
        <div v-if="fullPriceBeforeDiscount" class="extra">
          <div class="extra-text"> {{ discountText }} </div>
        </div>
        <div class="padding-horizontal-ml">
          <div class="text-container padding-vertical-ml">
            <div class="title ri-title">
              <div v-for="(sentence, i) in title" :key="sentence" :class="{ 'ri-font-weight-bold': i === 0 }">{{ sentence }}</div>
            </div>
            <div class="price ri-bold-title">
              <div>{{ formattedPrice }}</div>
              <div v-if="fullPriceBeforeDiscount" class="strike">{{ fullPrice }}</div>
            </div>
          </div>
          <separator />
        </div>
        <div class="flex-column padding-horizontal-ml margin-top-l">
          <list-item v-for="(item, index) in displayItems" :item="item" :image="images.bullet" :key="index"/>
        </div>
      </template>
      <div class="content">
        <div class="padding-ml">
          <riseup-button v-if="configs.showChat" class="button border-radius-medium"
                         :class="{ 'margin-bottom-m': configs.showShare }"
                         :action="contactTeam"
                         :size="'slim'"
                         title="לצ׳אט עם הצוות"
                         :icon="require('@/assets/icons/chat_icon_secondary.svg')"
                         :variant="'secondary'"
                         :id="`cx-just-in-time-${selectedOffering.id}-flow`" />
          <riseup-button v-if="configs.showShare" class="button border-radius-medium" :action="shareToOther"
                                     :size="'slim'"
                                     :title="shareTitle"
                                     icon="/images/advisory/advisory-share.svg"
                                     :variant="'secondary'"/>
        </div>
      </div>
    </dashboard-page>
  </div>
  </sticky-cta-page>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import Segment from '@/Segment';
import { mapGetters, mapState } from 'vuex';
import Intercom from '@/Intercom';
import config from '@/config';
import shareUtils from '@/utils/share';
import { BACKGROUND_POSITION } from '@riseupil/base-ui/src/utils/layout-consts';
import moneyUtils from '@/utils/money';
import ListItem from '@/pages/responsive-pages/authenticated/navigation-pages/offerings/one-off/ListItem.vue';

export default {
  name: 'OneOffLandingPage',
  components: {
    RiseupButton: BaseUI.RiseupButton,
    DashboardPage: BaseUI.Pages.DashboardPage,
    StickyCtaPage: BaseUI.Pages.StickyCtaPage,
    PageHeader: BaseUI.PageHeader,
    Separator: BaseUI.Separator,
    ListItem,
  },
  created() {
    Segment.trackUserGot('OneOffLandingPage', { offeringId: this.selectedOffering?.id });
  },
  computed: {
    ...mapGetters('session', ['otherMemberFirstName']),
    ...mapGetters('oneOffOfferings', ['fullPriceBeforeDiscount', 'displayItems', 'offeringsTypes', 'header', 'title', 'images', 'variant',
      'configs', 'textVariant', 'subHeader', 'paymentButtonTitle']),
    ...mapState('oneOffOfferings', ['selectedOffering']),
    shareTitle() {
      if (this.otherMemberFirstName) {
        return ` לשתף עם ${this.otherMemberFirstName} `;
      }
      return 'לשתף';
    },
    imageHeaderProps() {
      return {
        // eslint-disable-next-line global-require
        imgSrc: this.images?.landing,
        backgroundColor: 'dark-blue',
        bottomSeparator: false,
        textColor: 'white',
        backgroundPosition: BACKGROUND_POSITION.BOTTOM,
      };
    },
    fullPrice() {
      const fullPriceFormatted = moneyUtils.formatAmountWithFraction(this.fullPriceBeforeDiscount, 0);
      return `${fullPriceFormatted} ₪`;
    },
    formattedPrice() {
      const formattedPrice = moneyUtils.formatAmountWithFraction(this.selectedOffering?.price?.amount, 0);
      return `${formattedPrice} ₪`;
    },
    discountText() {
      const percentageDiscount = ((this.fullPriceBeforeDiscount - this.selectedOffering?.price?.amount) / this.fullPriceBeforeDiscount) * 100;
      const formatted = Math.floor(percentageDiscount);
      return `${formatted}% הנחה`;
    },
    pageHeaderProps() {
      return {
        backgroundColorVariant: this.variant,
        textColor: BaseUI.Colors.riseupWhite,
        sticky: true,
        showLogo: false,
        bottomBorder: true,
        rightActions: [{
          type: BaseUI.HeaderActions.BACK,
          onClick: this.back,
        }],
        leftActions: [{
          type: BaseUI.HeaderActions.CLOSE,
          onClick: this.close,
        }],
      };
    },
  },
  methods: {
    next() {
      this.$emit('next');
    },
    back() {
      this.$emit('back');
    },
    close() {
      this.$emit('close');
    },
    contactTeam() {
      Segment.trackUserInteraction('OneOffLandingPage_Contact_CX_Clicked', { offeringId: this.selectedOffering?.id });
      Intercom.showNewMessage('');
    },
    async shareToOther() {
      Segment.trackUserInteraction('OneOffLandingPage_Contact_CX_Clicked_Share_Clicked', { offeringId: this.selectedOffering?.id });
      const shareLinkUrl = `${config.get().elsaPublicUrl}/${this.configs.shareRoute}`;
      const text = `${this.otherMemberFirstName}, לרייזאפ יש תוכנית חדשה של ייעוץ אישי לצמיחה כלכלית. מה דעתך?`;
      await shareUtils.shareLink(shareLinkUrl, text, {});
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';
@import '~@riseupil/base-ui/src/scss/border-radius';

.one-off-landing-page {
  --dot-color: #{$riseup_black};
  color: $riseup_dark_green;

  display: flex;
  flex-direction: column;
  height: 100%;

  .content {
    height: 100%;
    flex: 1;
  }

  .price {
    text-align: left;

    .strike {
      color: $riseup_gray_1;
      text-decoration: line-through;
    }
  }

  .extra {
    position: relative;

    .extra-text {
      position: absolute;
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 80px;
      height: 30px;
      top: -15px;
      left: 20px;
      font-size: 12px;
      font-weight: bold;
      color: $riseup-white;
      border-radius: 9px;
      background-color: $riseup-green;
    }

    .icon {
      position: absolute;
      bottom: 160px;
      left: 0;
    }
  }

  .button {
    flex-shrink: 0;
  }

  .text-container {
    display: flex;
    justify-content: space-between;

    .title {
      text-align: start;
    }
  }

  .review-item {
    position: absolute;
    z-index: 1;
    top: 20px;
    .dark-green {
      color: $riseup_dark_green;
    }
    .green {
      color: $riseup_green;
    }
  }

  .sticky-button {
    position: sticky;
    bottom: 0;
    background-color: $riseup_white;
    z-index: 2000;

    .purchase-btn{
      margin-bottom: 20px;
    }

    ::v-deep .riseup-button.discount-secondary {
      color: $riseup_dark_green;
      background: $riseup_white;
      border: 2px solid $riseup_dark_green;
    }
  }

  ::v-deep .card.header-card {
    overflow: visible;
  }

  ::v-deep .image-header .image {
    background-position: top!important;
  }

  ::v-deep .image-header.dark-blue .image {
    background: #142AA0;
  }

  ::v-deep .image-header-content .image-header-page-header-text {
    max-width: 70%;
  }

  ::v-deep .content.card-body {
    overflow: visible;
  }

  .header {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    text-align: right;
    height: 100%;
    width: 100%;
  }

  .saturated-red {
    color: $riseup_light_saturated_red;
  }

  .white {
    color: $riseup_white;
  }
}
</style>
