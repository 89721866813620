<template>
  <transaction-insight-popup :insight-popup-title="popupTitle" :insight-prefix="'הסכום שנכנס'"
                             :cashflow-category="cashflowCategory" @close="closeModal"
                             :insight="transaction" :show-feedback-footer="false" :buttons="buttons"
                             :insight-popup-explanation="excludeIncomeExplanation">
    <template v-slot:footer class="footer-button">

      <riseup-button v-if="jitHighIncomeExclusion"
                     id="cx-high-income-exclusion"
                     :action="handleIntercomClickAction"
                     :icon="require('@/assets/icons/chat_icon_teriatry.svg')"
                     :title="'לצ׳אט עם הצוות'"
                     variant="tertiary"
                     :color="buttonColor"
                     class="footer-button"/>
    </template>
  </transaction-insight-popup>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import TransactionInsightPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/TransactionInsightPopup.vue';
import dateUtils from '@/utils/dates';
import CashflowUpdaterApi from '@/api/CashflowUpdaterApi';
import cashflowViewConsts from '@/constants/cashflow-view';
import Segment from '@/Segment';
import Intercom from '@/Intercom';
import BaseUI from '@riseupil/base-ui';
import EventBus from '@/event-bus/event-bus';
import ExclusionToaster from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/toasters/insights/ExclusionToaster.vue';

export default {
  name: 'HighIncomeExclusionPopup',
  components: { TransactionInsightPopup, RiseupButton: BaseUI.RiseupButton },
  props: {
    transaction: { required: true },
    insight: { default: null },
  },
  data() {
    return {
      buttonColor: BaseUI.LayoutConsts.COLOR_VARIANT.BLACK,
    };
  },
  created() {
    Segment.trackUserInteraction('HighIncomeExclusionPopup_Shown');
    Segment.trackUserInteraction('JustInTimeCX_Shown', { source: this.componentName, isButtonShown: this.jitHighIncomeExclusion });
  },
  computed: {
    ...mapGetters('featureFlags', ['jitHighIncomeExclusion']),
    ...mapState('cashflowView', ['cashflowStartDay', 'presentedBudgetDate']),
    cashflowCategory() {
      return cashflowViewConsts.CASHFLOW_CATEGORIES.EXCLUDED_INCOME;
    },
    popupTitle() {
      return `זוהתה הכנסה גבוהה במיוחד, המערכת הוציאה אותה מתזרים ${(dateUtils.getHebrewMonth(this.transaction.transactionDate))}`;
    },

    buttons() {
      return [
        {
          variant: 'primary',
          text: 'להשאיר מחוץ לתזרים',
          action: async () => {
            Segment.trackUserInteraction('HighIncomeExclusionPopup_KeepExcludedClicked');
            await this.excludeIncome({ transactionId: this.transaction.transactionId });
            await CashflowUpdaterApi.excludeTransaction({ transactionId: this.transaction.transactionId });
            this.closeModal();
            this.openExclusionToaster('ההכנסה תישאר מחוץ לתזרים');
          },
        },
        {
          text: 'להחזיר לתזרים',
          variant: 'secondary',
          action: async () => {
            Segment.trackUserInteraction('HighIncomeExclusionPopup_ReturnToCashflowClicked');
            await this.unexcludeTransaction({ transactionId: this.transaction.transactionId });
            this.closeModal();
            this.openExclusionToaster('ההכנסה עברה לתזרים');
          },
        },
      ];
    },
    excludeIncomeExplanation() {
      // eslint-disable-next-line max-len
      return `אם היא אמורה לממן את ההוצאות של החודש, אפשר להחזיר אותה והיא תשפיע על הסכום שישאר להוציא ב${(dateUtils.getHebrewMonth(this.transaction.transactionDate))}.`;
    },
  },
  methods: {
    ...mapActions('editCashflow', ['unexcludeTransaction', 'excludeIncome']),
    ...mapActions('modalRootStore', ['closeModal']),

    handleIntercomClickAction() {
      Segment.trackUserInteraction('JustInTimeCX_Clicked', { source: this.componentName });
      Intercom.showNewMessage('');
    },
    openExclusionToaster(action) {
      EventBus.$emit('open-toaster', {
        component: ExclusionToaster,
        props: {
          excludedAmount: 'income',
          excludedAction: action,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>

::v-deep #popup .footer {
  padding: 0;
  border-radius: 11px;
}

.footer-button {
  border-radius: 11px;
}

</style>
