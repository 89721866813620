<template>
  <div ref="container" class="progress-bar-illustration" :class="[cashflowCategory, { 'grayed-out': grayedOut }]">
    <div class="progress-animation"
         :class="[cashflowCategory, { 'grayed-out': grayedOut }]"
         :style="{ width: `${barWidthPercent}%` }"></div>
  </div>
</template>

<script>
import _ from 'lodash';
import EventBus from '@/event-bus/event-bus';
import cashflowViewConsts from '../constants/cashflow-view';

export default {
  name: 'AnimatedBar',
  props: {
    totalAmount: {
      required: true,
    },
    cashflowCategory: {
      type: String,
      required: false,
      validator: val => _.values(cashflowViewConsts.CASHFLOW_CATEGORIES).includes(val),
    },
    grayedOut: {
      type: Boolean,
      required: false,
      default: false,
    },
    upToNowAmount: {
      required: true,
    },
    animateInViewport: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fillAmount: 0,
      didAnimate: false,
      observer: undefined,
    };
  },
  async mounted() {
    await this.observeAnimation();
  },
  beforeDestroy() {
    this.observer?.disconnect();
  },
  computed: {
    barWidthPercent() {
      if (this.fillAmount === 0) {
        return 0;
      }
      return _.min([Math.floor((this.fillAmount / this.totalAmount) * 100), 100]);
    },
  },
  methods: {
    elementHasPosition(element) {
      const rect = element.getBoundingClientRect();
      return rect.x !== 0 || rect.y !== 0;
    },
    waitUntil(selector, scope, resolve, reject) {
      let loopCount = 0;
      const maxLoops = 10;

      // Loops until element exists in DOM or loop times out
      const checkForElement = () => {
        if (loopCount === maxLoops) {
          resolve(null);
          return;
        }

        const element = scope.querySelector(selector);

        if (element && this.elementHasPosition(element)) {
          resolve(element);
        } else {
          loopCount++;
          setTimeout(checkForElement, 5);
        }
      };

      checkForElement();
    },
    async elementIsRendered(selector, scope) {
      return new Promise((resolve, reject) => this.waitUntil(selector, scope, resolve, reject));
    },
    isElementInViewport(element) {
      const rect = element.getBoundingClientRect();

      return rect.top >= 0
        && rect.left >= 0
        && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        && rect.right <= (window.innerWidth || document.documentElement.clientWidth);
    },
    async observeAnimation() {
      if (!this.animateInViewport) {
        this.startDelayedAnimation();
        return;
      }

      const elementToAnimate = await this.elementIsRendered('.progress-animation', this.$refs.container);

      if (!elementToAnimate || this.isElementInViewport(elementToAnimate)) {
        this.startDelayedAnimation();
      } else {
        this.observer = new IntersectionObserver(entries => {
          if (entries[0].isIntersecting) {
            this.observer.unobserve(elementToAnimate);
            this.startDelayedAnimation();
          }
        });

        this.observer.observe(elementToAnimate);
      }
    },
    startDelayedAnimation(ms = 70) {
      this.didAnimate = true;
      setTimeout(() => {
        this.fillAmount = this.upToNowAmount;
      }, ms);
    },
  },
  watch: {
    upToNowAmount() {
      if (this.didAnimate) {
        this.fillAmount = this.upToNowAmount;
      }
    },
    fillAmount(newValue, oldValue) {
      EventBus.$emit('animatedBarAmountChanged', { cashflowCategory: this.cashflowCategory, newValue, oldValue });
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '../scss/category-color-mixins';

  .progress-bar-illustration {
    height: 15px;
    width: 100%;
    border-radius: 19px;
    @include category-background-color-light;

    &.grayed-out {
      background-color: $riseup_gray_0;
    }

    .progress-animation {
      height: 100%;
      transition: width 1s;
      border-radius: 25px;
      @include category-background-color;

      &.grayed-out {
        background-color: $riseup_gray_1;
      }
    }
  }
</style>
