import { clientWithoutErrorHandling, client } from '../http';

export const STATUS = {
  SUCCESS: 'success',
  FAILURE: 'failure',
};

export const VERIFY_SOCIAL_ID_NUMBER_ERROR_TYPES = {
  SOCIAL_ID_VERIFICATION_FAILED: 'SOCIAL_ID_VERIFICATION_FAILED',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
};

export const AUTHENTICATION_ERROR_TYPES = {
  SOCIAL_ID_VERIFICATION_FAILED: 'SOCIAL_ID_VERIFICATION_FAILED',
  INVALID_CODE: 'INVALID_CODE',
  CODE_EXPIRED: 'CODE_EXPIRED',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
};

export const VERIFY_DEPOSIT_RESPONSE_TYPES = {
  SUCCESS: 0,
  DEPOSIT_EXCEED_LIMIT: 1,
  UNKNOWN_ERROR: 2,
};

export const _DEPOSIT_TYPES = {
  OPPORTUNISTIC: 0,
  MONTHLY: 1,
};

export async function verifySocialId(socialIdNumber) {
  try {
    const response = await clientWithoutErrorHandling.post('/api/saving/dynamic-deposit/auth/verify-social-id', { socialIdNumber });
    return { status: STATUS.SUCCESS, data: response.data };
  } catch (err) {
    if (err.response && err.response.status === 400 && err.response.data === 'SOCIAL_ID_VERIFICATION_FAILED') {
      return { status: STATUS.FAILURE, data: VERIFY_SOCIAL_ID_NUMBER_ERROR_TYPES.SOCIAL_ID_VERIFICATION_FAILED };
    }
    return { status: STATUS.FAILURE, data: VERIFY_SOCIAL_ID_NUMBER_ERROR_TYPES.UNKNOWN_ERROR };
  }
}

export async function authenticate(socialIdNumber, code) {
  try {
    const response = await clientWithoutErrorHandling.post('/api/saving/dynamic-deposit/auth/authenticate', {
      socialIdNumber,
      code,
    });
    return { status: STATUS.SUCCESS, data: response.data };
  } catch (err) {
    if (err.response && err.response.status === 400 && err.response.data === 'SOCIAL_ID_VERIFICATION_FAILED') {
      return { status: STATUS.FAILURE, data: AUTHENTICATION_ERROR_TYPES.SOCIAL_ID_VERIFICATION_FAILED };
    }
    if (err.response && err.response.status === 400 && err.response.data === 'INVALID_CODE') {
      return { status: STATUS.FAILURE, data: AUTHENTICATION_ERROR_TYPES.INVALID_CODE };
    }

    if (err.response && err.response.status === 400 && err.response.data === 'CODE_EXPIRED') {
      return { status: STATUS.FAILURE, data: AUTHENTICATION_ERROR_TYPES.CODE_EXPIRED };
    }

    return { status: STATUS.FAILURE, data: VERIFY_SOCIAL_ID_NUMBER_ERROR_TYPES.UNKNOWN_ERROR };
  }
}

export async function initiatePayment(depositSum, isMonthlyDeposit, agreeToConditions, signature) {
  try {
    const depositType = isMonthlyDeposit ? _DEPOSIT_TYPES.MONTHLY : _DEPOSIT_TYPES.OPPORTUNISTIC;
    const response = await clientWithoutErrorHandling.post(
      '/api/saving/dynamic-deposit/initiate-payment', { depositSum, type: depositType, agreeToConditions, signature },
    );
    if (response.data.result === VERIFY_DEPOSIT_RESPONSE_TYPES.SUCCESS) {
      return { status: STATUS.SUCCESS };
    }
  } catch (err) {
    // left empty intentionally
  }
  return { status: STATUS.FAILURE };
}

export async function getSavingsInformation() {
  const response = await clientWithoutErrorHandling.get('/api/saving-in-cashflow/fund');
  return response.data;
}

export async function getSavingZone() {
  const response = await client.get('/api/saving-in-cashflow/saving-zone');
  return response.data;
}

export async function verifyDepositSum(depositSum, isMonthlyDeposit) {
  const depositType = isMonthlyDeposit ? _DEPOSIT_TYPES.MONTHLY : _DEPOSIT_TYPES.OPPORTUNISTIC;
  try {
    const response = await clientWithoutErrorHandling.post('/api/saving/dynamic-deposit/verify-deposit-sum', {
      depositSum,
      type: depositType,
    });
    if (response.data._type === VERIFY_DEPOSIT_RESPONSE_TYPES.SUCCESS) {
      return { status: VERIFY_DEPOSIT_RESPONSE_TYPES.SUCCESS, data: response.data.data };
    }
    if (response.data._type === VERIFY_DEPOSIT_RESPONSE_TYPES.DEPOSIT_EXCEED_LIMIT) {
      return { status: VERIFY_DEPOSIT_RESPONSE_TYPES.DEPOSIT_EXCEED_LIMIT, data: response.data.data };
    }
  } catch (err) {
    // left empty intentionally
  }
  return { status: VERIFY_DEPOSIT_RESPONSE_TYPES.UNKNOWN_ERROR };
}
