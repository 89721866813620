<template>
  <div class="bullet-block">
    <div class="bullet-circle">
      {{bulletText}}
    </div>
    <div class="section-text">
      <div class="title">{{ title }}</div>
      <div class="text">{{ text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BulletBlock',
  props: {
    bulletText: {
      type: Number,
      require: true,
    },
    title: {
      type: String,
      require: true,
    },
    text: {
      type: String,
      require: true,
    },
  },
};
</script>

<style scoped>
  .bullet-circle {
    display: flex;
    justify-content: center;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background-color: #DCE8F6;
    color: #5D7AFD;
    font-size: 18px;
    line-height: 24px;
    padding: 5px;
    font-weight: bold;
  }
  .title {
    font-weight: bold;
  }
  .section-text {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 24px;
  }
</style>
