import _ from 'lodash';
import BenchmarkApi from '@/api/BenchmarkApi';
import benchmarkUtils from '../utilities/benchmark';

const MAIN_CATEGORY = 'telecom';
const POLL_ANSWER_LOCAL_STORAGE_KEY = '2022-07-telecom-story-answer';

const state = {
  benchmark: {},
  nextStoryPollAnswer: '',
};

const getters = {
  categories: state => _.map(state.benchmark.categories, c => {
    return {
      name: c.name,
      amount: c.amount,
      low: benchmarkUtils.findLow(c.graph, c.amount),
    };
  }),
  telecomCategory: (state, getters) => _.find(getters.categories, c => c.name === MAIN_CATEGORY),
  subcategories: (state, getters) => _.reject(getters.categories, c => c.name === MAIN_CATEGORY),
  top3Subcategories: (state, getters) => _.chain(getters.subcategories)
    .sortBy(c => -c.amount)
    .take(3)
    .map(({ name, amount, low }) => {
      return {
        name,
        amount,
        low,
        good: low < 40,
      };
    })
    .value(),
};

const actions = {
  async fetchBenchmark({ commit }) {
    const benchmark = await BenchmarkApi.getBenchmark(MAIN_CATEGORY);
    const answer = localStorage.getItem(POLL_ANSWER_LOCAL_STORAGE_KEY);
    commit('setNextStoryPollAnswer', answer);
    commit('setBenchmark', benchmark);
  },
  setNextStoryPollAnswer({ commit }, answer) {
    localStorage.setItem(POLL_ANSWER_LOCAL_STORAGE_KEY, answer);
    commit('setNextStoryPollAnswer', answer);
  },
};

// mutations
const mutations = {
  setBenchmark(state, benchmark) {
    state.benchmark = benchmark;
  },
  setNextStoryPollAnswer(state, nextStoryPollAnswer) {
    state.nextStoryPollAnswer = nextStoryPollAnswer;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
