<template>
  <full-screen-modal :close-action="close">
    <template v-slot:content>
      <stepper :total-steps="2" :current-step="step + 1"/>
      <div class="plan-ahead-introduction-popup">
        <div class="pa-introduction-popup-content" :style="imageStyle">
          <div class="ri-label margin-bottom-sm">התוכניות הגדולות</div>
          <div class="ri-medium-bold-headline margin-bottom-s">{{ title }}</div>
          <div class="ri-large-body margin-bottom-s">{{ subtitle }}</div>
        </div>
        <div class="footer">
          <riseup-button :action="next" :title="buttonTitle" size="slim"/>
        </div>
      </div>
    </template>
  </full-screen-modal>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import FullScreenModal from '@/base-components/FullScreenModal';
import { mapActions } from 'vuex';
import Segment from '@/Segment';
import AddPlanFlow from './plans-flow/AddPlanFlow';

export default {
  name: 'PlanAheadIntroductionPopup',
  components: {
    FullScreenModal,
    Stepper: BaseUI.Stepper,
    RiseupButton: BaseUI.RiseupButton,
  },
  data() {
    return {
      step: 0,
    };
  },
  created() {
    Segment.trackUserGot('PlanAhead_IntroductionPopup_Shown');
  },
  computed: {
    buttonTitle() {
      return this.step === 0 ? 'איך זה עובד?' : 'לפתיחת תוכנית ראשונה';
    },
    title() {
      return this.step === 0 ? 'חופשה מפנקת או רכב חדש, אולי הדייסון שתמיד רצית?\nרייזאפ עוזרת לך להסתכל קדימה'
        : 'חודש אחרי חודש נראה איך התזרים החיובי מקרב אותך ליעדים שלך';
    },
    subtitle() {
      return this.step === 0 ? 'כל מה שצריך זה להוסיף תוכנית עתידית ולראות איך בכל תזרים הכסף שחסכת מקרב אותך צעד נוסף ליעד!'
        : 'הכסף ייצבר בעו״ש וכשיגיע זמן התוכנית, אפשר להציא אותו בכיף ובלי סטרס - הרי חסכת בשביל המטרה הזו!';
    },
    imageStyle() {
      return {
        backgroundPositionX: this.step === 0 ? '50%' : '0%',
      };
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal']),
    ...mapActions('planAhead', ['addPlan']),
    next() {
      Segment.trackUserGot('PlanAhead_IntroductionPopup_NextClicked');
      if (this.step === 1) {
        this.close();
        this.$router.push({ path: '/plan-ahead' });
        this.openModal({
          component: AddPlanFlow,
          props: {
            onDone: this.addPlan,
          },
          popupAlignment: 'full-screen',
        });
        return;
      }
      this.step += 1;
    },
    close() {
      Segment.trackUserGot('PlanAhead_IntroductionPopup_Closed');
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';
@import '~@riseupil/base-ui/src/scss/spacings';
@import '~@riseupil/base-ui/src/scss/mixins';

.plan-ahead-introduction-popup {
  background: $riseup_light_blue;
  width: 100%;
  height: 100%;
  text-align: right;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  .pa-introduction-popup-content {
    @include responsive-padding;
    flex: 1;
    align-items: flex-start;
    margin-top: 24px;
    white-space: pre-line;
    background-image: url('/images/plan-ahead/introduction-bg.png');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 300%;
    transition: background-position 0.5s ease-in-out;
  }

  .footer {
    @include responsive-padding;
    background: $riseup_white;
    color: $riseup_black;
    width: 100%;
    padding-top: $ri-spacing-ml;
    padding-bottom: $ri-spacing-ml;
  }
}
</style>
