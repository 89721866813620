import _ from 'lodash';
import Segment from '@/Segment';
import router from '../../router';
import questions from '../../pages/responsive-pages/non-authenticated/wine-and-dine/lead-qualification/consts/questions';
import valueProps from '../../pages/responsive-pages/non-authenticated/wine-and-dine/lead-qualification/consts/value-props';

const { QUESTION_NAMES, ANSWER_OPTIONS, WINE_AND_DINE_VERSION } = questions;
const { VALUE_PROPS_ORDER } = valueProps;

const state = {
  componentName: null,
  componentType: null,
  componentTypes: {
    QUESTION: 'question',
    VALUE_PROP: 'valueProp',
  },
  answers: {},
};

const getters = {
  getJTBDAnswerCode: (state, getters) => {
    const jtbdQuestion = getters.questions[QUESTION_NAMES.JOB_TO_BE_DONE];
    const answer = jtbdQuestion?.options.find(option => option.value === state.answers?.jobToBeDone);
    return answer?.marketingCode;
  },
  questions: (state, getters) => {
    return {
      [QUESTION_NAMES.HOUSEHOLD]: {
        index: 0,
        name: QUESTION_NAMES.HOUSEHOLD,
        text: 'משק הבית שלי כולל את...',
        nextPage: QUESTION_NAMES.AVERAGE_INCOME,
        options: _.shuffle([
          {
            value: ANSWER_OPTIONS[QUESTION_NAMES.HOUSEHOLD].JUST_ME,
            label: 'רק אותי',
            marketingCode: 'AAA',
          },
          {
            value: ANSWER_OPTIONS[QUESTION_NAMES.HOUSEHOLD].PARTNER,
            label: 'אותי ואת בן/בת הזוג',
            marketingCode: 'BBB',
          },
          {
            value: ANSWER_OPTIONS[QUESTION_NAMES.HOUSEHOLD].KIDS,
            label: 'יש גם ילדים',
            marketingCode: 'CCC',
          },
        ]),
      },
      [QUESTION_NAMES.AVERAGE_INCOME]: {
        index: 1,
        name: QUESTION_NAMES.AVERAGE_INCOME,
        text: `ההכנסה החודשית הממוצעת ${state.answers[QUESTION_NAMES.HOUSEHOLD] === ANSWER_OPTIONS[QUESTION_NAMES.HOUSEHOLD].JUST_ME
          ? 'שלי' : 'של משק הבית'} היא…`,
        nextPage: QUESTION_NAMES.JOB_TO_BE_DONE,
        options: _.shuffle([
          {
            value: ANSWER_OPTIONS[QUESTION_NAMES.AVERAGE_INCOME].OVER_BAR,
            label: 'מעל 14,000 ₪ בחודש',
            marketingCode: 'AAA',
          },
          {
            value: ANSWER_OPTIONS[QUESTION_NAMES.AVERAGE_INCOME].UNDER_BAR,
            label: 'עד 14,000 ₪ בחודש',
            marketingCode: 'BBB',
          },
          {
            value: ANSWER_OPTIONS[QUESTION_NAMES.AVERAGE_INCOME].NO_FIXED_INCOME,
            label: 'אין הכנסה קבועה',
            marketingCode: 'CCC',
          },
        ]),
      },
      [QUESTION_NAMES.JOB_TO_BE_DONE]: {
        index: 2,
        name: QUESTION_NAMES.JOB_TO_BE_DONE,
        text: 'מה השינוי שהיית רוצה לעשות?',
        nextPage: getters.isSegment ? QUESTION_NAMES.HANDLE_UNEXPECTED_INCOME : QUESTION_NAMES.HANDLE_UNEXPECTED_EXPENSE,
        options: _.shuffle([
          {
            value: ANSWER_OPTIONS[QUESTION_NAMES.JOB_TO_BE_DONE].CLOSE_THE_MONTH,
            label: 'אני רוצה לסגור את החודש',
            marketingCode: 'AAA',
          },
          {
            value: ANSWER_OPTIONS[QUESTION_NAMES.JOB_TO_BE_DONE].FINANCIAL_CLARITY,
            label: 'אני רוצה לעשות סדר בכסף שלי',
            marketingCode: 'BBB',
          },
          {
            value: ANSWER_OPTIONS[QUESTION_NAMES.JOB_TO_BE_DONE].START_SAVING,
            label: 'אני רוצה לחסוך יותר לעתיד',
            marketingCode: 'CCC',
          },
        ]),
      },
      [QUESTION_NAMES.HANDLE_UNEXPECTED_INCOME]: {
        index: 3,
        name: QUESTION_NAMES.HANDLE_UNEXPECTED_INCOME,
        text: 'החודש צצה הכנסה לא צפויה של 4,000 ₪, אני...',
        nextPage: QUESTION_NAMES.FINANCIAL_CONTROL,
        options: _.shuffle([
          {
            value: ANSWER_OPTIONS[QUESTION_NAMES.HANDLE_UNEXPECTED_INCOME].COVER_LOANS,
            label: 'אחזיר הלוואה או אצמצם מינוס',
            marketingCode: 'AAA',
          },
          {
            value: ANSWER_OPTIONS[QUESTION_NAMES.HANDLE_UNEXPECTED_INCOME].SAVINGS,
            label: 'אפקיד לחיסכון',
            marketingCode: 'BBB',
          },
          {
            value: ANSWER_OPTIONS[QUESTION_NAMES.HANDLE_UNEXPECTED_INCOME].BALANCE,
            label: 'אשאיר בעו״ש שיהיה לי זמין',
            marketingCode: 'CCC',
          },
        ]),
      },
      [QUESTION_NAMES.HANDLE_UNEXPECTED_EXPENSE]: {
        index: 3,
        name: QUESTION_NAMES.HANDLE_UNEXPECTED_EXPENSE,
        text: 'החודש צצה הוצאה לא צפויה של ₪8000, אני...',
        nextPage: QUESTION_NAMES.FINANCIAL_CONTROL,
        options: _.shuffle([
          {
            value: ANSWER_OPTIONS[QUESTION_NAMES.HANDLE_UNEXPECTED_EXPENSE].CALM,
            label: 'אשאר ברגוע, יש לי כסף בצד בדיוק בשביל זה',
            marketingCode: 'AAA',
          },
          {
            value: ANSWER_OPTIONS[QUESTION_NAMES.HANDLE_UNEXPECTED_EXPENSE].STRESSED,
            label: 'קצת בלחץ, אבל אצא מזה בהקדם',
            marketingCode: 'BBB',
          },
          {
            value: ANSWER_OPTIONS[QUESTION_NAMES.HANDLE_UNEXPECTED_EXPENSE].ANXIOUS,
            label: 'ממש בלחץ, יש מצב שאצטרך לקחת הלוואה',
            marketingCode: 'CCC',
          },
        ]),
      },
      [QUESTION_NAMES.FINANCIAL_CONTROL]: {
        index: 4,
        name: QUESTION_NAMES.FINANCIAL_CONTROL,
        text: 'כמה שליטה יש לי במצב הכלכלי שלי…',
        options: _.shuffle([
          {
            value: ANSWER_OPTIONS[QUESTION_NAMES.FINANCIAL_CONTROL].FULL_CONTROL,
            label: 'ידוע לי בדיוק מה המצב הכלכלי שלי, אני לגמרי בשליטה',
            marketingCode: 'AAA',
          },
          {
            value: ANSWER_OPTIONS[QUESTION_NAMES.FINANCIAL_CONTROL].SOME_CONTROL,
            label: 'ידוע לי בערך מה המצב הכלכלי שלי',
            marketingCode: 'BBB',
          },
          {
            value: ANSWER_OPTIONS[QUESTION_NAMES.FINANCIAL_CONTROL].NO_CONTROL,
            label: 'לא ידוע לי בכלל מה המצב הכלכלי שלי, אני לא בשליטה',
            marketingCode: 'CCC',
          },
        ]),
      },
    };
  },
  jobToBeDone: state => {
    const answer = state.answers[QUESTION_NAMES.JOB_TO_BE_DONE];
    if (answer === ANSWER_OPTIONS[QUESTION_NAMES.JOB_TO_BE_DONE].CLOSE_THE_MONTH) {
      return 'לסגור את החודש';
    }
    if (answer === ANSWER_OPTIONS[QUESTION_NAMES.JOB_TO_BE_DONE].FINANCIAL_CLARITY) {
      return 'לעשות סדר בכסף';
    }
    if (answer === ANSWER_OPTIONS[QUESTION_NAMES.JOB_TO_BE_DONE].START_SAVING) {
      return 'להתחיל לחסוך';
    }
    return null;
  },
  currentQuestion: (state, getters) => getters.questions[state.componentName],
  questionIndex: (state, getters) => getters.currentQuestion?.index,
  isSegment: state => state.answers[QUESTION_NAMES.HOUSEHOLD] !== ANSWER_OPTIONS[QUESTION_NAMES.HOUSEHOLD].JUST_ME
    && state.answers[QUESTION_NAMES.AVERAGE_INCOME] === ANSWER_OPTIONS[QUESTION_NAMES.AVERAGE_INCOME].OVER_BAR,
  totalQuestions: () => 5,
};

const actions = {
  gotoPage({ commit }, { componentName, componentType }) {
    commit('setComponentName', componentName);
    commit('setComponentType', componentType);
  },
  nextPage({ state, getters, commit, dispatch }, { questionName, answer }) {
    commit('addAnswer', { questionName, answer });
    if (VALUE_PROPS_ORDER.indexOf(state.componentName) === VALUE_PROPS_ORDER.length - 1) {
      return router.push({ path: '/signup/lq/finish', query: router.currentRoute.query });
    }
    let nextComponent;
    if (getters.currentQuestion) {
      const isLastQuestion = !getters.currentQuestion.nextPage;
      if (isLastQuestion) {
        logQuestionsResults(getters, state);
        dispatch('_trackAnswers', 'legacy_flow');
        nextComponent = {
          name: VALUE_PROPS_ORDER[0],
          componentType: state.componentTypes.VALUE_PROP,
        };
      } else {
        nextComponent = {
          name: getters.currentQuestion.nextPage,
          componentType: state.componentTypes.QUESTION,
        };
      }
    } else {
      nextComponent = {
        name: VALUE_PROPS_ORDER[VALUE_PROPS_ORDER.indexOf(state.componentName) + 1],
        componentType: state.componentTypes.VALUE_PROP,
      };
    }
    commit('setComponentName', nextComponent.name);
    commit('setComponentType', nextComponent.componentType);

    if (nextComponent.componentType === state.componentTypes.QUESTION) {
      return router.push({ path: `/signup/lq/question/${nextComponent.name}`, query: router.currentRoute.query });
    }
    return router.push({ path: `/signup/lq/vp/${nextComponent.name}`, query: router.currentRoute.query });
  },
  setAnswersFromExternalSources({ dispatch }, queryParams) {
    const { q1, q2, q3 } = queryParams;
    if (!q1 || !q2 || !q3) {
      return;
    }
    dispatch('_sanitizeAndSubmitAnswer', { question: QUESTION_NAMES.HOUSEHOLD, answer: q1 });
    dispatch('_sanitizeAndSubmitAnswer', { question: QUESTION_NAMES.AVERAGE_INCOME, answer: q2 });
    dispatch('_sanitizeAndSubmitAnswer', { question: QUESTION_NAMES.JOB_TO_BE_DONE, answer: q3 });
    dispatch('_trackAnswers', 'story');
  },
  async _trackAnswers({ state, getters }, source) {
    if (!_.isEmpty(state.answers)) {
      Segment.trackUserGot('MarketingBusinessEvent_WineAndDineCompleted', {
        [QUESTION_NAMES.HOUSEHOLD]: state.answers[QUESTION_NAMES.HOUSEHOLD],
        [QUESTION_NAMES.AVERAGE_INCOME]: state.answers[QUESTION_NAMES.AVERAGE_INCOME],
        [QUESTION_NAMES.FINANCIAL_CONTROL]: state.answers[QUESTION_NAMES.FINANCIAL_CONTROL],
        ...(
          getters.isSegment
            ? { [QUESTION_NAMES.HANDLE_UNEXPECTED_INCOME]: state.answers[QUESTION_NAMES.HANDLE_UNEXPECTED_INCOME] }
            : { [QUESTION_NAMES.HANDLE_UNEXPECTED_EXPENSE]: state.answers[QUESTION_NAMES.HANDLE_UNEXPECTED_EXPENSE] }
        ),
        [QUESTION_NAMES.JOB_TO_BE_DONE]: state.answers[QUESTION_NAMES.JOB_TO_BE_DONE],
        wndVersion: WINE_AND_DINE_VERSION,
        source,
      });
    }
  },
  _sanitizeAndSubmitAnswer({ commit }, { question, answer }) {
    const possibleAnswers = _.values(ANSWER_OPTIONS[question]);
    if (possibleAnswers.find(item => item === answer)) {
      commit('addAnswer', { questionName: question, answer });
    }
  },
};

const mutations = {
  setComponentName(state, componentName) {
    state.componentName = componentName;
  },
  setComponentType(state, componentType) {
    state.componentType = componentType;
  },
  addAnswer(state, { questionName, answer }) {
    state.answers = { ...state.answers, [questionName]: answer };
  },
};

function logQuestionsResults(getters, state) {
  const isCalmSegment = getters.isSegment
    && state.answers[QUESTION_NAMES.JOB_TO_BE_DONE] !== ANSWER_OPTIONS[QUESTION_NAMES.JOB_TO_BE_DONE].CLOSE_THE_MONTH;
  Segment.trackUserGot('WineAndDineSegmentTest_IsCalmSegment', { isCalmSegment });
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
