import { render, staticRenderFns } from "./DecimalNumber.vue?vue&type=template&id=3efec799&scoped=true&"
import script from "./DecimalNumber.vue?vue&type=script&lang=js&"
export * from "./DecimalNumber.vue?vue&type=script&lang=js&"
import style0 from "./DecimalNumber.vue?vue&type=style&index=0&id=3efec799&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3efec799",
  null
  
)

export default component.exports