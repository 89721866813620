import {
  findCategory,
  SPLIT_TRANSACTION_EXPENSE,
  SPLIT_TRANSACTION_EXPENSE_PLURAL,
  SPLIT_TRANSACTION_INCOME,
  SPLIT_TRANSACTION_INCOME_PLURAL, transactionAmount,
} from '@/utils/splitTransactionUtils';
import _ from 'lodash';
import { v1 as uuidv1 } from 'uuid';

const state = {
  slices: [],
  removedSlices: [],
  existingSplitTransactions: null,
  actionType: null,
  transaction: null,
  initialCategory: null,
  splitFromTransaction: null,
  totalAmount: null,
  changeMade: false,
  loading: false,
};

const getters = {
  lastSlice: state => state.slices[state.slices.length - 1],
  isIncome: state => state.splitFromTransaction.incomeAmount > 0,
  splitLabel: state => (state.splitFromTransaction.incomeAmount > 0 ? SPLIT_TRANSACTION_INCOME : SPLIT_TRANSACTION_EXPENSE),
  splitLabelPlural: state => (state.splitFromTransaction.incomeAmount > 0 ? SPLIT_TRANSACTION_INCOME_PLURAL : SPLIT_TRANSACTION_EXPENSE_PLURAL),
  slicesWithoutRemaining: state => state.slices.slice(0, -1),
};

const actions = {
  initializeSplitTransaction({ commit, getters },
    { transactionsInCurrentCashflow, transaction, initialCategory, availableCategories }) {
    const existingSplitTransactions = _.filter(transactionsInCurrentCashflow, { splitFrom: transaction.splitFrom });
    commit('setExistingSplitTransactions', existingSplitTransactions);
    commit('setTransaction', transaction);
    commit('setInitialCategory', initialCategory);
    commit('setChangeMade', false);
    if (existingSplitTransactions && existingSplitTransactions.length > 0) {
      commit('setActionType', 'update');
      commit('setTotalAmount', _.round(_.chain(state.existingSplitTransactions)
        .map(transaction => Number((transactionAmount(transaction)))).sum().value()));
      const splitFromTransaction = _.find(transactionsInCurrentCashflow, { transactionId: transaction.splitFrom });
      commit('setSplitFromTransaction', splitFromTransaction);
      const slices = _.chain(existingSplitTransactions)
        .orderBy('splitOrder')
        .map(splitTransaction => {
          return {
            amount: transactionAmount(splitTransaction),
            category: findCategory(splitTransaction, availableCategories),
            comment: splitTransaction.customerComment,
            transactionId: splitTransaction.transactionId,
          };
        }).value();
      commit('setSlices', slices);
    } else {
      commit('setActionType', 'create');
      commit('setSplitFromTransaction', transaction);
      commit('setTotalAmount', _.round(Number(transactionAmount(state.transaction)), 1));
      const slices = [{
        amount: null,
        comment: '',
        category: initialCategory,
        transactionId: uuidv1(),
      }, {
        amount: state.totalAmount,
        comment: '',
        category: initialCategory,
        transactionId: uuidv1(),
      }];
      commit('setSlices', slices);
    }
  },

  updateSlice({ commit, state, getters, dispatch }, { index, key, value }) {
    state.slices[index][key] = value;
    commit('setChangeMade', true);
    dispatch('reBalance');
  },

  setLoading({ commit }, { loading }) {
    commit('setLoading', loading);
  },

  removeSlice({ commit, state, getters, dispatch }, { index }) {
    const sliceToRemove = state.slices[index];
    state.removedSlices.push(sliceToRemove);
    state.slices.splice(index, 1);
    dispatch('reBalance');
  },

  reBalance({ commit, state, getters }) {
    const totalSlicesAmount = _.chain(getters.slicesWithoutRemaining).map('amount').sum().value();
    const differenceFromTotal = _.round(state.totalAmount - totalSlicesAmount, 1);
    if (differenceFromTotal >= 0) {
      commit('setRemainingBalance', differenceFromTotal);
    } else {
      commit('setRemainingBalance', 0);
    }
  },

  addSlice({ dispatch, state }) {
    state.slices.splice(state.slices.length - 1, 0,
      {
        amount: null,
        comment: '',
        category: state.initialCategory,
        transactionId: uuidv1(),
      });
    dispatch('reBalance');
  },
};
// mutations
const mutations = {
  setTransaction(state, transaction) {
    state.transaction = transaction;
  },
  setInitialCategory(state, initialCategory) {
    state.initialCategory = initialCategory;
  },
  setActionType(state, actionType) {
    state.actionType = actionType;
  },
  setSlices(state, slices) {
    state.slices = slices;
  },
  setSplitFromTransaction(state, splitFromTransaction) {
    state.splitFromTransaction = splitFromTransaction;
  },
  setExistingSplitTransactions(state, existingSplitTransactions) {
    state.existingSplitTransactions = existingSplitTransactions;
  },
  setRemainingBalance(state, balance) {
    state.slices[state.slices.length - 1].amount = balance;
  },
  setTotalAmount(state, totalAmount) {
    state.totalAmount = totalAmount;
  },
  setChangeMade(state, changeMade) {
    state.changeMade = changeMade;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
