<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import * as _ from 'lodash';

export default {
  name: 'OpenBankingMixin',
  computed: {
    ...mapState('session', ['isRestrictedCustomer']),
    ...mapState('credentials', ['obkSourceConfigurations', 'credentialsList']),
    ...mapGetters('featureFlags', ['openMeitavOBK']),
  },
  methods: {
    ...mapActions('modalRootStore', ['closeAllModals']),
    ...mapMutations('onboardingState', ['setError']),
    isOpenBankingSupported({ sourceId }) {
      const sourceName = this.getSourceName(sourceId);
      return this.isRestrictedCustomer || _.some(this.obkSourceConfigurations,
        {
          bankIdentifier: sourceName,
          enabled: true,
          type: 'enableConsentCreation',
        });
    },
    isSourceOpenToCustomer(sourceId) {
      const sourceName = this.getSourceName(sourceId);
      if (sourceName !== 'meitav') {
        return true;
      }
      return this.openMeitavOBK;
    },
    getSourceName(sourceId) {
      if (sourceId === 'amex') {
        return 'americanexpress';
      }
      return sourceId;
    },
    shouldShowObkConnectOption(credentials) {
      if (this.isOpenBanking(credentials)) {
        return false;
      }
      return !this.accountsExistsInOBKCredentials(credentials);
    },
    accountsExistsInOBKCredentials(credentials) {
      const { credentialsId } = credentials;
      const accounts = this.getCredsAccountsWithoutBankCC(credentials);
      const otherCredentialsSortedAccountsNumbers = _.chain(this.credentialsList)
        .reject(a => a.credentialsId === credentialsId)
        .filter(a => this.isOpenBanking(a))
        .flatMap(a => a.accounts)
        .compact()
        .map(pii => pii.accountNumberPiiValue)
        .value();
      // checking if accounts from other credentials conatins the accounts from current credentials
      return _.intersection(otherCredentialsSortedAccountsNumbers, accounts).length === accounts.length;
    },
    getCredsAccountsWithoutBankCC(credentials) {
      const credsAccountWithoutBankCC = credentials.type === 'bank'
        ? _.reject(credentials.accounts, a => a.sourceType === 'card')
        : credentials.accounts;
      return _.chain(credsAccountWithoutBankCC)
        .map(pii => pii.accountNumberPiiValue).sortBy()
        .value();
    },
    isOpenBanking(source) {
      return !!source.openBankingConsentId;
    },
  },
};
</script>
