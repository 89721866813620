import Vue from 'vue';
import _ from 'lodash';
import InvestigatorQuestionsApi from '../../api/InvestigatorQuestionsApi';
import consts from '../utilities/constants';

const state = {
  predictedExpenseQuestions: [],
  actualExpenseQuestions: [],
};

const getters = {
  fixedExpenses: state => _.filter(state.actualExpenseQuestions, { isFixed: true }),
  canceledEnvelopes: state => _.filter(state.predictedExpenseQuestions, { isFixed: false }),
  predictedExpenseByCategory: state => _.groupBy(state.predictedExpenseQuestions, 'category'),
  expenseInputs: (state, getters) => {
    return {
      [consts.INPUT_TYPES.BUDGET_CATEGORY_INPUT]: _.map(getters.fixedExpenses, createFixedExpenseInput),
      [consts.INPUT_TYPES.CANCEL_ENVELOPE_INPUT]: _.map(getters.canceledEnvelopes, createCanceledEnvelopeInput),
    };
  },
};

const actions = {
  async fetchExpenseQuestions({ commit }) {
    const expenseQuestions = await InvestigatorQuestionsApi.fetchExpenseQuestions();

    const actualExpenseQuestions = _.chain(expenseQuestions)
      .filter({ type: 'actual' })
      .map(question => {
        return { ...question, isFixed: false };
      })
      .value();
    commit('setActualExpenseQuestions', actualExpenseQuestions);

    const predictedExpenseQuestions = _.chain(expenseQuestions)
      .filter({ type: 'predicted' })
      .map(question => {
        return { ...question, isFixed: true };
      })
      .value();
    commit('setPredictedExpenseQuestions', predictedExpenseQuestions);
  },
};

function createFixedExpenseInput(actualExpenseQuestion) {
  return { transactionId: actualExpenseQuestion.transactionId, budgetCategory: 'fixed' };
}

function createCanceledEnvelopeInput(predictedExpenseQuestion) {
  return { envelopeId: predictedExpenseQuestion.envelopeId };
}

const mutations = {
  setActualExpenseQuestions(state, actualExpenseQuestions) {
    state.actualExpenseQuestions = actualExpenseQuestions;
  },
  setPredictedExpenseQuestions(state, predictedExpenseQuestions) {
    state.predictedExpenseQuestions = predictedExpenseQuestions;
  },
  setActualIsFixed(state, { transactionId, isFixed }) {
    const actual = _.find(state.actualExpenseQuestions, { transactionId });
    Vue.set(actual, 'isFixed', isFixed);
  },
  setPredictedIsFixed(state, { envelopeId, isFixed }) {
    const actual = _.find(state.predictedExpenseQuestions, { envelopeId });
    Vue.set(actual, 'isFixed', isFixed);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
