<script>
import DDLogs from '@/DDLogs';
import CredentialsApi from '@/api/CredentialsApi';
import consts from '@/constants/onboarding-state';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import ConsentCreationFailure from '@/pages/responsive-pages/non-authenticated/open-banking/connection-failures/ConsentCreationFailure.vue';
import BrowserInfo from '@/mixins/BrowserInfo.vue';
import Segment from '../../../Segment';
import AggregatorApi from '../../../api/AggregatorApi';
import onboardingApi from '../../../api/OnbordingApi';

export default {
  name: 'ConsentActionsMixin',
  mixins: [BrowserInfo],
  data() {
    return {
      loadingConsent: false,
    };
  },
  computed: {
    ...mapState('onboardingState', ['pageName']),
    ...mapState('credentials', ['closedBankingCredentialsIdToUpdate']),
    ...mapGetters('featureFlags', ['incognitoModeDetectionAmplitudeEvents']),
    isOnboarding() {
      return this.pageName && this.pageName !== consts.ONBOARDING_PAGE.CASHFLOW;
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['closeAllModals', 'openModal', 'closeModal']),
    ...mapMutations('onboardingState', ['setError']),
    async redirectToGetAccessCode({ idNumber, source, name, credentialsToUpdateMetaData }) {
      const { sourceName, openBanking, type } = source;
      const { aggregatorBankId: bankIdentifier } = openBanking;
      const extraParams = this.incognitoModeDetectionAmplitudeEvents ? await this.getBrowserParams() : {};
      Segment.trackUserInteraction(
        'RedirectToOBKPopup_ContinueInOBKClicked',
        { idNumber, source: sourceName, isOnboarding: this.isOnboarding, isUpdate: !!credentialsToUpdateMetaData, ...extraParams },
      );
      try {
        this.loadingConsent = true;
        const redirectUrl = credentialsToUpdateMetaData && !this.isOnboarding
          ? await this.updateConsent({
            bankIdentifier,
            consentId: credentialsToUpdateMetaData.consentId,
            credentialsId: credentialsToUpdateMetaData.credentialsId,
            sourceName,
          })
          : await this.createConsent({
            idNumber,
            bankIdentifier,
            name,
            sourceName,
            type,
            closedBankingCredentialsId: this.closedBankingCredentialsIdToUpdate,
          });
        this.loadingConsent = false;
        window.location.href = redirectUrl;
      } catch (error) {
        Segment.trackUserGot('OBKConsentCreationError', { idNumber: this.idNumber, sourceName, isOnboarding: this.isOnboarding });
        DDLogs.error('Failed getting redirect url from open-banking', { error });
        this.openModal(
          {
            component: ConsentCreationFailure,
            popupAlignment: 'full-screen',
            props: {
              sourceName,
              closeAction: () => {
                this.closeModal();
              },
            },
          },
        );
      }
    },
    async createConsent({ idNumber, bankIdentifier, name, sourceName, type, closedBankingCredentialsId }) {
      const { redirectUrl, consentId, previousConsentId } = await AggregatorApi.createConsent(idNumber,
        bankIdentifier, name, closedBankingCredentialsId);
      await CredentialsApi.createOBKConnectionRequestEvent({
        consentId,
        previousConsentId,
        sourceName,
        browserInfo: await this.getBrowserParams(),
      });
      if (this.isOnboarding) {
        try {
          if (this.pageName === consts.ONBOARDING_PAGE.BANK_DETAILS) {
            await onboardingApi.bankDetailsSubmitOpenBankingCreateConsent(redirectUrl, sourceName, type);
          } else {
            await onboardingApi.creditCardDetailsSubmitOpenBankingCreateConsent(redirectUrl, sourceName, type);
          }
        } catch (error) {
          DDLogs.error('Failed updating OpenBanking redirectUrl in johnny', { error });
        }
      }

      return redirectUrl;
    },
    async updateConsent({ consentId, bankIdentifier, credentialsId, sourceName }) {
      const redirectUrl = await AggregatorApi.updateConsent(bankIdentifier, consentId, credentialsId);
      await CredentialsApi.createOBKConnectionRequestEvent({
        consentId,
        previousConsentId: consentId,
        sourceName,
        browserInfo: await this.getBrowserParams(),
      });
      return redirectUrl;
    },
  },
};
</script>
