<template>
  <insight :insight="insight"
           :animation-name="'OpportunisticSaving.json'"
           :button-text="'ספרו לי עוד'"
           :button-action="buttonAction">
    <template v-slot:insight-text>
      <div>רצינו להזכיר שסיימת את {{budgetMonth}} בתזרים חיובי של <span class="amount">{{ formattedAmount }} ₪</span>. <div> כל הכבוד!</div>
      </div>
    </template>
  </insight>
</template>

<script>
import moment from 'moment';
import Segment from '@/Segment';
import cashflowViewConsts from '@/constants/cashflow-view';
import OpportunisticSavingPopup
  from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/components/savings/OpportunisticSavingPopup';
import { mapActions } from 'vuex';
import moneyUtils from '@/utils/money';
import Insight from './components/Insight';
import dateUtils from '../../../../../../utils/dates';

export default {
  name: 'OpportunisticSavingInsight',
  components: {
    Insight,
  },
  props: {
    insight: { type: Object },
  },
  computed: {
    formattedAmount() {
      return moneyUtils.formatAmountWithFraction(this.insight.proposedOpportunisticSavingAmount, 0);
    },
    budgetMonth() {
      return dateUtils.getHebrewMonth(moment(this.insight.budgetDate).subtract(1, 'month').format('YYYY-MM'));
    },
  },
  methods: {
    ...mapActions('modalRootStore', ['openModal', 'closeModal']),
    buttonAction() {
      this.openModal({
        component: OpportunisticSavingPopup,
        props: {
          cashflowCategory: cashflowViewConsts.CASHFLOW_CATEGORIES.VARIABLE_INCOME,
          insight: this.insight,
          closeAction: this.closePopup,
          buttons: [{
            text: 'איך לחסוך נכון?',
            variant: 'primary',
            action: () => {
              window.open('https://www.riseup.co.il/%d7%93%d7%a8%d7%9a-%d7%97%d7%93%d7%a9%d7%94-%d7%9c%d7%97%d7%a1%d7%95%d7%9a-%d7%9b%d7%a1%d7%a3/',
                '_blank');
              this.closePopup();
            },
          }],
        },
      });
    },
    closePopup() {
      Segment.trackUserInteraction('OpportunisticSavingPopupClosed', this.insight);
      this.closeModal();
    },
  },
};

</script>

<style lang="scss" scoped>
.amount {
  font-weight: bold;
  white-space: pre;
}
</style>
