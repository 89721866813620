import _ from 'lodash';
import CredentialsApi from '@/api/CredentialsApi';
import { getSourceConfigurationBySourceName } from '@/constants/sourcesConfiguration';

const state = {
  credentialsSettings: {
    credentialsConfigurations: [],
  },
  credentialsWithOldConsentConfiguration: [],
  credentialsToMigrateToOBKConfiguration: [],
};

const getters = {
  invalidCredentials: (state, getters) => _.filter(getters.credentialsConfigurations, c => c.status === 'passwordInvalid'),
  unvalidatedCredentials: (state, getters) => _.filter(getters.credentialsConfigurations, c => c.status === 'unvalidated'),
  credentialsConfigurations: state => state.credentialsSettings.credentialsConfigurations,
  allowNewConnections: state => state.credentialsSettings.allowNewConnections,
  disabledObkSources: state => state.credentialsSettings.disabledObkSources,
  getCredsBankCCAccounts: (state, getters) => credentialsId => {
    const creds = getters.credentialsConfigurations.find(c => c.credentialsId === credentialsId);
    if (!creds || creds.type !== 'bank') {
      return [];
    }
    return _.filter(creds.accounts, account => account.sourceType === 'card');
  },
};

const actions = {
  async fetchCredentialsSettings({ commit, rootState }) {
    const credentialsSettings = await CredentialsApi.fetchCredentialsSettings();
    const credsWithSourceFields = _.map(credentialsSettings.credentialsConfigurations, creds => {
      return {
        ...creds,
        ..._getSourceFieldsObject(creds.sourceName),
      };
    });
    commit('setCredentialsSettings', { ...credentialsSettings, credentialsConfigurations: credsWithSourceFields });
  },
  async fetchCredentialsToUpdate({ state, dispatch, commit }) {
    await dispatch('fetchCredentialsSettings');
    const { credentialsIdsToUpdateWithOldConsent, nonOBKCredentialsIds } = await CredentialsApi.fetchCredentialsIdsToUpdate();
    const credsWithOldConsent = state.credentialsSettings.credentialsConfigurations
      .filter(c => credentialsIdsToUpdateWithOldConsent.includes(c.credentialsId));
    const credsToMigrateToOBK = state.credentialsSettings.credentialsConfigurations
      .filter(c => nonOBKCredentialsIds.includes(c.credentialsId));
    commit('setCredentialsWithOldConsentConfiguration', credsWithOldConsent);
    commit('setCredentialsToMigrateToOBKConfiguration', credsToMigrateToOBK);
  },
};

const mutations = {
  setCredentialsSettings(state, credentialsSettings) {
    state.credentialsSettings = credentialsSettings;
  },
  setCredentialsWithOldConsentConfiguration(state, credentialsWithOldConsentConfiguration) {
    state.credentialsWithOldConsentConfiguration = credentialsWithOldConsentConfiguration;
  },
  setCredentialsToMigrateToOBKConfiguration(state, credentialsToMigrateToOBKConfiguration) {
    state.credentialsToMigrateToOBKConfiguration = credentialsToMigrateToOBKConfiguration;
  },
};

// Add closed banking fields to source (username and password)
function _getSourceFieldsObject(sourceName) {
  const source = getSourceConfigurationBySourceName(sourceName);
  const fieldsWithValue = _.mapValues(source.fields, fieldValue => _.assign({ value: undefined }, fieldValue));
  return _.assign(
    {},
    source,
    { fields: fieldsWithValue },
  );
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
