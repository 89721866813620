<template>
  <div class="all-insights-container">
    <riseup-header />
    <div class="insight-container" v-for="insight of insightsWithData" :key="getInsightName(insight)">
      <div class="insight-name">{{ getInsightName(insight) }}</div>
      <insight-factory :insight="insight" />
    </div>
    <div class="insight-container" v-for="insight of missingInsights" :key="getInsightName(insight)">
      <div class="insight-name">
        <span>missing: </span>
        <span class="missing">{{ getInsightName(insight) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import RiseupHeader from '@/base-components/app-header/RiseupHeader';
import InsightFactory from '@/pages/responsive-pages/authenticated/navigation-pages/cashflow-app/insights/InsightFactory.vue';

export default {
  name: 'InsightsViewer',
  components: {
    InsightFactory,
    RiseupHeader,
  },
  computed: {
    ...mapGetters('insights', ['INSIGHT_NAMES']),
    insightData() {
      return [].map(_addInsightId);
    },
    allInsightTypes() {
      return _.chain(this.INSIGHT_NAMES)
        .values()
        .reject(insightName => insightName === this.INSIGHT_NAMES.PersonalizedInsight)
        .map(insightName => {
          return { insightName };
        })
        .sortBy(insightType => !this.getInsight(insightType))
        .value();
    },
    insightsWithData() {
      return _.compact(this.allInsightTypes.map(this.getInsight));
    },
    missingInsights() {
      return _.filter(this.allInsightTypes, insightType => !this.getInsight(insightType));
    },
  },
  methods: {
    getInsightName(insight) {
      return insight.insightName === this.INSIGHT_NAMES.PersonalizedInsight ? `${insight.insightName}: ${insight.topic}` : `${insight.insightName}`;
    },
    getInsight(insightType) {
      return _.find(this.insightData, insightType);
    },
  },
};

function _addInsightId(insight, index) {
  return { ...insight, insightId: `insight-id-${index}` };
}

</script>

<style lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.all-insights-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .insight-container {
    width: 400px;
    border-bottom: 1px solid $riseup_gray_0;
    .insight-name {
      text-align: left;
      margin: 30px 0 0 30px;
      font-weight: bold;
      .missing {
        color: $riseup_danger_dark_red;
      }
    }
  }
}
</style>
