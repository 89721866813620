import DDLogs from '@/DDLogs';
import dateUtils from '@/utils/dates';
import moment from 'moment';
import _ from 'lodash';
import ChallengesApi from '../../api/ChallengesApi';

const DAILY_TASK_BASED = 'dailyTaskBased';
const HUNDRED_DAYS = '100-days';

const state = {
  imOnInChallenge: {},
  confettiDays: [2, 6], // Tuesday and Saturday
  weeklySummaryDay: 6, // Saturday
};

const actions = {
  async initImOnInChallenge({ commit }) {
    commit('setLoading', true);
    try {
      const imOnItChallenges = await ChallengesApi.getAllChallengesData(DAILY_TASK_BASED);
      const mostRecentChallenge = _.orderBy(imOnItChallenges, c => c.startDate, 'desc')[0];
      if (!mostRecentChallenge) {
        return;
      }
      commit('setImOnItChallenge', mostRecentChallenge);
    } catch (error) {
      DDLogs.log('Something went wrong when fetching challenge. Look at logs for challenges service', { error });
      commit('setError', true);
    } finally {
      commit('setLoading', false);
    }
  },
  async updateParticipantDailyTaskDone({ commit, getters }) {
    commit('setLoading', true);
    try {
      const participant = await ChallengesApi.updateParticipantDailyTaskDone(getters.imOnItChallengeId);
      if (participant?.challengeData?.dailyTaskDates) {
        commit('setDailyTaskDates', participant.challengeData.dailyTaskDates);
      }
      return participant;
    } catch (error) {
      DDLogs.log('Something went wrong when update daily task done for participant. Look at logs for challenges service', { error });
      commit('setError', true);
      return undefined;
    } finally {
      commit('setLoading', false);
    }
  },
  async removeParticipantCFCard({ commit, getters }) {
    commit('setLoading', true);
    try {
      const participant = await ChallengesApi.removeParticipantCFCard(getters.imOnItChallengeId);
      commit('setRemoveCFCard', participant.challengeData.participantRemovedCFCard);
      return participant;
    } catch (error) {
      DDLogs.log('Something went wrong when remove participant CF card. Look at logs for challenges service', { error });
      commit('setError', true);
      return undefined;
    } finally {
      commit('setLoading', false);
    }
  },
};

const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setError(state, isError) {
    state.hasError = isError;
  },
  setImOnItChallenge(state, imOnItChallenge) {
    state.imOnInChallenge = {
      // Make sure dailyTaskDates exists on this challenge
      dailyTaskDates: { },
      participantRemovedCFCard: false,
      ...imOnItChallenge,
    };
  },
  setDailyTaskDates(state, dailyTaskDates) {
    state.imOnInChallenge.dailyTaskDates = dailyTaskDates;
  },
  setRemoveCFCard(state, participantRemovedCFCard) {
    state.imOnInChallenge.participantRemovedCFCard = participantRemovedCFCard;
  },
};

const getters = {
  dailyTaskDates: state => state.imOnInChallenge.dailyTaskDates,
  is100DaysProgram: state => state.imOnInChallenge.tag === HUNDRED_DAYS,
  imOnItClicks: state => (state.imOnInChallenge.dailyTaskDates ? Object.keys(state.imOnInChallenge.dailyTaskDates).length : 0),
  whatsAppGroupLink: state => state.imOnInChallenge.whatsAppGroupLink,
  show100DaysBanner: (state, getters) => !!state.imOnInChallenge.showBannerOnCF && !getters.hasChallengeEnded,
  show100DaysCard: state => !!state.imOnInChallenge.showCardOnCF && !state.imOnInChallenge.participantRemovedCFCard,
  imOnItChallengeId: state => state.imOnInChallenge.challengeId,
  imOnItStartDate: state => state.imOnInChallenge.startDate,
  imOnItEndDate: state => state.imOnInChallenge.endDate,
  imOnItDaysCounter: (state, getters) => (getters.imOnItStartDate ? dateUtils.getDaysDiffFromNow(getters.imOnItStartDate) : null),
  isUpcomingChallenge: (state, getters) => (state.imOnInChallenge.startDate ? dateUtils.isDateAfterToday(state.imOnInChallenge.startDate) : false),
  hasChallengeEnded: (state, getters) => (getters.imOnItEndDate ? dateUtils.isDateBeforeToday(getters.imOnItEndDate) : false),
  daysTillStart: (state, getters) => (state.imOnInChallenge.startDate
    ? moment(state.imOnInChallenge.startDate).startOf('day').diff(moment().startOf('day'), 'days') : null),
  imOnItDaysInProgram: (state, getters) => (getters.imOnItEndDate && getters.imOnItStartDate
    ? dateUtils.getDaysDiff(getters.imOnItStartDate, getters.imOnItEndDate) : null),
  imOnItDaysLeft: (state, getters) => (getters.imOnItDaysInProgram && getters.imOnItDaysCounter
    ? getters.imOnItDaysInProgram - getters.imOnItDaysCounter + 1 : null),
  imOnItWeek: (state, getters) => (getters.imOnItDaysCounter ? Math.floor(
    (getters.imOnItDaysCounter + dateUtils.getDaysCounterFromSundayToDate(getters.imOnItStartDate)) / 7,
  ) + 1 : null),
  amIOnItToday: (state, getters) => !!getters.dailyTaskDates[dateUtils.getConsecutiveDaysFromDate()],
  currentStreakLength: (state, getters) => {
    let streakLength = 0;
    const currentDate = moment();
    while (getters.dailyTaskDates[currentDate.format('YYYY-MM-DD')]) {
      streakLength += 1;
      currentDate.subtract(1, 'day');
    }
    return streakLength;
  },
  isTodayConfettiDay: state => state.confettiDays.includes(moment().day()),
  isTodayWeeklySummaryDay: state => state.weeklySummaryDay === moment().day(),
  pastWeekDaysOnIt: (state, getters) => {
    const currentDatesOfWeek = dateUtils.getConsecutiveDaysFromDate(
      moment().startOf('week'),
      7,
    );
    return currentDatesOfWeek.filter(date => getters.dailyTaskDates[date]).length;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
