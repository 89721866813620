<template>
  <dynamic-deposit-step :authenticated="false" :step="this.$options.name">
    <template v-slot:content>
      <div class="dd-choose-monthly-amount">
        <div class="dd-headline-label ri-bold-body">עדכון סכום</div>
        <div class="ri-large-headline margin-bottom-s">
          הוראת הקבע הנוכחית שלך היא בסכום של {{ formatAmount(monthlyDeposit) }} ₪. כמה להוסיף?
        </div>
        <div>
          <amount-input
            :label="'תוספת להוראת הקבע'"
            :value="addToSaving"
            :error-message="errorMessage"
            @input="setMonthlyDepositSum"
          />
        </div>
        <div v-if="!errorMessage">הוראת הקבע תעודכן ל-
          <span class="new-monthly-deposit">{{ formatAmount(newDepositAmount) }} ₪</span>
          כל חודש
        </div>
      </div>
    </template>
    <template v-slot:next-button>
      <dynamic-deposit-button title="הבא >" :action="verifyDepositSum" :loading="loading" :disabled="buttonDisabled"/>
    </template>
  </dynamic-deposit-step>
</template>

<script>

import * as _ from 'lodash';
import Segment from '@/Segment';
import BaseUI from '@riseupil/base-ui';
import { mapGetters, mapMutations } from 'vuex';
import moneyUtils from '@/utils/money';
import * as SavingsApi from '@/api/SavingsApi';
import DynamicDepositStep from './DynamicDepositStep';
import DynamicDepositButton from './DynamicDepositButton';

export default {
  name: 'DDChooseMonthlyAmount',
  components: {
    DynamicDepositButton,
    DynamicDepositStep,
    AmountInput: BaseUI.AmountInput,
  },
  props: {
    nextStep: {
      type: Function,
      required: true,
    },
    showErrorPage: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      errorMessage: '',
      addToSaving: 0,
    };
  },
  created() {
    Segment.trackUserInteraction('DynamicDeposit_ChooseMonthlyAmount_Entered');
    const previouslySelectedDepositSum = this.depositSum;
    this.addToSaving = previouslySelectedDepositSum ? previouslySelectedDepositSum - this.monthlyDeposit : this.proposedSavingsAmount;
    this.setMonthlyDepositSum(this.addToSaving);
  },
  computed: {
    ...mapGetters('savings', ['monthlyDeposit']),
    ...mapGetters('dynamicSavings', ['proposedSavingsAmount', 'depositSum']),
    newDepositAmount() {
      if (_.isNil(this.addToSaving)) {
        return this.monthlyDeposit;
      }
      return this.addToSaving + this.monthlyDeposit;
    },
    buttonDisabled() {
      return _.isNil(this.addToSaving) || !!this.errorMessage;
    },
  },
  methods: {
    ...mapMutations('dynamicSavings', ['setDepositSum', 'setProposedSavingsAmount', 'setBankDetails', 'setFundDetails']),

    setMonthlyDepositSum(value) {
      this.errorMessage = '';
      this.addToSaving = value;
      if (!_.isNil(value) && value <= 0) {
        this.errorMessage = 'הסכום צריך להיות גדול מ-0';
      }
    },
    formatAmount(value) {
      return moneyUtils.formatAmount(value);
    },
    async verifyDepositSum() {
      this.loading = true;
      Segment.trackUserInteraction('DynamicDeposit_ChooseMonthlyAmount_NextClicked');
      Segment.trackUserInteraction('DynamicDeposit_ChooseMonthlyAmount_EditAmount',
        { diff: this.newDepositAmount - this.proposedSavingsAmount });
      try {
        const verifyResponse = await SavingsApi.verifyDepositSum(this.newDepositAmount, true);
        if (verifyResponse.status === SavingsApi.VERIFY_DEPOSIT_RESPONSE_TYPES.SUCCESS) {
          this.setDepositSum(this.addToSaving + this.monthlyDeposit);
          const { data: { bankNumber, bankBranch, bankAccountNumber, fundNumber } } = verifyResponse;
          this.setBankDetails({ bankNumber, bankBranch, bankAccountNumber });
          this.setFundDetails({ fundNumber });
          this.nextStep();
        } else if (verifyResponse.status === SavingsApi.VERIFY_DEPOSIT_RESPONSE_TYPES.DEPOSIT_EXCEED_LIMIT) {
          Segment.trackUserInteraction('DynamicDeposit_ChooseMonthlyAmount_ExceedLimit');
          this.errorMessage = `הסכום שהזנת עובר את התקרה השנתית, אפשר להפקיד השנה עד ${moneyUtils.formatAmount(verifyResponse.data.maxDeposit)} ₪`;
        } else {
          this.showErrorPage();
        }
      } finally {
        this.loading = false;
      }
    },
  },

};
</script>

<style lang="scss" scoped>
  .new-monthly-deposit {
    text-decoration: underline;
  }
</style>
